import {timer as observableTimer, Observable} from 'rxjs';
import {Component, OnInit, ViewChild} from '@angular/core';
import {IntelligentRiverService} from '../../../../../services/intelligent-river.service';
import {ActivatedRoute, Router} from '@angular/router';
import {User} from '../../../../..//models/user.model';
import {AuthService} from '../../../../../services/auth.service';
import {Device, DeviceForm, Sensor} from '../../.././../../models/device.model';
import {PostSensingDeviceParameter} from '../../../../../models/motestack.model';
import swal from 'sweetalert2';
import {Title} from '@angular/platform-browser';
import {SortService} from '../../../../../services/sort.service';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import {MatAccordion} from '@angular/material/expansion';
import {ErrorStateMatcher} from '@angular/material/core';
import {FormControl, FormGroupDirective, NgForm, Validators} from '@angular/forms';

declare var require: any;
const FormulaParser = require('hot-formula-parser').Parser;

export class MyErrorStateMatcher implements ErrorStateMatcher {
    isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
        console.log('control is ', control);
        return (control && control.invalid);
    }
}

@Component({
    selector: 'app-sensor-form',
    templateUrl: './sensor-form.component.html',
    styleUrls: ['./sensor-form.component.scss']
})
export class SensorFormComponent implements OnInit {
    private currentUser: User;
    deviceId: string;
    sensor: Sensor;
    deviceForm: DeviceForm;
    editingParam: PostSensingDeviceParameter;
    isSubmiting: boolean;
    isTrySubmit: boolean;
    submitError: boolean;
    showLoading: boolean = false;
    parser = new FormulaParser().setVariable('x', 1);
    convertFnMatcher = new MyErrorStateMatcher();
    // @ts-ignore
    @ViewChild(MatAccordion) accordion: MatAccordion;

    constructor(private irService: IntelligentRiverService, private route: ActivatedRoute, private authService: AuthService, private router: Router, private titleService: Title, private sort: SortService) {
    }

    ngOnInit() {
        this.titleService.setTitle(this.route.snapshot.data['title']);
        this.deviceId = this.route.snapshot.params['id'];
        if (this.deviceId !== 'new') {
            this.irService.getSensorEntityInfo(this.deviceId).subscribe(sensor => {
                // this.device = devices.find(i=> i.id == this.deviceId);
                console.log('DEBUG: sensor loaded info is ', sensor);
                this.sensor = sensor;
                this.sensor.sensorId = this.deviceId;
                if (this.sensor.type === 'ANALOG') {
                    this.sensor.parameters.forEach(param => {
                        param.units = 'Raw ADC Value';
                    });
                }
            });
        } else {
            this.sensor = {};
            this.sensor.name = '';
            this.sensor.parameters = [];
        }
        this.authService.observeCurrentUser.subscribe((next) => {
            this.currentUser = next;
        });
        this.deviceForm = {
            properties: [],
            units: [],
            subjects: [],
            sensors: []
        };
        this.irService.getSensorsProperties().subscribe(properties => {
            this.deviceForm.properties = properties.sort();
        });
        this.irService.getSensorsUnits().subscribe(units => {
            this.deviceForm.units = units.sort();
        });
        this.irService.getSensorsSubjects().subscribe(subjects => {
            this.deviceForm.subjects = subjects.sort();
        });
        this.irService.getSensorsTypes().subscribe(types => {
            this.deviceForm.sensors = types.sort();
        });
    }

    removeParam(index) {
        swal({
            title: 'Confirm Parameter Deletion',
            text: 'Are you sure you want to delete this parameter?',
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#023f7d',
            cancelButtonColor: '#6c757d',
            confirmButtonText: 'Yes'
        }).then((res) => {
            if (res.value) {
                if (this.sensor.parameters.length <= 1) {
                    return;
                }
                let param = this.sensor.parameters[index];
                // param.isRemoving = true;
                if (index > -1) {
                    this.sensor.parameters.splice(index, 1);
                }
            }
        });
    }

    addParam() {
        this.sensor.parameters.push({
            isVisible: true,
            name: '',
            context: '',
            units: '',
            convertFn: '',
            convertUnits: '',
        });
    }

    showNewSensorModal(editingParam: PostSensingDeviceParameter) {
        this.editingParam = editingParam;
        (<any>$('#newSensorModal')).modal('show');
    }

    showNewSubjectModal(editingParam: PostSensingDeviceParameter) {
        this.editingParam = editingParam;
        (<any>$('#newSubjectModal')).modal('show');
    }

    showNewPropertyModal(editingParam: PostSensingDeviceParameter) {
        this.editingParam = editingParam;
        (<any>$('#newPropertyModal')).modal('show');
    }

    showNewUnitModal(editingParam: PostSensingDeviceParameter) {
        this.editingParam = editingParam;
        (<any>$('#newUnitModal')).modal('show');
    }

    addNewSensor(labelInput: HTMLInputElement) {
        let label = labelInput.value;
        labelInput.value = '';
        this.irService.postSensor(label, this.currentUser.token).subscribe(res => {
            if (res.errorMessage === 'success') {
                this.deviceForm.sensors.push(label);
                this.editingParam.sensor = label;
                (<any>$('#newSensorModal')).modal('hide');
            }
        });
    }

    addNewSubject(labelInput: HTMLInputElement) {
        let label = labelInput.value;
        this.irService.postSubject(label, this.currentUser.token).subscribe(res => {
            if (res.errorMessage === 'success') {
                this.deviceForm.subjects.push(label);
                this.editingParam.subject = label;
                labelInput.value = '';
                (<any>$('#newSubjectModal')).modal('hide');
            }
        });
    }

    addNewProperty(labelInput: HTMLInputElement) {
        let label = labelInput.value;
        this.irService.postProperty(label, this.currentUser.token).subscribe(res => {
            if (res.errorMessage === 'success') {
                this.deviceForm.properties.push(label);
                this.editingParam.property = label;
                labelInput.value = '';
                (<any>$('#newPropertyModal')).modal('hide');
            }
        });
    }

    addNewUnit(labelInput: HTMLInputElement) {
        let label = labelInput.value;
        this.irService.postUnit(label, this.currentUser.token).subscribe(res => {
            if (res.errorMessage === 'success') {
                this.deviceForm.units.push(label);
                this.editingParam.unit = label;
                labelInput.value = '';
                (<any>$('#newUnitModal')).modal('hide');
            }
        });
    }

    updateEntity() {
        if (!this.isTrySubmit) {
            this.isTrySubmit = true;
            let timer = observableTimer(500);
            timer.subscribe(() => this.updateEntity());
            return;
        }
        this.submitError = false;

        // Checks if at least one parameter was created
        if (!this.sensor.parameters.length) {
            return;
        }

        let invalidFields = (<any>$('.is-invalid')).length;
        if (invalidFields > 0) {
            let top = (<any>$('.is-invalid'))[0].getBoundingClientRect().x;
            // window.scrollTo(top, 0);
            this.submitError = true;
            return;
        }
        this.isSubmiting = true;
        if (this.deviceId !== 'new') {
            console.log('DEBUG: the sensor before submitting is ', this.sensor);

            this.irService.updateSensingDevice(this.sensor, this.currentUser.token).subscribe(res => {
                this.isSubmiting = false;
                this.router.navigate(['./deployment-manager/sensors']);
            }, err => {
                this.isSubmiting = true;
            });
        } else {
            console.log('DEBUG: the sensor before submitting is ', this.sensor);
            this.irService.postSensingDevice(this.sensor, this.currentUser.token).subscribe(res => {
                this.isSubmiting = false;
                this.router.navigate(['./deployment-manager/sensors']);
            }, err => {
                this.isSubmiting = true;
            });
        }
    }

    clearConvertionFn(index) {
        this.sensor.parameters[index].convertFn = '';
        this.sensor.parameters[index].convertUnits = '';
    }

    clearConvertionUnit(newObject, index) {
        if (!newObject) {
            this.sensor.parameters[index].convertUnits = '';
        }
    }

    delete() {
        swal({
            title: 'Confirm sensor deletion',
            text: 'This action cannot be reverted. Are you sure you want to delete this sensor?',
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#023f7d',
            cancelButtonColor: '#6c757d',
            confirmButtonText: 'Yes'
        }).then((res) => {
            if (res.value) {
                this.showLoading = true;
                this.irService.deleteSensor(this.deviceId, this.currentUser.token).subscribe(response => {
                    this.showLoading = false;
                    if (response.errorMessage === 'success') {
                        swal('Success!', `Description: the sensor was successfully deleted.`, 'success');
                        this.router.navigate(['./deployment-manager/sensors']);
                    } else {
                        swal('Error!', `Description: something went wrong. Please try again`, 'error');
                    }
                }, error => {
                    console.log('ERROR: sensor form: delete error: ', error);
                    swal('Error!', `Description: something went wrong. Please try again`, 'error');
                });
            }
        });
    }

    typeChanged(value: string) {
        this.sensor.parameters = [];
        switch (value) {
            case 'SDI12' :
                break;
            case 'ANALOG' :
                this.addParam();
                this.sensor.parameters[0].units = 'Raw ADC Value';
                break;
            case 'ONEWIRE':
                this.addParam();
                break;
        }
    }

    isNumber(val: string) {
        // return !isNaN(+val) || !val;
        return !isNaN(parseInt(val, 10)) || !val;
    }

    drop(event: CdkDragDrop<string[]>) {
        moveItemInArray(this.sensor.parameters, event.previousIndex, event.currentIndex);
    }
}
