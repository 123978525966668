import {Component, OnInit} from '@angular/core';
import {ManagerProject, ManagerProjectDetails} from '../../../../../models/managerProject.model';
import {IntelligentRiverService} from '../../../../../services/intelligent-river.service';
import {Title} from '@angular/platform-browser';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthService} from '../../../../../services/auth.service';
import {User} from '../../../../../models/ideal/user-ideal.model';

@Component({
  selector: 'app-projects-form',
  templateUrl: './projects-form.component.html',
  styleUrls: ['./projects-form.component.scss']
})
export class ProjectsFormComponent implements OnInit {

  allProjects: ManagerProject[];
  project: ManagerProjectDetails;
  projectId: string;

  isTrySubmit: boolean;
  isSubmiting: boolean;

  private currentUser: User;

  constructor(private irService: IntelligentRiverService,
              private route: ActivatedRoute,
              private router: Router,
              private authService: AuthService,
              private titleService: Title) {
  }

  ngOnInit() {
    this.projectId = this.route.snapshot.params['id'];
    this.authService.observeCurrentUser.subscribe(user => {
      this.currentUser = user;
      if (user == null) {
        return;
      }
      if (this.projectId === 'new') {
        this.project = {token: this.currentUser.token};
      } else {
        this.irService.getManagerProjectEntity(this.projectId).subscribe(project => {
          console.log('DEBUG: project entity is ', project);
          this.project = project;
        });
      }
    });
  }

  isNumber(val: string) {
    return !isNaN(+val);
  }

  onSubmit() {
    this.isTrySubmit = true;
    if (!this.project.name) {
      return;
    }
    this.irService.postManagerProjects(this.project, this.currentUser.token).subscribe((res: any) => {
      this.isSubmiting = false;
      if (res.errorMessage === 'success') {
        this.router.navigate(['./deployment-manager/projects']);
      } else {

      }
    });
  }


}
