import {timer as observableTimer, Observable} from 'rxjs';
import {Component, OnInit} from '@angular/core';
import {Template} from '../../.././../../models/template.model';
import {IntelligentRiverService} from '../../../../../services/intelligent-river.service';
import {ActivatedRoute, Router} from '@angular/router';
import {User} from '../../../../..//models/user.model';
import {AuthService} from '../../../../../services/auth.service';
import {Device, Sensor, SensorParameters} from '../../../../../models/device.model';
import {Title} from '@angular/platform-browser';
import cloneDeep from 'lodash/cloneDeep';
import swal from "sweetalert2";

@Component({
  selector: 'app-template-form',
  templateUrl: './template-form.component.html',
  styleUrls: ['./template-form.component.scss']
})
export class TemplateFormComponent implements OnInit {
  templateId: string;
  template: Template = null;
  sensingDevices: Sensor[];
  private currentUser: User;
  selectedParamIndex: number;
  availableToAddParams: SensorParameters[];
  isSubmiting: boolean;
  isTrySubmit: boolean;
  submitError: boolean;
  selectedSensor: Sensor;
  // for explicit flag to show page loader
  showLoading: boolean;

  constructor(private irService: IntelligentRiverService,
              private route: ActivatedRoute,
              private authService: AuthService,
              private router: Router,
              private titleService: Title) {
  }

  ngOnInit() {
    this.titleService.setTitle(this.route.snapshot.data['title']);
    this.templateId = this.route.snapshot.params['id'];
    console.log('DEBUG1: template ID is ', this.templateId);
    if (this.templateId !== 'new') {
      this.irService.getTemplateEntityById(this.templateId).subscribe(template => {
        console.log('Got template: ', template);
        this.availableToAddParams = template.parameters;
        if (template.parameters.length > 0) {
          this.selectedParamIndex = 1;
        }
        this.template = template;
        this.irService.getSensorEntityInfo(template.sensorId.toString()).subscribe(sensor => {
          this.template.sensingDevice = sensor;
          this.availableToAddParams = cloneDeep(sensor.parameters);
          this.availableToAddParams = this.availableToAddParams.map(i => ({
            ...i,
            isVisible: true
          }));
          console.log('finished setting available to add params: ', this.availableToAddParams);
        });
      });
    } else {
      this.template = {};
      this.template.name = '';
      this.template.sensingDevice = {};
      this.template.parameters = [];
    }

    this.authService.observeCurrentUser.subscribe((next) => {
      this.currentUser = next;
    });

    this.irService.getAllSensors().subscribe(sensors => {
      console.log('DEBUG: sensors are ', sensors);
      this.sensingDevices = sensors.filter(i => i.type === 'SDI12');
    });
  }

  addParam() {
    const indexToAdd = this.selectedParamIndex-1;
    console.log('available to add params are ', this.availableToAddParams);
    console.log('DEBUG: selected param index is', this.selectedParamIndex);
    console.log('DEBUG: adding a parameter', this.availableToAddParams[indexToAdd]);
    this.template.parameters.push(this.availableToAddParams[indexToAdd]);
  }

  removeParam(indx: number) {
    this.template.parameters.splice(indx, 1);
  }

  updateEntity() {
    if (!this.isTrySubmit) {
      this.isTrySubmit = true;
      const timer = observableTimer(500);
      timer.subscribe(() => this.updateEntity());
      return;
    }
    this.submitError = false;
    const invalidFields = (<any>$('.is-invalid')).length;
    if (invalidFields > 0 || this.template.parameters.length < 1) {
      if (invalidFields > 0) {
        const top = (<any>$('.is-invalid'))[0].getBoundingClientRect().x;
        // window.scrollTo(top, 0);
        this.submitError = true;
      }
      return;
    }
    this.isSubmiting = true;
    if (this.templateId !== 'new') {
      console.log('DEBUG: template before the update is', this.template);
      this.irService.updateTemplate(this.template, this.currentUser.token).subscribe(res => {
        this.isSubmiting = false;
        this.router.navigate(['./deployment-manager/templates']);
      }, err => {
        this.submitError = true;
      });
    } else {
      this.irService.postTemplate(this.template, this.currentUser.token).subscribe(res => {
        this.isSubmiting = false;
        this.router.navigate(['./deployment-manager/templates']);
      }, err => {
        this.submitError = true;
      });
    }
  }

  updateSelectedParamIndex(newValue) {
    console.log('DEBUG: setting the new value of selected param index: ', newValue);
    this.selectedParamIndex = newValue;
  }

  changeDeviceParams(val) {
    this.template.parameters = [];
    this.availableToAddParams = [];
    console.log('DEBUG: value when sensor is changes is ', val);
    this.irService.getSensorEntityInfo(val).subscribe(sensor => {
      console.log('DEBUG: sensor loaded info is ', sensor);
      this.selectedSensor = sensor;
      this.selectedSensor.sensorId = val;
      this.template.sensingDevice = sensor;
      this.template.sensingDevice.sensorId = val;

      this.selectedParamIndex = 1;

      this.availableToAddParams = sensor.parameters.map(i => ({
        ...i,
        isVisible: true
      }));
    });
    // this.template.sensingDevice.name = this.sensingDevices.find(i => i.sensorId === this.template.sensingDevice.sensorId).parameters.map(i => i.name)[0];
  }

  delete() {
    swal({
      title: 'Confirm Template Deletion',
      text: 'This action cannot be reverted. Are you sure you want to delete this template?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#023f7d',
      cancelButtonColor: '#6c757d',
      confirmButtonText: 'Yes'
    }).then((res) => {
      if (res.value) {
        this.showLoading = true;
        this.irService.deleteTemplate(this.templateId, this.currentUser.token).subscribe(response => {
          this.showLoading = false;
          if (response.errorMessage === 'success') {
            swal('Success!', `Description: the template was successfully deleted.`, 'success');
            this.router.navigate(['./deployment-manager/templates']);
          }
          else {
            swal('Error!', `Description: something went wrong. Please try again`, 'error');
          }
        }, error => {
          console.log('ERROR: template form: delete error: ', error);
          swal('Error!', `Description: something went wrong. Please try again`, 'error');
        });
      }
    });
  }

}
