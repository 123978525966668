import {Component, OnInit, ViewChild} from '@angular/core';
import {User, UserEntity} from '../../../../../models/user.model';
import {IntelligentRiverService} from '../../../../../services/intelligent-river.service';
import {Router, ActivatedRoute, Params} from '@angular/router';
import {AuthService} from '../../../../../services/auth.service';
import {Title} from '@angular/platform-browser';
import {MatTableDataSource} from "@angular/material/table";
import {MatSort} from "@angular/material/sort";

@Component({
    selector: 'app-user-list',
    templateUrl: './user-list.component.html',
    styleUrls: ['./user-list.component.scss']
})
export class UserListComponent implements OnInit {
    users: UserEntity[];
    filteredUsers: UserEntity[];
    private currentUser: User;
    usersTableData: any;
    @ViewChild(MatSort, {static: true}) sort: MatSort;
    displayedColumns = ['fullName'];

    constructor(private irService: IntelligentRiverService, private router: Router, private authService: AuthService, private titleService: Title,
                private route: ActivatedRoute) {
    }

    ngOnInit() {
        this.titleService.setTitle(this.route.snapshot.data['title']);
        this.authService.observeCurrentUser.subscribe((next) => {
            this.currentUser = next;
            if (next == null) {
                return;
            }
            this.irService.getUsers(this.currentUser.token).subscribe(users => {
                this.users = users;
                this.filteredUsers = Object.assign([], this.users);
                this.usersTableData = new MatTableDataSource(users);
                this.usersTableData.sort = this.sort;
            });
        });
    }

    filterItem(filter: string): void {
        this.filteredUsers = this.users.filter(i => i.fullName.toLowerCase().includes(filter.toLowerCase()));
    }

    updateUser(user: UserEntity): void {
        if (user == null) {
            this.router.navigate(['./deployment-manager/accounts', 'new']);
        } else {
            this.router.navigate(['./deployment-manager/accounts', user.accountId]);
        }
    }

    applyFilter(event: Event) {
        const filterValue = (event.target as HTMLInputElement).value;
        this.usersTableData.filter = filterValue.trim().toLowerCase();
    }
}
