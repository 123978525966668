import {Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges} from '@angular/core';
import {Deployment} from '../../../models/deployments.model';
import {MapRestriction} from "../../../models/google-maps-types.model";

@Component({
    selector: 'app-data-deployments-map',
    templateUrl: './data-deployments-map.component.html',
    styleUrls: ['./data-deployments-map.component.scss']
})
export class DataDeploymentsMapComponent implements OnInit, OnChanges {
    @Input() deployments: Deployment[];
    @Output() deploymentClick = new EventEmitter<number>();
    lngCenter: number;
    latCenter: number;
    mapZoom: number;
    // new stuff
    map: any;
    openedInfo = {};
    public restriction: MapRestriction = {
        latLngBounds: {
            north: 85.0,
            south: -85.0,
            west: -180.0,
            east: 180.0
        },
        strictBounds: true
    };
    @Input() deploymentParamsData: any;
    @Input() viewBy: 'sensor' | 'deployment';
    @Input() loading: boolean;
    @Input() maxValue: number;
    @Input() minValue: number;
    // dynamic styles
    style: any;

    constructor() {
        this.style = document.createElement('style');
        this.style.type = 'text/css';
        // this.generateColorClasses();
    }

    generateColorClasses() {
        let customStyle, color, colorString;
        for (let i = 0; i < 256; i++) {
            color = this.getColorForValue(i);
            colorString = "rgb("+color.r+","+color.g+","+color.b+")";
            customStyle = '.county-marker-color-' + i +
                ' { button { background-color: ' + colorString + ' !important; } ';
            if (i < 128) {
                customStyle += ' .mat-button-wrapper { color: black !important; }';
            }
            customStyle += ' .si-pointer-bg-top { border-top-color: ' + colorString + ' !important; } ' +
                ' .si-content-wrapper { background-color: ' + colorString + ' !important; } }';
            this.style.innerHTML += customStyle;
        }
        console.log('style generated is ', this.style.innerHTML);
    }

    ngOnInit() {
        this.calculateMapCenter();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if ('deploymentParamsData' in changes) {
            console.log('params got updated, recentering the map, params are ', changes.deploymentParamsData.currentValue);
            this.fitBoundsToVisibleMarkers();
        }
        if ('loading' in changes) {
            console.log('DEBUG1: loading is now ', changes.loading);
        }
        if ('deployments' in changes) {
            console.log('deployments got updated', this.deployments);
        }
    }

    mapReady(map) {
        this.map = map;
    }

    getColorClass(deploymentUri): any {
        if (this.deploymentParamsData[deploymentUri]) {
            let normalizedValue = this.normalize(this.deploymentParamsData[deploymentUri].value, this.maxValue, this.minValue);
            // console.log('the max is ', this.maxValue, 'the min is', this.minValue, 'the norm is ', normalizedValue);
            return 'county-marker-colored county-marker-color-' + (Math.floor(normalizedValue));
            // return 'county-marker-colored .county-marker-continuous-color-' + (Math.floor(normalizedValue / 10) * 10);
        }
    }

    normalize(val, max, min) {
        return Math.floor(((val - min) / (max - min)) * 255);
    }

    showDeploymentName(uri) {
        // console.log('showDeploymentName: this.openedInfo is ', this.openedInfo[uri]);
        if (uri in this.openedInfo) {
            this.openedInfo[uri] = !this.openedInfo[uri];
        } else {
            this.openedInfo[uri] = true;
        }
    }

    public getValue(d: any): string {
        // console.log('all deployment data is ', this.deploymentParamsData);
        const val = this.deploymentParamsData[d.deploymentUri];
        if (val.value === undefined || val.value === null) {
            return 'N/A';
        }
        if (isNaN(Number(val.value))) {
            return 'N/A';
        }
        // console.log('the val is', val);
        return (Math.round(val.value * 100) / 100) + ' ' + val.units;
    }

    calculateMapCenter() {
        if (this.deployments.length) {
            let minLng = Math.min(...this.deployments.map(item => item.location.lng));
            let maxLng = Math.max(...this.deployments.map(item => item.location.lng));
            let minLat = Math.min(...this.deployments.map(item => item.location.lat));
            let maxLat = Math.max(...this.deployments.map(item => item.location.lat));
            let diff_lat = maxLat - minLat;
            let diff_lng = maxLng - minLng;
            let offset = diff_lat > diff_lng ? diff_lat : diff_lng;
            offset = Math.round(Math.log2(Math.abs(offset)));
            if (offset < -8) {
                this.mapZoom = 16;
            } else {
                offset = 8 - offset;
                if (offset < 3) {
                    this.mapZoom = 2;
                } else {
                    this.mapZoom = offset;
                }
            }
            this.lngCenter = this.deployments.map(item => item.location.lng).reduce((acc, cur) => {
                return acc + cur;
            }) / this.deployments.length;
            this.latCenter = this.deployments.map(item => item.location.lat).reduce((acc, cur) => {
                return acc + cur;
            }) / this.deployments.length;
        } else {
            this.mapZoom = 3;
        }
    }

    fitBoundsToVisibleMarkers() {
        setTimeout(() => {
            if (this.map && google) {
                const bounds = new google.maps.LatLngBounds();
                for (const d of this.deployments) {
                    bounds.extend(new google.maps.LatLng(d.location.lat, d.location.lng));
                }
                // console.log(bounds);
                this.map.fitBounds(bounds);
            }
        }, 50);
    }

    onDeploymentClicked(deploymentId) {
        this.deploymentClick.emit(deploymentId);
    }

    // onMapReady(map) {
    //   debugger
    //   this.bounds = new google.maps.LatLngBounds(new google.maps.LatLng (this.minLat, this.minLng), new google.maps.LatLng (this.maxLat, this.maxLng));
    //   map.fitBound(this.bounds);
    // }
    GColor = function (r?, g?, b?) {
        r = (typeof r === 'undefined') ? 0 : r;
        g = (typeof g === 'undefined') ? 0 : g;
        b = (typeof b === 'undefined') ? 0 : b;
        return {r: r, g: g, b: b};
    };

    getColorForValue(value: number) {
        const yellow = this.GColor(255, 255, 0);
        const red = this.GColor(255, 0, 0);
        let tmpColor = this.GColor();
        tmpColor.r = yellow.r + ((value * (red.r - yellow.r)) / 255);
        tmpColor.g = yellow.g + ((value * (red.g - yellow.g)) / 255);
        tmpColor.b = yellow.b + ((value * (red.b - yellow.b)) / 255);
        return tmpColor;
    }
}
