import { Component, OnInit } from '@angular/core';
import {Router, ActivatedRoute, Params, NavigationEnd} from '@angular/router';
import { AuthService } from '../../../services/auth.service';

@Component({
    selector: 'app-deployment-manager',
    templateUrl: './deployment-manager.component.html',
    styleUrls: ['./deployment-manager.component.scss']
})
export class DeploymentManagerComponent implements OnInit {
    activeLink = 4;
    constructor(private router: Router, private authService: AuthService) {
        let subRoute = this.router.url.substring(20);
        subRoute = subRoute.split('/')[0];
        this.setActiveLinkBasedOnUrl(subRoute);
        this.authService.observeLoggedIn.subscribe((next) => {
            if (!next) {
                this.router.navigate(['/']);
            }
        });
    }

    ngOnInit() {
        this.router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                let subRoute = event.urlAfterRedirects.split('/')[2];
                console.log('sub route in event is ', subRoute);
                this.setActiveLinkBasedOnUrl(subRoute);
            }
        });
    }

    setActiveLinkBasedOnUrl(subroute) {
        switch(subroute) {
            case 'sensors':  this.activeLink = 0; break;
            case 'templates':  this.activeLink = 1; break;
            case 'radios':  this.activeLink = 2; break;
            case 'motestacks':  this.activeLink = 3; break;
            case 'deployments':  this.activeLink = 4; break;
            case 'accounts':  this.activeLink = 5; break;
            case 'errors':  this.activeLink = 6; break;
            case 'notifications':  this.activeLink = 7; break;
            case 'middleware': this.activeLink = 8; break;
            case 'projects': this.activeLink = 9; break;
            default: this.activeLink = 4; break;
        }
    }

}
