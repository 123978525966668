import { Component, OnInit } from '@angular/core';
import { IntelligentRiverService } from '../../../../../services/intelligent-river.service';
import {ActivatedRoute, Router } from '@angular/router';
import { User } from '../../../../../models/user.model';
import { Deployment } from '../../../../../models/ideal/deployments-ideal.model';
import { BoundaryNotification, Template } from '../../../../..//models/boundary.model';
import { AuthService } from '../../../../../services/auth.service';
import { Observable } from 'rxjs';
import { Title } from '@angular/platform-browser';
import swal from 'sweetalert2';

@Component({
  selector: 'app-notification-boundary-form',
  templateUrl: './notification-boundary-form.component.html',
  styleUrls: ['./notification-boundary-form.component.scss']
})
export class NotificationBoundaryFormComponent implements OnInit {

  private currentUser: User;
  isTrySubmit = false;
  isDeleteSubmiting = false;
  isSubmiting = false;
  submitError: string;

  users: User[];
  deployments: Deployment[];
  selectedDeployment: Deployment;
  druftSelectedDeploymentURI: any;
  notificationId: string;
  boundary: BoundaryNotification;

  selectedSubscriber?: string;
  selectedParamId: string;
  selectedSensingId: string;

  minInfString = '-infinity';
  maxInfString = '+infinity';

  constructor(private irService: IntelligentRiverService,
    private route: ActivatedRoute,
    private authService: AuthService,
    private router: Router,
    private titleService: Title) { }

  ngOnInit() {
    this.notificationId = this.route.snapshot.params['id'];
    this.authService.observeCurrentUser.subscribe((next) => {
      this.currentUser = next;

    if (this.notificationId !== 'new') {
      this.irService.getBoundaryNotifications(this.currentUser.token).subscribe(res => {
        this.boundary = res.find(i => i.id === this.notificationId);

        if (this.router.url.includes('duplicate')) {
          delete this.boundary.id;
          // delete this.boundary.deploymentUri;
          // this.selectedDeployment = this.deployments.find(i => i.motestackId.uri === this.boundary.deploymentUri);
          this.boundary.title = '';
        }
      });
    } else {
      this.boundary = { subscribers: [], templates: [] };
    }

    this.irService.getUsers(this.currentUser.token).subscribe(res => {
      // this.users = res;
    });

    this.irService.getDeploymentExpandedAll().subscribe(res => {
      this.deployments = res;
      if (this.boundary.deploymentUri) {
        this.selectedDeployment = this.deployments.find(i => i.motestackId.uri === this.boundary.deploymentUri);
      }
    });

    });

  }

  addSubscriber() {
    if (!this.selectedSubscriber || this.boundary.subscribers.find(i => i === this.selectedSubscriber)) {
      return;
    }
    this.boundary.subscribers.push(this.selectedSubscriber);
  }

  removeSubscriber(email: string) {
    this.boundary.subscribers = this.boundary.subscribers.filter(i => i !== email);
  }

  selectedDeploymentChanged(uri) {
    debugger
    const prevUri = this.boundary.deploymentUri;
    this.boundary.deploymentUri = uri;
    const draftSelectedDeplyment = this.deployments.find(i => i.motestackId.uri === uri);
    const newTemplateArr = [];
    this.boundary.templates.forEach(i => {
      if (draftSelectedDeplyment.motestackId.sensing.some(s => s.sensingDevice.id === i.sensorId)) {
        newTemplateArr.push(i);
      }
    });
    if (this.boundary.templates && this.boundary.templates.length !== newTemplateArr.length) {
      swal({
        title: 'Are you sure?',
        text: '',
        cancelButtonText: 'No',
        confirmButtonText: 'Yes',
        showCancelButton: true,
        showConfirmButton: true
      })
      .then((value) => {
        if (value.dismiss) {
          this.boundary.deploymentUri = prevUri;
          return;
        }
        this.selectedDeployment = this.deployments.find(i => i.motestackId.uri === uri);
        this.boundary.templates = newTemplateArr;
      });
    } else {
      this.selectedDeployment = this.deployments.find(i => i.motestackId.uri === uri);
      this.boundary.templates = newTemplateArr;
    }

  }

  getSensingLableById(template: Template) {
    return this.selectedDeployment.motestackId.sensing.find(i => template.deviceId === i.id).label;
  }

  getSensingParams(template: Template) {
    return this.selectedDeployment.motestackId.sensing.find(i => template.deviceId === i.id).parameters;
  }

  getParamLabelById(template: Template, paramId: string) {
    const sensing = this.selectedDeployment.motestackId.sensing.find(i => template.deviceId === i.id);
    const label = sensing.parameters.find(i => i.parameter.id === paramId).parameter.label;
    return label;
  }

  removeRange(templateIndx: number, paramIndx: number, rangeIndx: number) {
    const param = this.boundary.templates[templateIndx].parameters[paramIndx];
    param.ranges.splice(rangeIndx, 1);
  }

  addRange(templateIndx: number, paramIndx: number) {
    const param = this.boundary.templates[templateIndx].parameters[paramIndx];
    param.ranges.push({});
  }

  removeParam(templateIndx: number, paramIndx: number) {
    const template = this.boundary.templates[templateIndx];
    template.parameters.splice(paramIndx, 1);
  }

  addParam(templateInd: number) {
    if (!this.selectedParamId) {
      return;
    }
    if (this.boundary.templates[templateInd].parameters.find(i => i.parameterId === this.selectedParamId)) {
      return;
    }
    this.boundary.templates[templateInd].parameters.push({parameterId: this.selectedParamId, ranges: [{}]});
    this.selectedParamId = null;
  }

  removeTemplate(templateInd: number) {
    this.boundary.templates.splice(templateInd, 1);
  }

  addTemplate() {
    if (!this.selectedSensingId) {
      return;
    }
    const sensing = this.selectedDeployment.motestackId.sensing.find(i => i.id === this.selectedSensingId);
    if (this.boundary.templates.find(i => i.deviceId === this.selectedSensingId)) {
      return;
    }
    this.boundary.templates.push({deviceId: sensing.id, sensorId: sensing.sensingDevice.id, parameters: []});
  }

  deleteboundary() {
    this.isDeleteSubmiting = true;
    this.irService.deleteBoundaryNotification(this.boundary.id, this.currentUser.token).subscribe(res => {
      this.isDeleteSubmiting = false;
      this.router.navigate(['./deployment-manager/notifications' ]);
    });
  }

  onSubmit() {

    this.isTrySubmit = true;
    if (this.boundary.subscribers.length < 1 || this.boundary.templates.length < 1) {
      return;
    }

    const invalidFields = (<any>$('.is-invalid')).length;
    const invalidAlerts = (<any>$('.alert-danger')).length;
    if (invalidAlerts > 0 && !this.submitError) {
      return;
    }
    if (invalidFields > 0) {
        this.submitError = 'field error';
      return;
  }

    this.isSubmiting = true;
    this.submitError = null;
    if (!this.boundary.id) {
      this.irService.postBoundaryNotification(this.boundary, this.currentUser.token).subscribe(res => {
        this.isSubmiting = false;
        this.router.navigate(['./deployment-manager/notifications' ]);
      }, (err) => {
        this.submitError = err;
      });
    } else {
      this.irService.updateBoundaryNotification(this.boundary, this.currentUser.token).subscribe(res => {
        this.isSubmiting = false;
        this.router.navigate(['./deployment-manager/notifications' ]);
      }, (err) => {
        this.submitError = err;
      });
    }
  }

  rangeHasIntersect(templateIndx, parameterIndx: number) {
    const param = this.boundary.templates[templateIndx].parameters[parameterIndx];
    const paramClone = JSON.parse(JSON.stringify(param));
    this.rangeInfToMaxVal(paramClone.ranges);
    let havIntersect = false;
    paramClone.ranges.forEach((param1, indx1) => {
      paramClone.ranges.forEach((param2, indx2) => {
        if (indx1 !== indx2) {
          if (+param1.leftBoundary > +param2.leftBoundary && +param1.leftBoundary < +param2.rightBoundary) {
            havIntersect = true;
          }
          if (+param1.rightBoundary > +param2.leftBoundary && +param1.rightBoundary < +param2.rightBoundary) {
            havIntersect = true;
          }
        }
      });
    });
    this.rangeToValidStringVal(paramClone.ranges);
    return havIntersect;
  }

  private rangeInfToMaxVal(ranges) {
    ranges.forEach(i => {
      if (i.leftBoundary === this.minInfString) {
        i.leftBoundary = '' + Number.MIN_VALUE;
      }
      if (i.rightBoundary === this.maxInfString) {
        i.rightBoundary = '' + Number.MAX_VALUE;
      }
    });
  }

  private rangeToValidStringVal(ranges) {
    ranges.forEach(i => {
      if (i.leftBoundary === '' + Number.MIN_VALUE ) {
        i.leftBoundary = this.minInfString;
      }
      if (i.rightBoundary === '' + Number.MAX_VALUE ) {
        i.rightBoundary = this.maxInfString;
      }
    });
  }

  isValidRange(range, boundary) {
    if (range.leftBoundary === this.minInfString || range.rightBoundary === this.maxInfString) {
      return true;
    }
    if (+range.leftBoundary > +range.rightBoundary) {
      return false;
    }
    return !isNaN(+boundary);

  }

  duplicate() {
    this.router.navigate(['./deployment-manager/boundary-notifications/' + this.boundary.id + '/duplicate' ]);
  }

}
