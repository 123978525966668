import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { IntelligentRiverService } from '../../../services/intelligent-river.service';
import { Deployment } from '../../../models/deployments.model';
import { Project } from '../../../models/projects.model';


@Component({
  selector: 'app-deployments',
  templateUrl: './deployments.component.html',
  styleUrls: ['./deployments.component.scss']
})
export class DeploymentsComponent implements OnInit {

  constructor(private titleService: Title, private irService: IntelligentRiverService) { }

  deployments: Deployment[];
  currentProject: Project;
  ngOnInit() {
    this.titleService.setTitle('Deployments | Sense Stream');

    this.irService.observeCurrentProject.subscribe((next) => {
      this.currentProject = next;
      console.log('DEBUG: deployments: current project ', this.currentProject);
      if (this.currentProject)
        this.irService.getDeploymentsByProjId(this.currentProject.projectId).subscribe(deployments => {
          console.log('DEBUG: deployments: deployments from api is : ', deployments);
          this.deployments = deployments;
        });
      });

  }

}
