import {Component, OnInit, Output, EventEmitter, Input, SimpleChanges, OnChanges, Inject} from '@angular/core';
import {Project} from '../../../models/projects.model';
import {IntelligentRiverService} from '../../../services/intelligent-river.service';
import {Router} from '@angular/router';
import {Location} from '@angular/common';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-project-select',
  templateUrl: './project-select.component.html',
  styleUrls: ['./project-select.component.scss']
})
export class ProjectSelectComponent implements OnInit, OnChanges {
  @Output() hideSelectProjects: EventEmitter<any> = new EventEmitter<any>();
  projects: Project[];
  projectsLess3: Project[];
  showBarLoader: boolean = false;
  @Input() isVisible: boolean;
  isMobile: boolean;

  constructor( @Inject(DOCUMENT) private _document,
               private irService: IntelligentRiverService,
              private location: Location,
              private router: Router) {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.isVisible) {
      $('footer').css('box-shadow', 'none');
      $('#select-proj-content').css('opacity', 0);
      $('#select-proj-content').fadeTo('slow', 1, function () {
        // Animation complete.
      });
    }
  }

  ngOnInit() {
    /**
     * INF: added this to uninitialize data components
     */
    this.irService.setCurrentProject(null);
    this.isMobile = document.getElementsByTagName('body')[0].offsetWidth <= 600;
    this.showBarLoader = true;
    this.irService.getAllProjects().subscribe((next) => {
      const div3 = next.length % 3;
      this.projects = next.slice(0, next.length - div3);
      this.projectsLess3 = next.slice(next.length - div3);
      this.showBarLoader = false;
    });
  }

  setRoute () {
    let currentResource = this.location.path().split('/')[1];
    if (currentResource){
      this.router.navigate(['/' + currentResource]);
    }
    else {
      this.router.navigate(['/measurements']);
    }
  }


  onCloseProjSelect() {
    this.hideSelectProjects.emit();
  }

  changeProject(project: Project): void {
    // this.irService.setCurrentProjectId(project.projectId);
    this.irService.setCurrentProject(project.projectId);

    let currentResource;
    if (this.location.path() != '') {
      currentResource = this.location.path();
      currentResource = currentResource.split('/')[1];
    }
    if (currentResource === null) {
      this.router.navigate(['/']);
    } else if (currentResource === 'deployment-manager') {
      currentResource += '/' + this.location.path().split('/')[2];
      this.router.navigate([currentResource]);
    } else {
      this.router.navigate([currentResource, project.projectId]);
    }



    this.onCloseProjSelect();
  }

  onResize(event) {
    this.isMobile = event.target.innerWidth <= 600;
  }

}
