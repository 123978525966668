import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { environment } from '../../../../../environments/environment.prod';
import { DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';

@Component({
  selector: 'app-middleware',
  templateUrl: './middleware.component.html',
  styleUrls: ['./middleware.component.scss']
})
export class MiddlewareComponent implements OnInit {

  constructor(private titleService: Title, private route:ActivatedRoute, public sanitizer:DomSanitizer) { }
  iframeUrl: SafeResourceUrl;
  ngOnInit() {
    this.titleService.setTitle(this.route.snapshot.data['title']);
    this.iframeUrl =  this.sanitizer.bypassSecurityTrustResourceUrl(environment.middlewareUrl);      
  }

}
