import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';

import { Project, ProjectsJSON } from '../../../models/projects.model';
import { Deployment, DeploymentsAllJSON } from '../../../models/deployments.model';

import { IntelligentRiverService } from '../../../services/intelligent-river.service';

declare var $: any;



@Component({
  selector: 'app-deployments-map',
  templateUrl: './deployments-map.component.html',
  styleUrls: ['./deployments-map.component.scss']
})
export class DeploymentsMapComponent implements OnInit {
  // ng-map variables
  lat: number;
  lng: number;
  mapZoom: number;
  mapType: string;
  motestackIcon: string;
  // data variables to listen to irService
  projects: Project[];
  deployments: Deployment[];
  deploymentsByProjectId: any;
  deploymentDetailsByDeploymentId: any;
  currentDeploymentDetails: any;
  currentProject: Project;
  currentDeployment: Deployment;

  currentProjectId: number;
  currentDeploymentId: number;

  constructor(private router: Router,
    private route: ActivatedRoute,
    private irService: IntelligentRiverService) {
      this.lat = 30.3449153;
      this.lng = -81.8231895;
      this.mapZoom = 6;
      this.mapType = 'satellite';
      this.motestackIcon = 'assets/img/motestack/motestack.png';

      this.projects = null;
      this.deployments = null;
      this.deploymentsByProjectId = null;
      this.deploymentDetailsByDeploymentId = {};

      this.currentProject = null; this.currentProjectId = null;
      this.currentDeployment = null; this.currentDeploymentId = null;
    }

  ngOnInit() {
    this.irService.observeProjects.subscribe((projects) => {
      this.projects = projects;
    });


    this.irService.observeCurrentProject.subscribe((next) => {
      this.currentProject = next;
      if (next) {
        this.currentProjectId = next.projectId;
        this.getDeployments();
      }
    });
  }

  private getDeployments() {
    this.irService.getDeploymentsByProjId(this.currentProjectId).subscribe((newDeploymentsByProjectId) => {
      this.deploymentsByProjectId = newDeploymentsByProjectId;
    });
  }


  onDeploymentClicked(deploymentId: string): void {
    this.currentDeploymentDetails = null;
    this.irService.getDeploymentDetailsByDeployment(deploymentId).subscribe(res => {
      this.currentDeploymentDetails = res;
      $('#deployment-details-modal').modal('show');
    });
  }

  onShowHide(event: Event): void {
    const el  = <HTMLElement> event.srcElement;
    const text = el.innerHTML;
    if (text === 'Show') {
      el.innerHTML = 'Hide';
    } else {
      el.innerHTML = 'Show';
    }
  }
}
