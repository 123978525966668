import {timer as observableTimer, Observable, Subscriber, AsyncSubject, Subscription} from 'rxjs';
import {first} from 'rxjs/operators';
import {Component, OnInit, OnDestroy, ViewChild} from '@angular/core';
import {Router, ActivatedRoute} from '@angular/router';
import {IntelligentRiverService} from '../../../services/intelligent-river.service';
import {Project} from '../../../models/projects.model';
import {Deployment, DeploymentDetails} from '../../../models/deployments.model';
import {Data} from '../../../models/data.model';
import * as moment from 'moment';
import swal from 'sweetalert2';
// import {CsvService} from 'angular2-json2csv';
import {Title} from '@angular/platform-browser';
import {Location} from '@angular/common';
import {interval} from 'rxjs';
import {
    DeploymentDataApiResponse, DeploymentData,
    NewApiSensor, SensorParameter, SensorMeasurements, SensorMeasurementsJSON,
    SensorMeasurementsJSONResponse, AllSensorsMeasurements, ParamsData, DeviceParams
} from '../../../models/data.model';
import {Sensor} from '../../../models/device.model';
import cloneDeep from 'lodash/cloneDeep';
import {$e} from "codelyzer/angular/styles/chars";
import {Template} from "../../../models/template.model";

declare var Plotly: any;

interface ViewBySensorListElement {
    name: string;
    id: string;
    type: string;
}

interface FilterBySensorElement {
    name: string;
    id: string;
    checked: boolean;
}

@Component({
    selector: 'app-data',
    templateUrl: './data.component.html',
    styleUrls: ['./data.component.scss']
})
export class DataComponent implements OnInit, OnDestroy {
    deployments: Deployment[];
    filteredDeployments: Deployment[];
    sensors: Sensor[];
    params: any;
    currentDeploymentData: Deployment = null; // INF: this holds all the data related to the current deployment, except for the measurements
    currentSensorMeasurements: AllSensorsMeasurements = {}; // INF: current sensor measurements
    exportID: string = null; // exportID to check progress of export file, should be null except for when an export is in process
    selectedDeploymentData: Data[];
    selectedDeploymentDetails: any;
    devicesParamsData: DeviceParams[];
    // paramsData: ParamsData[];
    deploymentsParamsData: ParamsData[];
    chart: any;
    chartData: any;
    selectedDeployment: Deployment;
    isBarLg = false;
    startDateArchive: number;
    endDateArchive: number;
    startExportDate: number;
    endExportDate: number;
    showBarLoader: boolean;
    showProgressBar: boolean;
    showSensorProgressBar: boolean = false; // INF: separate progress bar for sensors measurements
    showExportProgressBar: boolean;
    showFilterProgressBar: boolean;
    showCompareProgressBar: boolean;
    progressValue: number;
    compareProgresValue: number;
    noCompareData: boolean;
    private subscription: Subscription;
    private tenSecondsSubscription: Subscription;
    monthInMiliseconds = 2629746000; // loading by one month
    exportDeployments: { label: string, id: string, checked: boolean, uri?: string }[] = [];
    layout = {
        autosize: true,
        margin: {
            l: 50,
            r: 50,
            b: 40,
            t: 40,
            pad: 4
        },
        xaxis: {
            // type: 'date',
        }
        // hovermode:'closest' - when near line then shown hover
    };
    config = {
        displayModeBar: true,
        modeBarButtonsToRemove: ['sendDataToCloud', 'hoverCompareCartesian', 'pan2d',
            'hoverClosestCartesian', 'hoverCompareCartesian', 'toggleSpikelines'],
        displaylogo: false
    };
    stationModalParam: string;
    calendarOptions: any = {
        parentEl: 'body',
        locale: {format: 'YYYY-MM-DD'},
        alwaysShowCalendars: false,
        ranges: {
            'Today': [moment(), moment()],
            'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
            'Last 7 Days': [moment().subtract(6, 'days'), moment()],
            'Last 30 Days': [moment().subtract(29, 'days'), moment()],
            'This Month': [moment().startOf('month'), moment().endOf('month')],
            'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
        },
        opens: 'center'
    };
    calendarModalOpns: any = {};
    /*** Filtering variables ***/
    filterDeploymentsBySensorList: FilterBySensorElement[] = [];
    filterAllCheckBoxValue = true;
    filterByDeploymentValue: string;
    /*** Configuration of view ***/
    viewBy: 'sensor' | 'deployment'; // options
    /*** Everything for sensing view ***/
    allSensors: ViewBySensorListElement[] = [];
    filteredSensors: ViewBySensorListElement[] = [];
    currentSensor: Sensor | Template;
    filterBySensorValue: string;
    selectedParamIndex: number;
    deploymentParamsData: any;
    currentSensorIndexForEachDeployment: { deploymentUri: string, sensorIndex: string } [] = [];
    loadedCount: number;
    showLoadingPercentage: boolean = false;
    paramMinValue: number = Number.MAX_VALUE;
    paramMaxValue: number = Number.MIN_VALUE;

    constructor(private router: Router,
                private irService: IntelligentRiverService,
                private location: Location,
                private titleService: Title) {
    }

    ngOnInit() {
        this.titleService.setTitle('Measurements | Sense Stream');
        Object.assign(this.calendarModalOpns, this.calendarOptions);
        this.filterByDeploymentValue = '';
        // this.viewBy = 'deployment';
        this.viewBy = 'sensor';
        this.deploymentParamsData = {};
        this.calendarModalOpns.parentEl = '#exportModal';
        this.currentSensorMeasurements = null;
        this.selectedDeploymentData = null;
        this.allSensors = [];
        // get all sensors
        this.irService.getAllSensors().subscribe(sensors => {
            //
            this.filterDeploymentsBySensorList = sensors.map(sensor => {
                return {
                    name: sensor.name,
                    id: sensor.sensorId,
                    checked: true,
                };
            });
            let allSensors = sensors.map(sensor => {
                return {
                    name: sensor.name,
                    id: sensor.sensorId,
                    type: sensor.type
                };
            });
            this.allSensors = allSensors;
            this.filteredSensors = cloneDeep(allSensors);
            /*this.irService.getTemplates().subscribe(templates => {
                Array.prototype.push.apply(allSensors, templates.map(template => {
                    return {
                        name: template.name,
                        id: template.id,
                        type: 'SDI12'
                    };
                }));
                this.allSensors = allSensors;
                this.filteredSensors = cloneDeep(allSensors);
                console.log('all filtered sensors are', this.filteredSensors);
            });*/
        });
        this.irService.getAllDeployments().subscribe(deployments => {
            this.currentDeploymentData = null;
            this.deployments = deployments;
            console.log('all deployments are ', this.deployments);
            this.filterDeploymentsByName();
            this.filteredDeployments = cloneDeep(deployments);
            if (!deployments.length) {
                swal('No deployments', 'No deployments have been found for this project. Please, try again later.', 'warning');
                this.currentDeploymentData = null;
                this.deployments = [];
                this.filteredDeployments = [];
            }
            deployments.forEach(d => {
                this.exportDeployments.push({
                    id: d.deploymentId,
                    checked: false,
                    label: d.name,
                    uri: d.deploymentUri
                });
            });
            this.setupResizing();
        });
    }

    setViewBySensor() {
        this.viewBy = 'sensor';
    }

    setViewByDeployment() {
        this.viewBy = 'deployment';
    }

    filterDeploymentsByName(): void {
        this.filteredDeployments = this.deployments.filter(i => i.name.toLowerCase().includes(this.filterByDeploymentValue.toLowerCase()));
    }

    filterSensorsByName(): void {
        this.filteredSensors = this.allSensors.filter(i => i.name.toLowerCase().includes(this.filterBySensorValue.toLowerCase()));
    }

    resetDeploymentsFilter(): void {
        this.filterByDeploymentValue = '';
        this.filteredDeployments = this.deployments;
    }

    resetSensorsFilter(): void {
        this.filterBySensorValue = '';
        this.filteredSensors = this.allSensors;
    }

    /**
     * Sensor View stuff
     */
    handleParamClick(paramIndex) {
        this.selectedParamIndex = paramIndex;
        if (this.currentSensorIndexForEachDeployment.length === 0) {
            swal('Warning', 'No deployments found with the selected sensor', 'warning');
        } else {
            this.loadNewSensorParamsData();
        }
    }

    getParamVisibility(param): boolean {
        // if sensor is SDI12 then check if the visibility is set to high
        let paramVisibility = this.currentSensor.type !== 'SDI12' || (this.currentSensor.type === 'SDI12' && param.isVisible === true);
        // console.log('current sensor is', this.currentSensor, 'param is', param, 'param visibility is ', paramVisibility);
        return paramVisibility;
    }

    loadNewSensorParamsData() {
        if (this.currentSensor && this.selectedParamIndex !== undefined) {
            let timestamps;
            if (!this.startDateArchive || !this.endDateArchive) {
                timestamps = this.irService.getTodayRangeTimestamps();
            } else {
                timestamps = {start: this.startDateArchive, end: this.endDateArchive};
            }
            // Load data for each deployment separately
            this.deploymentParamsData = {};
            this.loadedCount = 0;
            this.showLoadingPercentage = true;
            this.paramMinValue = Number.MAX_VALUE;
            this.paramMaxValue = Number.MIN_VALUE;
            this.currentSensorIndexForEachDeployment.forEach(el => {
                this.irService.getAverageReadingsBySensorParams(el.deploymentUri, el.sensorIndex,
                    this.selectedParamIndex, timestamps.start, timestamps.end).subscribe(res => {
                    this.deploymentParamsData[el.deploymentUri] = res;
                    this.checkMinMax(res.value);
                    this.checkLoadingStatusCompleteness();
                }, err => {
                    this.checkLoadingStatusCompleteness();
                });
            });
        }
    }

    checkMinMax(value) {
        console.log('the value is', value);
        if (value > this.paramMaxValue) {
            this.paramMaxValue = value;
        } else if (value < this.paramMinValue) {
            this.paramMinValue = value;
        }
    }

    countLoadingPercentage() {
        if (this.deployments.length === 0 || this.loadedCount === 0) {
            return 0;
        }
        return 100 * this.loadedCount / Object.values(this.currentSensorIndexForEachDeployment).length;
    }

    checkLoadingStatusCompleteness() {
        this.loadedCount += 1;
        if (this.loadedCount === Object.values(this.currentSensorIndexForEachDeployment).length) {
            this.showLoadingPercentage = false;
        }
    }

    resetSensorView() {
        this.isBarLg = false;
        this.currentSensor = null;
        this.deploymentParamsData = null;
        this.currentSensorIndexForEachDeployment = [];
        this.selectedParamIndex = undefined;
        this.showLoadingPercentage = false;
    }

    /**
     * End sensor view stuff
     */

    setupResizing() {
        // set modal resizable and dragable
        return observableTimer(10).subscribe(r => {
            $('.datarangepicker').css('opacity', 0);
            (<any>$('#compareDataModalContent')).resizable({
                resize: (event, ui) => {
                    $('#chartdivcompare').height($('#compare-modal-body').height());
                    Plotly.relayout('chartdivcompare', {'xaxis.autorange': true, 'yaxis.autorange': true});
                }
            });
            (<any>$('#compareDataModalContent')).draggable({
                handle: '.modal-header'
            });
            (<any>$('#stationModalContent')).resizable({
                resize: (event, ui) => {
                    $('#stationPlot').height($('#station-modal-body').height());
                    Plotly.relayout('stationPlot', {'xaxis.autorange': true, 'yaxis.autorange': true});
                }
            });
            (<any>$('#stationModalContent')).draggable({
                handle: '.modal-header'
            });
            (<any>$('#exportModalContent')).resizable({});
            (<any>$('#exportModalContent')).draggable({
                handle: '.modal-header'
            });
            $('#compareDataModal').on('shown.bs.modal', function () {
                Plotly.relayout('chartdivcompare', {'xaxis.autorange': true, 'yaxis.autorange': true});
            });
            $('#stationModal').on('shown.bs.modal', function () {
                Plotly.relayout('stationPlot', {'xaxis.autorange': true, 'yaxis.autorange': true});
            });
        });
    }

    ngOnDestroy() {
    }

    /**
     * INF: This gets called every time a deployment in the left panel gets clicked
     * @param $event
     */
    onDeploymentSelect($event) {
        console.log('deployment got selected', $event);
        this.showProgressBar = true;
        this.selectedDeployment = this.deployments.find(i => i.deploymentId == $event); // find the deployment structure from the local state
        this.irService.getDeploymentData(this.selectedDeployment.deploymentUri).subscribe(res => {
            console.log('deployment information returned ', res);
            this.currentDeploymentData = res;
            this.showProgressBar = false;
        });
    }

    /**
     * INF: This gets called every time a sensor in the left panel gets clicked
     * @param event
     */
    onSensorSelect(sensor) {
        this.showProgressBar = true;
        this.irService.getSensorEntityInfo(sensor.id).subscribe(res => {
            this.findDeploymentsWithSensor(sensor.id);
            this.currentSensor = res;
            this.currentSensor.type = sensor.type;
            this.showProgressBar = false;
            console.log('got sensor: ', res);
        });
    }

    findDeploymentsWithSensor(sensorId) {
        // find all deployments that have the selected sensor for events when param is clicked
        this.currentSensorIndexForEachDeployment = [];
        console.log('sensor id is ', sensorId);
        console.log('all deployments are ', this.deployments);
        this.deployments.forEach(d => {
            let index = null;
            d.sensors.some(function (el) {
                if (el.sensorId == sensorId) {
                    console.log('deployment has sensor', d);
                    index = el.sensorIndex;
                    return true;
                }
                return false;
            });
            if (index) {
                this.currentSensorIndexForEachDeployment.push({
                    deploymentUri: d.deploymentUri,
                    sensorIndex: index
                });
            }
        });
    }

    /**
     * show data plot
     * @param sensorIndex
     * @param paramIndex
     */
    showDataPlot(sensorIndex, paramIndex) {
        console.log('inside show data plot');
        // $('#' + sensorIndex + paramIndex).toggle();
        // "2020-03-10T04:06:02.000Z" // old api
        // "03/10/2020 00:01:01" //new api
        if (this.currentSensorMeasurements && this.currentSensorMeasurements.hasOwnProperty(sensorIndex)) {
            console.log('current sensor measurements: ', this.currentSensorMeasurements);
            console.log('sensor index: ', sensorIndex, 'param index: ', paramIndex);
            let x = this.currentSensorMeasurements['' + sensorIndex].map(i => {
                return new Date(i.time);
            });
            let y = this.currentSensorMeasurements['' + sensorIndex].map(i => (
                i.readings[paramIndex]
            ));
            let currentSensor = this.currentDeploymentData.sensors.find(sensor => {
                return sensor.sensorIndex === sensorIndex;
            });
            const trace = {
                type: 'scatter',
                mode: 'lines',
                name: currentSensor.name,
                hoverinfo: 'x + y + name',
                hoverlabel: {
                    namelength: -1, // shows the whole name regardless of length.
                },
                x: x,
                y: y,
                line: {color: this.getRandomColor()}
            };
            console.log('data for a plot is', trace);
            const plotly = Plotly.newPlot(('chartdiv' + sensorIndex + paramIndex), [trace], this.layout, this.config);
            $('#' + sensorIndex + paramIndex).toggle();
        }
    }

    getSensorsMeasurements(sensorIndex) {
        if (this.currentSensorMeasurements && this.currentSensorMeasurements['' + sensorIndex]) {
            this.currentSensorMeasurements['' + sensorIndex] = null;
        } else {
            this.showSensorProgressBar = true;
            // This initializes empty structure and makes sure that only one sensor data is displayed at a time
            this.currentSensorMeasurements = {};
            if (this.startDateArchive) {
                this.irService.getSensorMeasurements(this.currentDeploymentData.deploymentUri, sensorIndex,
                    this.startDateArchive, this.endDateArchive).subscribe(res => {
                    if (res.length > 0) {
                        this.currentSensorMeasurements['' + sensorIndex] = res;
                    } else {
                        swal('Warning!', `No data`, 'warning');
                    }
                    this.showSensorProgressBar = false;
                }, error => {
                    swal('Error!', error.error.errorMessage, 'error');
                    this.showSensorProgressBar = false;
                });
            } else {
                this.irService.getSensorMeasurementsForToday(this.currentDeploymentData.deploymentUri, sensorIndex).subscribe(res => {
                        if (res.length > 0) {
                            this.currentSensorMeasurements['' + sensorIndex] = res;
                        } else {
                            swal('Warning!', `No data`, 'warning');
                        }
                        this.showSensorProgressBar = false;
                    },
                    error => {
                        swal('Error!', error.error.errorMessage, 'error');
                        this.showSensorProgressBar = false;
                    });
            }
        }
    }

    compareParams() {
        const compareData = [];
        this.devicesParamsData.forEach(device => {
            const paramsData = device.paramsData;
            paramsData.forEach((data, indx) => {
                data.values.sort((a, b) => {
                    return moment(a.date).unix() - moment(b.date).unix();
                });
                const trace = {
                    type: 'scatter',
                    mode: 'lines',
                    name: data.paramName,
                    hoverinfo: 'x + y + name',
                    hoverlabel: {
                        namelength: -1, // shows the whole name regardless of length.
                    },
                    x: data.values.map(i => i.date),
                    y: data.values.map(i => i.val),
                    line: {color: this.getRandomColor()}
                };
                compareData.push(trace);
            });
        });
        Plotly.newPlot('chartdivcompare', compareData, this.layout, this.config);
    }

    async onCompareClick(disabled) {
        Plotly.newPlot('chartdivcompare', [], this.layout, this.config);
        if (!disabled) {
            this.compareParams();
            return false; // if compare in deployment level, else compare all deployments data
        }
        this.noCompareData = false;
        this.showCompareProgressBar = true;
        this.compareProgresValue = 0;
        const compareData = [];
        for (let i = 0; i < this.deployments.length; i++) {
            if (this.startDateArchive && this.endDateArchive) {
                const data = await this.getDataArchiveByFewCalls(this.deployments[i].deploymentId, this.startDateArchive, this.endDateArchive, this.deployments.length, this.compareProgresValue);
                if (data.length) {
                    compareData.push(...this.addDataToDeploymentComparePlot(data, this.deployments[i]));
                }
            } else {
                const data = await this.irService.getDataRecentAsync(this.deployments[i].deploymentId);
                if (data.length) {
                    compareData.push(...this.addDataToDeploymentComparePlot(data, this.deployments[i]));
                }
            }
            this.compareProgresValue = (i + 1) / this.deployments.length;
        }
        this.showCompareProgressBar = false;
        if (compareData.length) {
            Plotly.newPlot('chartdivcompare', compareData, this.layout, this.config);
        } else {
            this.noCompareData = true;
        }
    }

    addDataToDeploymentComparePlot(data: Data[], deployment: Deployment): any[] {
        const parameterIds = data[0].readings.map(i => i.parameterId);
        let addArrayIndx = 0;
        this.deploymentsParamsData = null;
        if (this.deploymentsParamsData) {
            addArrayIndx = this.deploymentsParamsData.length;
        } else {
            this.deploymentsParamsData = [];
        }
        parameterIds.forEach((i, paramIndx) => {
            const indx = paramIndx + addArrayIndx;
            this.deploymentsParamsData[indx] = {};
            this.deploymentsParamsData[indx].paramId = i;
            const param = this.params[i];
            if (param) {
                this.deploymentsParamsData[indx].paramName = `${param.label} (${deployment.name})`;
            } else {
                this.deploymentsParamsData[indx].paramName = `No title (${i}) (${deployment.name})`;
            }
            this.deploymentsParamsData[indx].values = [];
            data.map(innerData => {
                this.deploymentsParamsData[indx].values.push({
                    val: innerData.readings[paramIndx].value,
                    date: new Date(innerData.observationDateTime)
                });
            });
        }, this);
        const compareData = [];
        this.deploymentsParamsData.forEach(data => {
            data.values.sort((a, b) => {
                return moment(a.date).unix() - moment(b.date).unix();
            });
            const trace = {
                type: 'scatter',
                mode: 'lines',
                name: data.paramName,
                hoverinfo: 'x + y + name',
                hoverlabel: {
                    namelength: -1, // shows the whole name regardless of length.
                },
                x: data.values.map(i => i.date),
                y: data.values.map(i => i.val),
                line: {color: this.getRandomColor()}
            };
            compareData.push(trace);
        });
        return compareData;
    }

    getRandomColor() {
        const letters = '0123456789ABCDEF';
        let color = '#';
        for (let i = 0; i < 6; i++) {
            color += letters[Math.floor(Math.random() * 16)];
        }
        return color;
    }

    selectedArchiveDate(value: any) {
        // clear compare modal plot
        Plotly.newPlot('chartdivcompare', [], this.layout, this.config);
        if (value.label == 'Today') {
            this.startDateArchive = null;
            this.endDateArchive = null;
        } else {
            this.startDateArchive = moment(value.start.valueOf()).local().unix() * 1000;
            this.endDateArchive = moment(value.end.valueOf()).local().unix() * 1000;
        }
        /**
         * INF: new api change. Now check if the current mode is in sensors then get new data
         */
        if (this.currentSensorMeasurements && Object.keys(this.currentSensorMeasurements).length > 0) {
            const keys = Object.keys(this.currentSensorMeasurements);
            this.showSensorProgressBar = true;
            keys.forEach(key => {
                if (this.startDateArchive) {
                    this.irService.getSensorMeasurements(this.currentDeploymentData.deploymentUri,
                        key, this.startDateArchive, this.endDateArchive).subscribe(res => {
                        if (res.length > 0) {
                            this.currentSensorMeasurements[key] = res;
                        } else {
                            swal('Warning!', `No data`, 'warning');
                        }
                        this.showSensorProgressBar = false;
                    }, error => {
                        swal('Error!', error.error.errorMessage, 'error');
                        this.showSensorProgressBar = false;
                    });
                } else {
                    this.irService.getSensorMeasurementsForToday(this.currentDeploymentData.deploymentUri,
                        key).subscribe(res => {
                        if (res.length > 0) {
                            this.currentSensorMeasurements[key] = res;
                        } else {
                            swal('Warning!', `No data`, 'warning');
                        }
                        this.showSensorProgressBar = false;
                    }, error => {
                        swal('Error!', error.error.errorMessage, 'error');
                        this.showSensorProgressBar = false;
                    });
                }
            });
        }
        /** Check if current viewBy is by sensors, if so then load new statistics **/
        if (this.viewBy === 'sensor') {
        }
    }

    selectExportDateRange(value: any) {
        if (value.label === 'Today') {
            this.startExportDate = null;
            this.endExportDate = null;
        } else {
            this.startExportDate = moment(value.start.valueOf()).local().unix() * 1000;
            this.endExportDate = moment(value.end.valueOf()).local().unix() * 1000;
        }
    }

    exportSelectAll(checked: boolean) {
        this.exportDeployments.forEach(i => i.checked = checked);
    }

    /**
     * INF: this function is called whenever export deployments modal's export button is called
     */
    async exportData() {
        const exportDeployments = this.exportDeployments.filter(i => i.checked);
        if (exportDeployments.length === 0) {
            await swal('No deployments selected', 'Please select at least one deployment to export', 'warning');
            return;
        }
        this.showExportProgressBar = true;
        const exportDeploymentsUris = exportDeployments.map(i => i.uri);
        let start = 0;
        let end = 0;
        if (this.startExportDate && this.endExportDate) {
            start = this.startExportDate;
            end = this.endExportDate;
        } else {
            const todayTimestamps = this.irService.getTodayRangeTimestamps();
            start = todayTimestamps.start;
            end = todayTimestamps.end;
        }
        this.irService.getDeploymentsMeasurementsExportFile(start, end, exportDeploymentsUris).subscribe(res => {
            this.exportID = res.exportId;
            const tenSecInterval = interval(5000);
            let tenSecondsSubscription = tenSecInterval.subscribe(x => {
                console.log('x is ', x);
                this.irService.checkMeasurementsExportStatus(this.exportID).subscribe(res => {
                    console.log('DATA: got response of status: ', res);
                    if (res.exportStatus === 'success') {
                        this.completeExport(tenSecondsSubscription);
                        this.downloadFromUrl(res.downloadUrl, 'SenseStream Measurements Export');
                    } else if (res.exportStatus === 'error') {
                        this.completeExport(tenSecondsSubscription);
                        swal('Error', 'Export failed, please trt again', 'error');
                    } else if (x === 240) {
                        this.completeExport(tenSecondsSubscription);
                        swal('Time out', 'The request timed out, please try again', 'error');
                        console.log('x is ', x, ' the timer got unsubscribed');
                    }
                });
            });
        });
    }

    completeExport(sub) {
        this.exportID = null;
        this.showExportProgressBar = false;
        sub.unsubscribe();
    }

    /**
     * FILTER functions
     */
    filterSelectAll(checked: boolean) {
        this.filterDeploymentsBySensorList.forEach(i => i.checked = checked);
    }

    applyFilter() {
        const sensorsToInclude = this.filterDeploymentsBySensorList.filter(i => i.checked).map(sensor => sensor.id);
        if (sensorsToInclude.length === 0) {
            swal('No sensors selected', 'Please select at least one sensor to include', 'warning');
            return;
        }
        this.showFilterProgressBar = true;
        this.irService.getDeploymentsBySensorIds(sensorsToInclude).subscribe(response => {
            console.log('got deployments: ', response);
            if (response.errorMessage === 'success') {
                this.deployments = response.result;
                this.filterDeploymentsByName();
                this.showFilterProgressBar = false;
                (<any>$('#filterDeploymentsModal')).modal('hide');
            } else {
                swal('Error', 'Something went wrong. Please try again', 'error');
                this.deployments = [];
                this.filteredDeployments = [];
                this.showFilterProgressBar = false;
                (<any>$('#filterDeploymentsModal')).modal('hide');
            }
        });
    }

    downloadFromUrl(url: string, name: string) {
        let link = document.createElement('a');
        link.download = name;
        link.href = url;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    compareModalFullScrean() {
        const currentWidth = $('#compareModalDialog').width();
        if (currentWidth >= $('#compareModalDialog').width('100%').width()) {
            $('#compareModalDialog').width('60%').height('55%').css('margin', '20%').css('margin-top', '10%');
        } else {
            $('#compareModalDialog').width('100%').height('100%').css('margin', '0').css('padding', '0');
        }
        $('#compareDataModalContent').css('height', '100%').css('width', '100%');
        $('#chartdivcompare').height($('#compare-modal-body').height());
        Plotly.relayout('chartdivcompare', {'xaxis.autorange': true, 'yaxis.autorange': true});
    }

    onStationModalFullScreenClick() {
        const currentWidth = $('#stationModalDialog').width();
        if (currentWidth >= $('#stationModalDialog').width('100%').width()) {
            $('#stationModalDialog').width('60%').height('55%').css('margin', '20%').css('margin-top', '10%');
        } else {
            $('#stationModalDialog').width('100%').height('100%').css('margin', '0').css('padding', '0');
        }
        $('#stationModalContent').css('height', '100%').css('width', '100%');
        $('#stationPlot').height($('#station-modal-body').height());
        Plotly.relayout('stationPlot', {'xaxis.autorange': true, 'yaxis.autorange': true});
    }

    /**
     * modal click
     * @param sensorIndex
     * @param paramIndex
     */
    onShowModalClick(sensorIndex, paramIndex) {
        let x = this.currentSensorMeasurements['' + sensorIndex].map(i => {
            return new Date(i.time);
        });
        let y = this.currentSensorMeasurements['' + sensorIndex].map(i => (
            i.readings[paramIndex]
        ));
        let currentSensor = this.currentDeploymentData.sensors.find(sensor => {
            return sensor.sensorIndex === sensorIndex;
        });
        this.stationModalParam = currentSensor.parameters[paramIndex].name;
        const trace = {
            type: 'scatter',
            mode: 'lines',
            name: currentSensor.parameters[paramIndex].name,
            hoverinfo: 'x + y + name',
            hoverlabel: {
                namelength: -1, // shows the whole name regardless of length.
            },
            x: x,
            y: y,
            line: {color: this.getRandomColor()}
        };
        // console.log('the current param name is ', this.currentDeploymentData.motestack.sensors[sensorIndex].parameters[paramIndex].name);
        const plotly = Plotly.newPlot('stationPlot', [trace], this.layout, this.config);
    }

    async getDataArchiveByFewCalls(deploymentId: string, startDate: number, endDate: number, diagnisticCount: number = null, initValProgress: number = null) {
        this.progressValue = 0;
        const dataArray = [];
        const requestCount = Math.ceil((endDate - startDate) / this.monthInMiliseconds);
        for (let i = 0; i <= requestCount;) {
            const partStartDate = startDate;
            const partEndDate = endDate;
            if (requestCount !== 1) {
                const partStartDate = i * this.monthInMiliseconds + startDate;
                let partEndDate = partStartDate + this.monthInMiliseconds;
                if (partEndDate > endDate) {
                    partEndDate = endDate;
                }
            }
            const response = await this.irService.getDataArchive(deploymentId, partStartDate, partEndDate).pipe(first()).toPromise();
            dataArray.push(...response);
            this.progressValue = ++i / requestCount;
            if (diagnisticCount) {
                const maxRes = 1 / diagnisticCount;
                this.compareProgresValue = initValProgress + this.progressValue * maxRes;
            }
        }
        return dataArray;
    }

    monthDiff(d1num: number, d2num: number) {
        const d1 = new Date(d1num);
        const d2 = new Date(d2num);
        return d2.getMonth() - d1.getMonth() + (12 * (d2.getFullYear() - d1.getFullYear()));
    }
}
