import {AfterViewInit, Component, OnChanges, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {IntelligentRiverService} from '../../../../../services/intelligent-river.service';
import {Router, ActivatedRoute, Params} from '@angular/router';
import {Device, SensingDeviceManagerList} from '../../../../../models/device.model';
import {Title} from '@angular/platform-browser';
import {SortService} from '../../../../../services/sort.service';
import {TemplateManagerList} from '../../../../../models/template.model';
import swal from "sweetalert2";
import {MatTableDataSource} from '@angular/material/table';
import {MatSort} from '@angular/material/sort';
import {MatPaginator} from "@angular/material/paginator";


@Component({
    selector: 'app-sensor-list',
    templateUrl: './sensor-list.component.html',
    styleUrls: ['./sensor-list.component.scss']
})
export class SensorListComponent implements OnInit, OnChanges {
    sensors: SensingDeviceManagerList[];
    sensorsTableData: any;
    displayedColumns = ['name', 'type'];

    // @ViewChild(MatSort, {static: true}) sortItems: MatSort;
    @ViewChild(MatSort, {static: true}) sort: MatSort;

    constructor(private irService: IntelligentRiverService, private router: Router, private titleService: Title, private route: ActivatedRoute, private sortService: SortService) {
    }

    ngOnInit() {
        this.titleService.setTitle(this.route.snapshot.data['title']);
        this.irService.getAllSensors().subscribe(sensors => {
            console.log('all sensors are ',sensors);
            this.sensors = this.sortService.sortByAlphabet('name', sensors);
            this.sensorsTableData = new MatTableDataSource(sensors);

            this.sensorsTableData.sort = this.sort;

            // setTimeout(() => {
            //     this.sensorsTableData.sort = this.sortTable;
            // }, 50);
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if ('sensorsTableData' in changes) {
            if (changes.deploymentParamsData.currentValue) {
                this.sensorsTableData.sort = this.sort;
                // this.sensorsTableData.paginator = this.paginator;
            }
        }
    }

   /* filterItem(filter: string): void {
        this.filteredDevices = this.devices.filter(i => i.name.toLowerCase().includes(filter.toLowerCase()));
    }*/

    applyFilter(event: Event) {
        const filterValue = (event.target as HTMLInputElement).value;
        this.sensorsTableData.filter = filterValue.trim().toLowerCase();
    }

    updateDevice(device: SensingDeviceManagerList): void {
        // swal('Warning', 'This feature is not available at the moment', 'warning');
        if (device == null) {
            this.router.navigate(['./deployment-manager/sensors', 'new']);
        } else {
            this.router.navigate(['./deployment-manager/sensors', device.sensorId]);
        }
    }
}
