// crypto-js api: https://code.google.com/archive/p/crypto-js/
// examples: https://github.com/brix/crypto-js

import { Component, OnInit, AfterViewInit } from '@angular/core';

import { AuthService } from '../../../services/auth.service';

import { User, UserAccount, UserJSON } from '../../../models/user.model';

import * as $ from 'jquery';
import * as CryptoJS from 'crypto-js';
import swal from "sweetalert2";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, AfterViewInit {
  username: string;
  email: string;
  password: string;
  confirm: string;

  user: User;
  newUser: User;

  attemptingToSignIn: boolean;
  allowLogin: boolean;
  loginStatus: boolean;
  loginAttempts: {};

  constructor(private authService: AuthService) {
    this.username = '';
    this.email = '';
    this.password = '';
    this.confirm = '';

    this.attemptingToSignIn = false;
    this.allowLogin = true;
    this.loginStatus = false;
    this.loginAttempts = {};
  }

  ngOnInit() {
  }

  ngAfterViewInit() {
    $('body');
  }

  onSignIn(value, invalid): void {
    if (invalid) {
      this.allowLogin = false;
    } else if (this.password.includes("'") || this.password.includes('"')){
      // this.allowLogin = false;
      swal('Error!', `Description: password cannot contain symbols ' or "`, 'error');
    } else {
      this.attemptingToSignIn = true;
      this.authService.postSignIn(this.username, this.password).subscribe(data => {
        // console.log(data);
        if (data['errorMessage'] === 'success') {
          this.user = data.result;
          // this.user.token = data.user.token; // INF: I am not sure why there is a need for double assignment
          this.attemptingToSignIn = false;
          this.username = '';
          this.password = '';
        } else {
          this.handleSignInErrorResponse();
        }
      }, error => {
        if ('errorCode' in error.error && 'errorMessage' in error.error) {
          swal('Error!', `Description: Wrong username/password combination. ${error.error.errorMessage}`, 'error');
        }
        else {
          swal('Error!', 'Something went wrong. Please try again.', 'error');
        }
        this.handleSignInErrorResponse();
      });
    }
  }

  handleSignInErrorResponse () {
    this.allowLogin = false;
    setTimeout(() => {
      this.attemptingToSignIn = false;
      this.allowLogin = true;
    }, 1000);

    if (this.email in this.loginAttempts) {
      this.loginAttempts[this.email] += 1;
    } else {
      this.loginAttempts[this.email] = 0;
    }
  }

  isLoggedIn() {
    return this.authService.isLoggedIn();
  }

  onSignUp(value, invalid) {
    if (invalid) {
      // console.log('sign up invalid');
    } else {
      // console.log('sign up not implemented');
    }
  }
}
