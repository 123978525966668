import {Component, OnInit} from '@angular/core';
import {IntelligentRiverService} from '../../../../../services/intelligent-river.service';
import {Router, ActivatedRoute, Params} from '@angular/router';
import {Motestack, MotestackManagerList} from '../../../../../models/motestack.model';
import {Title} from '@angular/platform-browser';
import {SortService} from '../../../../../services/sort.service';
import swal from "sweetalert2";

@Component({
    selector: 'app-motestack-list',
    templateUrl: './motestack-list.component.html',
    styleUrls: ['./motestack-list.component.scss']
})
export class MotestackListComponent implements OnInit {
    motestacks: MotestackManagerList[];
    filteredMotestacks: MotestackManagerList[];

    constructor(private irService: IntelligentRiverService, private router: Router, private titleService: Title, private route: ActivatedRoute, private sort: SortService) {
    }

    ngOnInit() {
        this.titleService.setTitle(this.route.snapshot.data['title']);
        this.irService.getMotestacks().subscribe(motestacks => {
            this.motestacks = this.sort.sortByAlphabet('name', motestacks);
            this.filteredMotestacks = Object.assign([], this.motestacks);
        });
    }

    filterItem(filter: string): void {
        this.filteredMotestacks = this.motestacks.filter(i => i.name.toLowerCase().includes(filter.toLowerCase()));
    }

    updateMotestack(motestack: MotestackManagerList): void {
        if (motestack == null) {
            this.router.navigate(['./deployment-manager/motestacks', 'new']);
        } else {
          this.router.navigate(['./deployment-manager/motestacks', motestack.id]);
        }
    }
}
