import {Component, OnInit, Input, Output, SimpleChanges, EventEmitter, OnChanges} from '@angular/core';
import { Deployment } from '../../../../../../models/deployments.model';

@Component({
  selector: 'app-create-deployment-map',
  templateUrl: './create-deployment-map.component.html',
  styleUrls: ['./create-deployment-map.component.scss']
})
export class CreateDeploymentMapComponent implements OnInit, OnChanges {
  @Input() deployment: Deployment;
  @Input() lat;
  @Input() lng;
  @Output() onCoordinateChange = new EventEmitter() ;
  map: google.maps.Map;
  marker: google.maps.Marker ;

  constructor() { }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.marker && this.lat && this.lng) {
      this.marker.setPosition(new google.maps.LatLng(this.lat, this.lng));
    }
  }

  onMapReady(map) {
    this.map = map;
  }

  onMapClick(event) {
    this.onCoordinateChange.emit({lat: event.latLng.lat(), lng: event.latLng.lng()});
  }

  onMarkerInit(marker) {
    this.marker = marker;
  }

}
