import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {NgStyle} from '@angular/common';
import {RouterModule, Routes, RouterStateSnapshot} from '@angular/router';         // ActivatedRoute
import {Title} from '@angular/platform-browser';
import {HttpClientModule} from '@angular/common/http';        // HttpHeaders, HttpRequest, HttpResponse, HttpParams
import {FormsModule} from '@angular/forms';

// ng-gui map
import {NguiMapModule} from '@ngui/map';
import {SortablejsModule} from 'ngx-sortablejs';

import {NgxJsonViewerModule} from 'ngx-json-viewer';
// import { AgmJsonViewerModule } from 'agm-json-viewer';
import {NgDatepickerModule} from 'ng2-datepicker';

import {AuthService} from './services/auth.service';
import {IntelligentRiverService} from './services/intelligent-river.service';
import {OnlyLoggedGuardService} from './services/only-logged-guard.service';


import {AppComponent} from './app.component';
import {NavbarComponent} from './components/all/navbar/navbar.component';
import {FooterComponent} from './components/all/footer/footer.component';
import {ProjectMapComponent} from './components/all/project-map/project-map.component';
import {LoginComponent} from './components/all/login/login.component';
import {DeploymentsMapComponent} from './components/all/deployments-map/deployments-map.component';
import {MaintenanceComponent} from './components/routes/maintenance/maintenance.component';
import {DataComponent} from './components/routes/data/data.component';
import {DeploymentsComponent} from './components/routes/deployments/deployments.component';
import {DeploymentListComponent} from './components/routes/deployment-manager/deployments/deployment-list/deployment-list.component';
import {DeploymentFormComponent} from './components/routes/deployment-manager/deployments/deployment-form/deployment-form.component';
import {DeploymentManagerComponent} from './components/routes/deployment-manager/deployment-manager.component';
import {StatusComponent} from './components/routes/status/status.component';
import {MotestackListComponent} from './components/routes/deployment-manager/motestacks/motestack-list/motestack-list.component';
import {MotestackFormComponent} from './components/routes/deployment-manager/motestacks/motestack-form/motestack-form.component';
import {SensingTemplatesComponent} from './components/routes/deployment-manager/motestacks/motestack-form/sensing-templates/sensing-templates.component';
import {TemplateListComponent} from './components/routes/deployment-manager/templates/template-list/template-list.component';
import {TemplateFormComponent} from './components/routes/deployment-manager/templates/template-form/template-form.component';
import {UserListComponent} from './components/routes/deployment-manager/users/user-list/user-list.component';
import {UserFormComponent} from './components/routes/deployment-manager/users/user-form/user-form.component';
import {ErrorsListComponent} from './components/routes/deployment-manager/system-errors/errors-list/errors-list.component';
import {NotificationListComponent} from './components/routes/deployment-manager/notifications/notification-list/notification-list.component';
import {NotificationFormComponent} from './components/routes/deployment-manager/notifications/notification-form/notification-form.component';
import {DiagnosticComponent} from './components/routes/diagnostic/diagnostic.component';
import {Daterangepicker} from 'ng2-daterangepicker';
import {DataDeploymentsMapComponent} from './components/all/data-deployments-map/data-deployments-map.component';
import {ProjectSelectComponent} from './components/all/project-select/project-select.component';
import {MiddlewareComponent} from './components/routes/deployment-manager/middleware/middleware.component';
// tslint:disable-next-line:max-line-length
import {CreateDeploymentMapComponent} from './components/routes/deployment-manager/deployments/deployment-form/create-deployment-map/create-deployment-map.component';
import {SortService} from './services/sort.service';
import {ProjectsComponent} from './components/routes/deployment-manager/projects/projects-list/projects.component';
import {ProjectsFormComponent} from './components/routes/deployment-manager/projects/projects-form/projects-form.component';
import {NotificationBoundaryFormComponent} from './components/routes/deployment-manager/notifications/notification-boundary-form/notification-boundary-form.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';

// Material components
import {MatChipsModule} from '@angular/material/chips';
import {MatFormFieldModule} from '@angular/material';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import { DeploymentNotificationSettingsComponent } from './components/all/deployment-notification-settings/deployment-notification-settings.component';
import {MatCheckboxModule} from "@angular/material/checkbox";
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import {MatExpansionModule} from "@angular/material/expansion";
import {MatTableModule} from "@angular/material/table";
import {AgmCoreModule} from "@agm/core";
import {AgmJsMarkerClustererModule} from "@agm/js-marker-clusterer";
import {AgmSnazzyInfoWindowModule} from "@agm/snazzy-info-window";
import { SensorFormComponent } from './components/routes/deployment-manager/sensors/sensor-form/sensor-form.component';
import { SensorListComponent } from './components/routes/deployment-manager/sensors/sensor-list/sensor-list.component';
import {MatInputModule} from "@angular/material/input";
import {MatSelectModule} from "@angular/material/select";
import {DragDropModule} from "@angular/cdk/drag-drop";
import {MatSortModule} from '@angular/material/sort';
import {MatPaginatorModule} from "@angular/material/paginator";
import {MatCardModule} from "@angular/material/card";
import {MatDatepickerModule} from "@angular/material/datepicker";
import {MatNativeDateModule} from '@angular/material';
import { MatMomentDateModule } from "@angular/material-moment-adapter";
import { ErrorModalComponent } from './components/routes/deployment-manager/system-errors/error-modal/error-modal.component';
import {MatDialogModule} from "@angular/material/dialog";

const routes: Routes = [
    {path: '', redirectTo: '/measurements', pathMatch: 'full'},
    // {path: '', redirectTo: '/diagnostics', pathMatch: 'full' },
    // {path: 'diagnostics', component: ProjectSelectComponent, data: {title: 'Select a project | Sense Stream', showNavbar: false}},
    // {path: 'measurements', component: ProjectSelectComponent, data: {title: 'Select a project | Sense Stream', showNavbar: false}},
    {path: 'diagnostics/:projectId', component: DiagnosticComponent, data: {title: 'Diagnostics | Sense Stream'}},
    {path: 'measurements/:projectId', component: DataComponent, data: {title: 'Measurements | Sense Stream'}},
    {path: 'measurements', component: DataComponent, data: {title: 'Measurements | Sense Stream'}},
    {path: 'diagnostics', component: DiagnosticComponent, data: {title: 'Diagnostics | Sense Stream'}},
    {path: 'status', component: StatusComponent, data: {title: 'Status | Sense Stream'}},
    {
        path: 'deployment-manager', component: DeploymentManagerComponent, canActivate: [OnlyLoggedGuardService], children: [
            {path: '', redirectTo: '/deployment-manager/deployments', pathMatch: 'full'},
            {path: 'sensors', component: SensorListComponent, data: {title: 'Sensors Manager | Sense Stream'}},
            {path: 'sensors/:id', component: SensorFormComponent, data: {title: 'Sensors Manager | Sense Stream'}},
            {path: 'templates', component: TemplateListComponent, data: {title: 'Template Manager | Sense Stream'}},
            {path: 'templates/:id', component: TemplateFormComponent, data: {title: 'Template Manager | Sense Stream'}},
            {path: 'motestacks', component: MotestackListComponent, data: {title: 'Motestack Manager | Sense Stream'}},
            {path: 'motestacks/:id', component: MotestackFormComponent, data: {title: 'Motestack Manager | Sense Stream'}},
            {path: 'motestacks/:id/duplicate', component: MotestackFormComponent, data: {title: 'Motestack Manager | Sense Stream'}},
            {path: 'deployments', component: DeploymentListComponent, data: {title: 'Deployment Manager | Sense Stream'}},
            {path: 'deployments/:deploymentUri', component: DeploymentFormComponent, data: {title: 'Deployment Manager | Sense Stream'}},
            {path: 'accounts', component: UserListComponent, data: {title: 'Account Manager | Sense Stream'}},
            {path: 'accounts/:id', component: UserFormComponent, data: {title: 'Account Manager | Sense Stream'}},
            {path: 'errors', component: ErrorsListComponent, data: {title: 'Error Manager | Sense Stream'}},
            {path: 'notifications', component: NotificationListComponent, data: {title: 'Notification Manager | Sense Stream'}},
            {path: 'notifications/:id', component: NotificationFormComponent, data: {title: 'Notification Manager | Sense Stream'}},
            {
                path: 'boundary-notifications/:id',
                component: NotificationBoundaryFormComponent,
                data: {title: 'Notification Manager | Sense Stream'}
            },
            {
                path: 'boundary-notifications/:id/duplicate',
                component: NotificationBoundaryFormComponent,
                data: {title: 'Notification Manager | Sense Stream'}
            },
            {path: 'projects', component: ProjectsComponent, data: {title: 'Projects | Sense Stream'}},
            {path: 'projects/:id', component: ProjectsFormComponent, data: {title: 'Projects | Sense Stream'}},
            {path: 'middleware', component: MiddlewareComponent, data: {title: 'Middleware | Sense Stream'}},

        ]
    },
    {
        path: 'maintenance',
        component: MaintenanceComponent,
        canActivate: [OnlyLoggedGuardService],
        data: {title: 'Maintenance | Sense Stream'},
        pathMatch: 'full'
    },
    {
        path: 'maintenance/:id',
        component: MaintenanceComponent,
        canActivate: [OnlyLoggedGuardService],
        data: {title: 'Maintenance | Sense Stream'},
        pathMatch: 'full'
    },
    // {path: ':resource', component: RouterHelperComponent, data: {title: 'Sense Stream Data'}},
    // {path: ':resource/:projectId', component: RouterHelperComponent, data: {title: 'Sense Stream Data by ProjectId'}},
    // {path: ':resource/:projectId/:deploymentId', component: RouterHelperComponent, data: {title: 'Sense Stream Deployments'}}
];

@NgModule({
    declarations: [
        AppComponent,
        // RouterHelperComponent,
        NavbarComponent,
        FooterComponent,
        ProjectMapComponent,
        LoginComponent,
        DeploymentsMapComponent,
        MaintenanceComponent,
        DataComponent,
        DeploymentsComponent,
        DeploymentListComponent,
        DeploymentFormComponent,
        DeploymentManagerComponent,
        StatusComponent,
        MotestackListComponent,
        MotestackFormComponent,
        SensingTemplatesComponent,
        TemplateListComponent,
        TemplateFormComponent,
        UserListComponent,
        UserFormComponent,
        ErrorsListComponent,
        NotificationListComponent,
        NotificationFormComponent,
        DiagnosticComponent,
        DataDeploymentsMapComponent,
        ProjectSelectComponent,
        MiddlewareComponent,
        CreateDeploymentMapComponent,
        ProjectsComponent,
        ProjectsFormComponent,
        NotificationBoundaryFormComponent,
        DeploymentNotificationSettingsComponent,
        SensorFormComponent,
        SensorListComponent,
        ErrorModalComponent,
    ],
    imports: [
        BrowserModule,
        RouterModule.forRoot(routes),
        SortablejsModule.forRoot({animation: 150}),
        HttpClientModule,
        FormsModule,
        Daterangepicker,
        NgxJsonViewerModule,
        NgDatepickerModule,
        NguiMapModule.forRoot({
            apiUrl: 'https://maps.google.com/maps/api/js?key=AIzaSyBH3lG3-zHJdTTOzx-J8ft2v1syC9MGCnE'
        }),
        BrowserAnimationsModule,
        // material components:
        MatChipsModule,
        MatFormFieldModule,
        MatButtonModule,
        MatIconModule,
        MatSlideToggleModule,
        MatCheckboxModule,
        MatTooltipModule,
        MatProgressSpinnerModule,
        MatExpansionModule,
        MatTableModule,
        AgmCoreModule.forRoot({apiKey: 'AIzaSyBH3lG3-zHJdTTOzx-J8ft2v1syC9MGCnE'}),
        AgmJsMarkerClustererModule,
        AgmSnazzyInfoWindowModule,
        MatInputModule,
        MatSelectModule,
        DragDropModule,
        MatSortModule,
        MatPaginatorModule,
        MatCardModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatMomentDateModule,
        MatDialogModule,
        
    ],
    providers: [
        AuthService,
        // CsvService,
        IntelligentRiverService,
        OnlyLoggedGuardService,
        SortService
    ],
    entryComponents: [
        ErrorModalComponent
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
