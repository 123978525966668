import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  logoBase: string;
  logos: string[];
  urls: string[];
  logoSense: string;
  showIcons: boolean;

  constructor() {
    this.logoBase = 'assets/img/';
    this.logoSense = this.logoBase + 'footer/sense.png';
    this.logos = [
      this.logoBase + 'footer/fau.png',
      this.logoBase + 'footer/Coastal Carolina.png',
      this.logoBase + 'footer/clemson.png',
      this.logoBase + 'footer/sba.png',
      this.logoBase + 'footer/telit.png',
      this.logoBase + 'footer/Wells Fargo.png',
      this.logoBase + 'footer/nsf.png',
      this.logoBase + 'footer/epa.png',
      this.logoBase + 'footer/aiken.png'
    ];
    this.urls = [
      'http://www.fau.edu',
      'https://www.coastal.edu/',
      'http://www.clemson.edu',
      'http://en.sbasite.com',
      'https://www.telit.com',
      'https://www.wellsfargo.com/',
      'https://www.nsf.gov',
      'https://www.epa.gov',
      'http://www.pepaiken.org/summer-institute.html',
    ];

    this.onResize(null);
  }

  onResize(event) {
    let w = window.innerWidth
      || document.documentElement.clientWidth
      || document.body.clientWidth;
    this.showIcons =  w > 1125;
  }

  ngOnInit() {
  }

}
