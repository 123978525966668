
import {throwError as observableThrowError,  Observable ,  BehaviorSubject } from 'rxjs';

import {catchError, map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse, HttpErrorResponse } from '@angular/common/http';




import * as moment from 'moment/moment.js';

import { Project, ProjectsJSON } from '../models/projects.model';
import { Statistics, StatisticsJSON } from '../models/statistics.model';
import { Deployment, DeploymentsByProjectIdJSON, DeploymentsAllJSON } from '../models/deployments.model';
import { User, UserAccount, UserJSON } from '../models/user.model';
import swal from 'sweetalert2';
import { Router } from '@angular/router';
// import Any = jasmine.Any;

import {environment} from '../../environments/environment';

@Injectable()
export class AuthService {
  private headers: HttpHeaders = new HttpHeaders({'Content-Type': 'application/json'});
  private currentUser: User;
  private loggedIn: boolean;
  observeLoggedIn: BehaviorSubject<boolean>;
  observeCurrentUser: BehaviorSubject<User>;
  redirectToAfterLogIn: string;
  // public authServer = 'https://api.sensestream.org';
  public authServer = 'https://newapi.sensestream.org';
  constructor(private http: HttpClient, private router: Router) {
    if (!this.lsTest()) {
      swal("Warning!", "Enable store cookie for access to site", "error");
      return;
    }
    this.loggedIn = this.currentUser ? true : false;
    this.observeLoggedIn = new BehaviorSubject<boolean>(this.currentUser ? true : false);
    this.observeCurrentUser = new BehaviorSubject<User>(this.currentUser ? this.currentUser : null);
    // // console.log('Auth Service init');
    this.retrieveUserFromStorage();
    // // console.log('Auth Service: current user from storage', this.currentUser);
  }

  setRedirectToAfterLogIn(val: string) {
    this.redirectToAfterLogIn = val;
  }

  getCurrentUser(): any {
    return this.currentUser;
  }

  isLoggedIn(): any {
    return this.loggedIn;
  }

  postSignIn(login: string, password: string): Observable<UserJSON> {
    // console.log('Auth Service: postSignIn(' + login + ', ' + passwordSHA1 + ') new HTTP request');
    const body = {
      email: login,
      password: password
    };
    return this.http.post(environment.apiUrl + '/accounts/signIn', body, {
      headers: this.headers
    }).pipe(map(
      ( response: UserJSON) => {
        console.log('Auth Service: postSignIn() response', response);
        if (response['errorMessage'] === 'success') {
          this.currentUser = response.result;
          if(this.currentUser) {
            this.observeCurrentUser.next(this.currentUser);
            this.loggedIn = true;
            this.observeLoggedIn.next(true);
            this.setUserInStorage();
            if(this.redirectToAfterLogIn) {
              this.router.navigateByUrl(this.redirectToAfterLogIn);
            }
          }
        } else  swal('Error!', `Status: ${response.errorCode}. Description: ${(<any>response).errorMessage}. `, 'error')
        return response;
      }
    ),catchError(
      ( error: HttpErrorResponse ) => {
        return observableThrowError(error);
      }
    ),);
  }
  //
  // postSignOut(login: string, token: string): Observable<UserJSON> {
  //   // console.log('Auth Service: postSignOut() new HTTP Request');
  //   this.currentUser = null;
  //   this.observeCurrentUser.next(null);
  //   this.loggedIn = false;
  //   this.observeLoggedIn.next(false);
  //
  //   this.clearUserInStorage();
  //   const body = {
  //     login: login,
  //     token: token
  //   };
  //   return this.http.post(environment.apiUrl + '/api/signOut', body, {
  //     headers: this.headers
  //   }).pipe(map(
  //     ( response: UserJSON) => {
  //       // console.log('Auth Service: postSignOut() response', response);
  //       if (response['status'] === 'success') {
  //
  //       } else {
  //          swal('Error!', `Status: ${response.errorCode}. Description: ${(<any>response).errorMessage}. `, 'error')
  //       }
  //       return response;
  //     }
  //   ),catchError(
  //     ( error: HttpErrorResponse ) => {
  //       return observableThrowError(error);
  //     }
  //   ),);
  // }

  postSignOut(token: string): Observable<any> {
    console.log('!!Auth Service: postSignOut() new HTTP Request');
    this.currentUser = null;
    this.observeCurrentUser.next(null);
    this.loggedIn = false;
    this.observeLoggedIn.next(false);

    this.clearUserInStorage();
    const body = {
    };
    console.log('headers are ', this.headers);
    const headers = this.headers.append('x-auth-token', token);
    return this.http.post(environment.apiUrl + '/accounts/signOut', body, {
      headers: headers,
    }).pipe(map(
      ( response: any) => {
        console.log('Auth Service: postSignOut() response', response);
        if (response['errorMessage'] === 'success') {

        } else
          swal('Error!', `Status: ${response.errorCode}. Description: ${(<any>response).errorMessage}. `, 'error')
        return response;
      }
    ),catchError(
      ( error: HttpErrorResponse ) => {
        return observableThrowError(error);
      }
    ),);
  }

  /**
   * For now there is no api to sign out
   * @param login
   * @param token
   */
  /*postSignOut(): void {
    // console.log('Auth Service: postSignOut() new HTTP Request');
    this.currentUser = null;
    this.observeCurrentUser.next(null);
    this.loggedIn = false;
    this.observeLoggedIn.next(false);

    this.clearUserInStorage();
  }*/

  setUserInStorage(): void {
    const now: any = moment();
    localStorage.setItem('intelligentriver.org-currentUser', JSON.stringify(this.currentUser));
    localStorage.setItem('intelligentriver.org-lastLogin', JSON.stringify(now.toISOString()));
  }

  retrieveUserFromStorage(): void {
    const now = moment();
    // console.log('Auth Service: now', now.toISOString());
    if (localStorage.getItem('intelligentriver.org-lastLogin') === null) {
      this.currentUser = null;
      this.loggedIn = false;
    } else {
      let then: any = JSON.parse(localStorage.getItem('intelligentriver.org-lastLogin'));
      // console.log('Auth Service: lastLogin', then);
      then = moment(then);

      if (now.diff(then, 'days') < 7) {
        // console.log('Auth Service: last update less than 7 days, using local storage');
        if (localStorage.getItem('intelligentriver.org-currentUser') === null) {
          localStorage.setItem('intelligentriver.org-currentUser', JSON.stringify(this.currentUser));
        } else {
          const data: any = JSON.parse(localStorage.getItem('intelligentriver.org-currentUser'));
          // console.log('retrieve user', data);
          this.currentUser = data === 'undefined' ? null : data;
          if (this.currentUser) {
            this.observeCurrentUser.next(this.currentUser);
          } else {
            this.observeCurrentUser.next(null);
          }
          if (this.currentUser) {
            this.loggedIn = true;
            this.observeLoggedIn.next(true);
          } else {
            this.loggedIn = false;
            this.observeLoggedIn.next(false);
          }
        }
      } else {
        // console.log('Auth Service: last update greater than 7 days, clearing local storage');
        this.clearUserInStorage();
      }
    }
  }

  clearUserInStorage(): void {
    localStorage.removeItem('intelligentriver.org-currentUser');
    localStorage.removeItem('intelligentriver.org-lastLogin');
    localStorage.removeItem('intelligentriver.org-last-update');
  }

    // check local storage available
    lsTest() {
      var test = 'test';
      try {
          localStorage.setItem(test, test);
          localStorage.removeItem(test);
          return true;
      } catch(e) {
          return false;
      }
  }
}
