import {timer as observableTimer, Observable} from 'rxjs';
import {Component, OnInit} from '@angular/core';
import {IntelligentRiverService} from '../../../services/intelligent-river.service';
import {Project} from '../../../models/projects.model';
import {User} from '../../../models/user.model';
import {AuthService} from '../../../services/auth.service';
import {Maintenance} from '../../../models/maintenance.model';
import * as moment from 'moment';
import swal from 'sweetalert2';
import {Title} from '@angular/platform-browser';
import {Router, ActivatedRoute} from '@angular/router';

@Component({
    selector: 'app-maintenance',
    templateUrl: './maintenance.component.html',
    styleUrls: ['./maintenance.component.scss']
})
export class MaintenanceComponent implements OnInit {
    currentProject: Project;
    currentUser: User;
    maintenances: Maintenance[];
    filteredMaintenance: Maintenance[] = [];
    chosenMaintenance: Maintenance;
    newMaintenance: Maintenance = {};
    deployments: any[];
    eventTypes: any[];
    isLoading: boolean = false;
    dateTimePickerValue = new Date;
    calendarModalOpns: any = {
        parentEl: '#addRowModal',
        locale: {format: 'YYYY-MM-DD'},
        alwaysShowCalendars: false,
        opens: 'center',
        singleDatePicker: true,
        timePicker: true
    };

    constructor(private irService: IntelligentRiverService, private router: Router, private authService: AuthService, private titleService: Title, private route: ActivatedRoute) {
    }

    ngOnInit() {
        this.titleService.setTitle('Maintenance | Sense Stream');
        this.irService.setCurrentResource('maintenance');
        this.authService.observeCurrentUser.subscribe((next) => {
            if (next) {
                this.currentUser = next;
                /**
                 * INF: compiling error with new API
                 */
                // this.newMaintenance.user = next.username;
            }
        });
        this.authService.observeLoggedIn.subscribe((next) => {
            if (!next) {
                this.router.navigate(['/']);
            }
        });
        this.irService.observeCurrentProject.subscribe((next) => {
            if (!this.currentProject || (next && this.currentProject.projectId !== next.projectId)) {
                this.maintenances = [];
                this.filteredMaintenance = [];
                this.currentProject = next;
                if (next) {
                    this.newMaintenance.projectId = next.projectId;
                    this.getTableData();
                    this.getMaintenanceFormData();
                }
            }
        });
        (<any>$('#addRowModalContent')).resizable({});
        (<any>$('#addRowModalContent')).draggable({
            handle: '.modal-header'
        });
    }

    getTableData() {
        this.irService.getMaintenanceByProjectId(this.currentProject.projectId).subscribe(
            (data) => {
                this.maintenances = data;
                this.maintenances.forEach(item => {
                    item.datetimeString = moment(item.datetime).format('DD MMM YYYY hh:mm a');
                    if (item.comment && item.comment.length === 1) {
                        item.comment = item.comment.replace(' ', '');
                    }
                });
                Object.assign(this.filteredMaintenance, this.maintenances);
            }
        );
    }

    getMaintenanceFormData() {
        this.irService.getMaintenanceFormData(this.currentProject.projectId).subscribe((data) => {
                this.deployments = data.deployments;
                this.eventTypes = data.eventTypes;
                // Observable.timer(10).subscribe(r=> {
                // $('.datarangepicker').css("opacity", 0);
                // });
            }
        );
    }

    filterItem(text: string) {
        this.filteredMaintenance = this.maintenances.filter(i => i.deployment.toLowerCase().includes(text.toLowerCase()) ||
            i.user.toLowerCase().includes(text.toLowerCase()) || i.eventType.toLowerCase().includes(text.toLowerCase()) ||
            i.comment.toLowerCase().includes(text.toLowerCase()));
    }

    maintenanceClick(meintenance: Maintenance) {
        this.chosenMaintenance = meintenance;
        observableTimer(10).subscribe(r => {
            (<any>$('#rowDetailModalContent')).resizable({});
            (<any>$('#rowDetailModalContent')).draggable({
                handle: '.modal-header'
            });
        });
    }

    deleteRow() {
        this.isLoading = true;
        this.irService.deleteMaintenance(
            this.currentUser.token,
            this.chosenMaintenance.id
        ).subscribe(data => {
            if (data['status'] === 'success') {
                // this.getTableData();
                this.maintenances = this.maintenances.filter(i => i.id !== this.chosenMaintenance.id);
                this.filteredMaintenance = this.filteredMaintenance.filter(i => i.id !== this.chosenMaintenance.id);
                swal('Success!', `Deleted.`, 'success');
            } else {
                swal('Error!', `Error`, 'error');
            }
            $('#rowDetailClose').click();
            this.isLoading = false;
        });
    }

    onSubmit() {
        this.isLoading = true;
        this.irService.postMaintenanceData(
            this.newMaintenance,
            this.currentUser.token
        ).subscribe(data => {
            this.isLoading = false;
            if (data['status'] === 'success') {
                this.getTableData();
                // this.maintenances.push(this.newMaintenance);
                // this.filteredMaintenance.push(this.newMaintenance);
                swal('Success!', `Added new Maintenance.`, 'success');
            } else if (data['status'] === 'failure') {
                swal('Error!', `Error`, 'error');
            }
            $('#addRowClose').click();
        });
    }

    dateTimeSelected(value) {
        this.newMaintenance.datetime = value.start.valueOf();
    }
}
