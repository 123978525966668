import { Component, OnInit } from '@angular/core';
import { Notification, NotificationForm } from '../../.././../../models/notification.model';
import { IntelligentRiverService } from '../../../../../services/intelligent-river.service';
import {ActivatedRoute, Router } from '@angular/router';
import { User } from '../../../../..//models/user.model';
import { Deployment } from '../../../../..//models/deployments.model';
import { AuthService } from '../../../../../services/auth.service';
import { Observable ,  timer } from 'rxjs';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-notification-form',
  templateUrl: './notification-form.component.html',
  styleUrls: ['./notification-form.component.scss']
})
export class NotificationFormComponent implements OnInit {
  notificationId: string;
  notification: Notification;
  notificationForm: NotificationForm;
  private currentUser: User;

  isSubmiting: boolean;
  isDeleteSubmiting: boolean;

  selectedDeploymentId: string;
  selectedEmail: string;
  deploymentLabels: string[];

  availableToAddUsers: User[];
  availblaeToAddDeployments: Deployment[];

  isTrySubmit: boolean;
  submitError: boolean;

  constructor(private irService: IntelligentRiverService,
     private route: ActivatedRoute,
     private authService: AuthService,
     private router: Router,
     private titleService: Title) { }

  ngOnInit() {
    this.titleService.setTitle(this.route.snapshot.data['title']);
    this.notificationId = this.route.snapshot.params['id'];

      this.authService.observeCurrentUser.subscribe((next) => {
         this.currentUser = next;
         if (next == null) {
           return;
         }
         if (this.notificationId !== 'new') {
            this.irService.getNotifications(this.currentUser.token).subscribe(notifications => {
                this.notification = notifications.find(i => i.id === this.notificationId);
                if (this.notificationForm && !this.deploymentLabels) {
                  this.fillDeploymentsLabel();
                }
              });
          } else {
              this.notification = {};
              this.notification.id = '';
              this.notification.deployments = [];
              this.notification.subscriberEmails = [];
              this.deploymentLabels = [];
          }

        this.irService.getNotificationsForm(this.currentUser.token).subscribe(notificationForm => {
          this.notificationForm = notificationForm;
          if (this.notification && !this.deploymentLabels) {
            this.deploymentLabels = this.notificationForm.deployments.
                  filter(i => this.notification.deployments.includes(i.deploymentId)).map(i => i.name);
          }

        });

      });
  }

  fillDeploymentsLabel() {
   this.deploymentLabels = this.notificationForm.deployments.filter(i => this.notification.deployments.includes(i.deploymentId)).map(i => i.name);
  }

  isPositiveNumber(val: string) {
    return !isNaN(+val) && +val >= 0;
  }

  addEmail() {
    if (this.selectedEmail && !this.notification.subscriberEmails.includes(this.selectedEmail)) {
      this.notification.subscriberEmails.push(this.selectedEmail);
    }
  }

  removeEmail(email: string) {
    this.notification.subscriberEmails = this.notification.subscriberEmails.filter(i => i !== email);
  }

  addDeployment() {
    if (this.selectedDeploymentId) {
      if (!this.notification.deployments.includes(this.selectedDeploymentId)) {
        this.notification.deployments.push(this.selectedDeploymentId);
        this.fillDeploymentsLabel();
      }
    }
  }

  removeDeployment(deploymentLabel: string) {
    const deploymentId = this.notificationForm.deployments.find(i => i.name === deploymentLabel).deploymentId;
    this.notification.deployments = this.notification.deployments.filter(i => i !== deploymentId);
    this.fillDeploymentsLabel();
  }

  onSubmit() {
    if (!this.isTrySubmit) {
      this.isTrySubmit = true;
      const timer1 = timer(500);
      timer1.subscribe(() => this.onSubmit());
      return;
    }
    this.submitError = false;
    const invalidFields = (<any>$('.is-invalid')).length;
    if (invalidFields > 0 || this.notification.deployments.length < 1 || this.notification.subscriberEmails.length < 1) {
        if (invalidFields > 0) {
        const top = (<any>$('.is-invalid'))[0].getBoundingClientRect().x;
        // window.scrollTo(top, 0);
        this.submitError = true;
      }
      return;
  }

    this.isSubmiting = true;
    if (this.notificationId === 'new') {
      this.irService.postNotification(this.notification, this.currentUser.token).subscribe(res => {
        this.isSubmiting = false;
        this.router.navigate(['./deployment-manager/notifications' ]);
      }, err => {
        this.submitError = true;
      });
    } else {
      this.irService.updateNotification(this.notification, this.currentUser.token).subscribe(res => {
        this.isSubmiting = false;
        this.router.navigate(['./deployment-manager/notifications' ]);
      }, err => {
        this.submitError = true;
      });
    }
  }

  deleteNotification() {
    this.isDeleteSubmiting = true;
    this.irService.deleteNotification(this.notificationId, this.currentUser.token).subscribe(res => {
      this.isDeleteSubmiting = false;
       this.router.navigate(['./deployment-manager/notifications' ]);
    });
  }


}
