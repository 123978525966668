import {Component, OnInit, ViewChild} from '@angular/core';
import {Deployment} from '../../../../../models/deployments.model';
import {IntelligentRiverService} from '../../../../../services/intelligent-river.service';
import {Router, Params, ActivatedRoute} from '@angular/router';
import {Title} from '@angular/platform-browser';
import {SortService} from '../../../../../services/sort.service';
import swal from 'sweetalert2';
import {MatSort} from "@angular/material/sort";
import {MatTableDataSource} from "@angular/material/table";

@Component({
    selector: 'app-deployment-list',
    templateUrl: './deployment-list.component.html',
    styleUrls: ['./deployment-list.component.scss']
})
export class DeploymentListComponent implements OnInit {
    deployments: Deployment[];
    filteredDeployments: Deployment[];
    deploymentsTableData: any;
    displayedColumns = ['name', 'deploymentUri'];
    @ViewChild(MatSort, {static: true}) sort: MatSort;

    constructor(private irService: IntelligentRiverService, private router: Router, private titleService: Title, private route: ActivatedRoute, private sortService: SortService) {
    }

    ngOnInit() {
        this.titleService.setTitle(this.route.snapshot.data['title']);
        this.irService.getActiveInactiveDeployments().subscribe(deployments => {
            this.deployments = this.sortService.sortByAlphabet('name', deployments);
            // this.filteredDeployments = Object.assign([], this.deployments);
            this.deploymentsTableData = new MatTableDataSource(deployments);
            this.deploymentsTableData.sort = this.sort;
        });
    }

    applyFilter(event: Event) {
        const filterValue = (event.target as HTMLInputElement).value;
        this.deploymentsTableData.filter = filterValue.trim().toLowerCase();
    }

    filterItem(filter: string): void {
        this.filteredDeployments = this.deployments.filter(i => i.name.toLowerCase().includes(filter.toLowerCase()));
    }

    updateDeployment(deployment: Deployment): void {
        if (deployment == null)
            this.router.navigate(['./deployment-manager/deployments', 'new']);
        else this.router.navigate(['./deployment-manager/deployments', deployment.deploymentUri]);
    }
}
