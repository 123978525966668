import {Component, OnInit} from '@angular/core';
import {IntelligentRiverService} from '../../../../../services/intelligent-river.service';
import {ActivatedRoute, Router} from '@angular/router';
import {User, UserAccount} from '../../../../../models/user.model';
import {AuthService} from '../../../../../services/auth.service';
// import * as sha1 from 'js-sha1';
import {Observable, timer} from 'rxjs';
import {Title} from '@angular/platform-browser';
import swal from "sweetalert2";

@Component({
    selector: 'app-user-form',
    templateUrl: './user-form.component.html',
    styleUrls: ['./user-form.component.scss']
})
export class UserFormComponent implements OnInit {
    userId: string | number;
    user: UserAccount;
    private currentUser: User;
    isSubmiting: boolean = false;
    isDeleteSubmiting: boolean;
    isPasswordChangeSubmiting: boolean;
    private password: string;
    errorMessage: string;
    showSuccess: boolean;
    pureEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    purePassword = /^[a-zA-Z0-9!@#\$%\^\&*\)\(+=._-]{8,}$/;
    isTrySubmit: boolean;
    submitError: boolean;

    constructor(private irService: IntelligentRiverService, private route: ActivatedRoute, private authService: AuthService, private router: Router, private titleService: Title) {
    }

    ngOnInit() {
        this.titleService.setTitle(this.route.snapshot.data['title']);
        this.isSubmiting = false;
        this.userId = this.route.snapshot.params['id'];
        this.authService.observeCurrentUser.subscribe(next => {
            this.currentUser = next;
            console.log('Current user was updated, the current user is', next);
            if (next == null) {
                return;
            }
            if (this.userId != 'new') {
                this.irService.getAccountById(this.userId, this.currentUser.token).subscribe((account) => {
                    console.log('DEBUG: received the user account: ', account);
                    this.user = account;
                });
            } else {
                this.user = {};
            }
        });
    }

    onSubmit() {
        if (!this.isTrySubmit) {
            this.isTrySubmit = true;
            let timer1 = timer(500);
            timer1.subscribe(() => this.onSubmit());
            return;
        }
        this.submitError = false;
        let invalidFields = (<any>$('.is-invalid')).length;
        if (invalidFields > 0) {
            let top = (<any>$('.is-invalid'))[0].getBoundingClientRect().x;
            // window.scrollTo(top, 0);
            this.submitError = true;
            return;
        }
        this.isSubmiting = true;
        if (this.userId === 'new') {
            this.irService.postAccount(this.user, this.password, this.currentUser.token).subscribe(res => {
                this.isSubmiting = false;
                console.log('DEBUG: created account api response: ', res);
                if (res.errorMessage === 'failure') {
                    this.showErrMsg(res.description);
                } else {
                    this.router.navigate(['./deployment-manager/accounts']);
                }
            }, err => {
                this.submitError = true;
            });
        } else {
            this.irService.updateAccount(this.user, this.password, this.currentUser.token).subscribe(res => {
                this.isSubmiting = false;
                if (res.errorMessage === 'success') {
                    this.router.navigate(['./deployment-manager/accounts']);
                } else {
                    this.showErrMsg(res.errorMessage);
                    this.submitError = true;
                }
            }, err => {
                this.submitError = true;
            });
        }
    }

    showErrMsg(msg) {
        this.errorMessage = msg;
        let timer1 = timer(5000);
        timer1.subscribe(r => {
            this.errorMessage = null;
        });
    }

    deleteUser() {
        let prompt = 'This action cannot be reverted. Are you sure you want to delete this account?';
        if (this.currentUser.account.accountId == this.userId) {
            prompt = 'You are about to delete your account. This action cannot be reverted. Once the account is deleted your session will end.';
        }
        swal({
            title: 'Confirm Account Deletion',
            text: prompt,
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#023f7d',
            cancelButtonColor: '#6c757d',
            confirmButtonText: 'Yes'
        }).then((res) => {
            if (res.value) {
                this.isDeleteSubmiting = true;
                this.irService.deleteAccount(this.user.accountId, this.currentUser.token).subscribe(response => {
                    this.isDeleteSubmiting = false;
                    if (response.errorMessage === 'success') {
                        swal('Success!', `Description: the account was successfully deleted.`, 'success');
                        // If the account in which the user is currently signed in deleted then sign the user out
                        if (this.currentUser.account.accountId == this.userId) {
                            this.authService.postSignOut(this.currentUser.token).subscribe((results) => {
                                if ('errorMessage' in results) {
                                    if (results['errorMessage'] === 'success') {
                                        this.router.navigate(['']);
                                    } else {
                                        console.log('not logged out, something went wrong');
                                    }
                                } else {
                                    console.log('something DREADFULLY wrong has occured, please consult weeping dev in corner');
                                }
                            });
                        } else {
                            this.router.navigate(['./deployment-manager/accounts']);
                        }
                    } else {
                        swal('Error!', `Description: something went wrong. Please try again`, 'error');
                    }
                }, error => {
                    console.log('ERROR: account form: delete error: ', error);
                    swal('Error!', `Description: something went wrong. Please try again`, 'error');
                });
            }
        });
    }

    emailValidation() {
        return this.pureEmail.test(this.user.email);
    }

    passwordValidation() {
        const valid = this.purePassword.test(this.password);
        console.log('DEBUG1: password valid: ', valid);
        return valid;
    }
}
