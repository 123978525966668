import {Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
import {
  MotestackSensingFull, Motestack,
  MotestackForm, TemplateMotestackForm,
  PostSensingDeviceParameter
} from '../../../../../../models/motestack.model';
import {DeviceFormUnit, Device, Sensor} from '../../../../../../models/device.model';
import {Template, TemplateManagerList} from '../../../../../../models/template.model';
import {IntelligentRiverService} from '../../../../../../services/intelligent-river.service';
import cloneDeep from 'lodash/cloneDeep';


declare var require: any;
const FormulaParser = require('hot-formula-parser').Parser;


@Component({
  selector: 'app-sensing-templates',
  templateUrl: './sensing-templates.component.html',
  styleUrls: ['./sensing-templates.component.scss']
})
export class SensingTemplatesComponent implements OnInit {

  @Input() motestack: Motestack;
  @Input() unitOptions: string[];
  @Input() templateOptions: TemplateManagerList[];
  @Input() oneWireOptions: Sensor[];
  @Input() analogOptions: Sensor[];
  @Input() sdi12Options: Sensor[];
  @Input() isTrySubmit: boolean;
  @Output() newUnit: EventEmitter<string> = new EventEmitter<string>();
  selectedNewSensorId: string;
  selectedNewTemplateId: string;
  parser = new FormulaParser().setVariable('x', 1);

  paramToAddIndex: number;

  constructor(private irService: IntelligentRiverService,) {
  }

  ngOnInit() {
    if (this.motestack.sensors) {
      this.motestack.sensors.forEach((sensor: Sensor) => {
        sensor.parameters.forEach((param, index) => {
          if (param.convertFn) {
            // param.convertFnEnd = param.convertFn.substring(5);
          }
        });
      });
    } else {
      this.motestack.sensors = [];
    }

    console.log('DEBUG: sensing templates: unit options are ', this.unitOptions);
  }

  showNewUnitModal(val, param, indx) {
    if (val === 'new') {
      (<any>$('#newUnitModal')).modal('show');
    } else {
      param.convertUnit = val;
    }
  }

  addNewUnit(labelInput: HTMLInputElement) {
    this.newUnit.emit(labelInput.value);
  }

  updateParamToAdd(newValue){
    this.paramToAddIndex = newValue;
  }

  addParam(index) {
    const param = {
      ...this.motestack.sensors[index].paramsAvailableToAdd[this.paramToAddIndex],
      showRemove: false,
      showConvers: false
    };
    this.motestack.sensors[index].parameters.push(param);
    this.paramToAddIndex = null;
  }

  removeParam(sensIndex, paramIndex) {
    const sens = this.motestack.sensors[sensIndex];
    sens.parameters.splice(paramIndex, 1);
  }

  addSensor() {
    console.log('DEBUG: selected sensor id is ', this.selectedNewSensorId);
    this.irService.getSensorEntityInfo(this.selectedNewSensorId).subscribe(sensor => {
      if (sensor.type === 'ANALOG'){
        sensor.parameters.forEach(i => {
          // i.convertUnit = 'Raw ADC Value';
        });
      }
      const newSensor = sensor;
      newSensor.paramsAvailableToAdd = cloneDeep(sensor.parameters);
      newSensor.sensorId = this.selectedNewSensorId;
      console.log('DEBUG: adding a new sensor to a motestack: ', newSensor);
      this.motestack.sensors.push(newSensor);
      this.selectedNewSensorId = undefined;
    });
  }

  addTemplate() {
    console.log('DEBUG: selected template id is ', this.selectedNewTemplateId);
    this.irService.getTemplateEntityById(this.selectedNewTemplateId).subscribe(template => {
      const newTemplate = template;
      this.irService.getSensorEntityInfo(template.sensorId).subscribe(sensor => {
        newTemplate.paramsAvailableToAdd = cloneDeep(sensor.parameters);
        console.log('DEBUG: adding a new template to a motestack: ', newTemplate);
        newTemplate.type = 'SDI12';
        this.motestack.sensors.push(newTemplate);
      });
      this.selectedNewTemplateId = undefined;
    });
  }

  removeTemplate(sensingId) {
    this.motestack.sensors = this.motestack.sensors.filter(i => i.sensorId !== sensingId);
  }

  getAvailableToAddSensors(): any[] {
    const res = [];
    if (this.motestack.sensors.filter(i => i.type === 'ONEWIRE').length < this.motestack.max1Wire) {
      res.push(...this.oneWireOptions);
    }
    if (this.motestack.sensors.filter(i => i.type === 'SDI12').length < this.motestack.maxSdi12) {
      res.push(...this.sdi12Options);
    }
    if (this.motestack.sensors.filter(i => i.type === 'ANALOG').length < this.motestack.numAds) {
      res.push(...this.analogOptions);
    }
    return res;
  }

  getAvailableToAddTemplates(): any[] {
    const res = [];
    if (this.motestack.sensors.filter(i => i.type === 'SDI12').length < this.motestack.maxSdi12) {
      res.push(...this.templateOptions);
    }
    return res;
  }

  // warning alerts:
  isSDI12LimitReached() {
    if (!this.motestack.maxSdi12) {
      return false;
    }
    return this.motestack.sensors.filter(i => i.type === 'SDI12').length >= +this.motestack.maxSdi12;
  }

  isOneWireLimitReached() {
    if (!this.motestack.max1Wire) {
      return false;
    }
    return this.motestack.sensors.filter(i => i.type === 'ONEWIRE').length >= +this.motestack.max1Wire;
  }

  isAnalogCorrect() {
    if (!this.motestack.numAds) {
      return false;
    }
    return this.motestack.sensors.filter(i => i.type === 'ANALOG').length !== +this.motestack.numAds;
  }

  isSdiNotEnough() {
    if (!this.motestack.minSdi12) {
      return false;
    }
    return this.motestack.sensors.filter(i => i.type === 'SDI12').length < +this.motestack.minSdi12;
  }

  isOneWireNotEnought() {
    if (!this.motestack.min1Wire) {
      return false;
    }
    return this.motestack.sensors.filter(i => i.type === 'ONEWIRE').length < +this.motestack.min1Wire;
  }

  isUniquesIds() {
    const sensIds = this.motestack.sensors.map(i => i.sensorIndex);
    return sensIds.some((i, indx) => {
      return sensIds.indexOf(i) !== indx;
    });
  }

  isValidId(val: string) {
    return !isNaN(+val) || !val;
  }
}
