import { Injectable } from '@angular/core';
import {CanActivate} from "@angular/router";
import { AuthService } from '../services/auth.service';
import { Router,ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';


@Injectable()
export class OnlyLoggedGuardService implements CanActivate { 

  constructor(private authService: AuthService, private router : Router ) { }

canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this.authService.isLoggedIn()) { 
      return true;
    } else {
          this.authService.setRedirectToAfterLogIn(state.url);
         (<any>$('#login-modal')).modal('show');
      return false;
    }
  }
}
