import {timer as observableTimer, Observable} from 'rxjs';
import {Component, OnInit} from '@angular/core';
import {IntelligentRiverService} from '../../../../../services/intelligent-river.service';
import {ActivatedRoute, Router} from '@angular/router';
import {User} from '../../../../../models/user.model';
import {AuthService} from '../../../../../services/auth.service';
import {MotestackForm, Motestack, IHash} from '../../../../../models/motestack.model';
import {DeviceFormUnit, Sensor} from '../../../../../models/device.model';
import {Title} from '@angular/platform-browser';
import {Template, TemplateManagerList} from '../../../../../models/template.model';
import cloneDeep from 'lodash/cloneDeep';
import swal from 'sweetalert2';

@Component({
    selector: 'app-motestack-form',
    templateUrl: './motestack-form.component.html',
    styleUrls: ['./motestack-form.component.scss']
})
export class MotestackFormComponent implements OnInit {
    motestackId: string;
    motestack: Motestack;
    // motestackForm: MotestackForm;
    allSensors: Sensor[];
    templateOptions: TemplateManagerList[];
    oneWireOptions: Sensor[];
    analogOptions: Sensor[];
    sdi12Options: Sensor[];
    unitOptions: string[];
    usiSufix: string;
    isSubmiting: boolean;
    isTrySubmit: boolean;
    private currentUser: User;
    isDuplicate: boolean;
    submitError: boolean;



    showLoadingOnly: boolean = false; // show loading but do not hide page content

    constructor(
        private irService: IntelligentRiverService,
        private router: Router,
        private route: ActivatedRoute,
        private authService: AuthService,
        private titleService: Title) {
    }

    ngOnInit() {
        this.titleService.setTitle(this.route.snapshot.data['title']);
        this.motestackId = this.route.snapshot.params['id'];
        // Get all sensor devices for the options
        this.irService.getAllSensors().subscribe(sensors => {
            if (this.motestackId !== 'new') {
                // Get motestack details
                this.irService.getMotestackById(this.motestackId).subscribe(motestack => {
                    this.motestack = motestack;
                    this.motestack.sensors.forEach((sensor) => {
                        console.log('DEBUG: motestack-form: loading all sensors for this motestack, current sensor: ', sensor);
                        this.irService.getSensorEntityInfo(sensor.sensorId).subscribe(sensorDetails => {
                            sensor.paramsAvailableToAdd = cloneDeep(sensorDetails.parameters);
                        });
                        let foundSensor = sensors.find(i => i.sensorId == sensor.sensorId);
                        if (foundSensor) {
                            sensor.type = foundSensor.type;
                        }
                    });
                    if (this.router.url.includes('duplicate')) {
                        this.motestack.id = null;
                        this.motestackId = 'new';
                        this.motestack.name = '';
                        this.isDuplicate = true;
                    }
                });
            } else {
                this.motestack = {};
                this.motestack.sensors = [];
                this.motestack.name = '';
            }
            this.allSensors = sensors;
            this.oneWireOptions = sensors.filter(i => i.type === 'ONEWIRE');
            this.analogOptions = sensors.filter(i => i.type === 'ANALOG');
            this.sdi12Options = sensors.filter(i => i.type === 'SDI12');
        });
        this.irService.getTemplates().subscribe(templates => {
          this.templateOptions = templates;
        });
        this.irService.getSensorsUnits().subscribe(units => {
            console.log('DEBUG: got units : ', units);
            this.unitOptions = units;
        });
        this.authService.observeCurrentUser.subscribe((next) => {
            this.currentUser = next;
        });
    }

    addNewUnit(unit: string) {
        this.irService.postUnit(unit, this.currentUser.token).subscribe(res => {
            if (res.errorMessage === 'success') {
                this.unitOptions.push(unit);
                (<any>$('#newUnitModal')).modal('hide');
            }
        });
    }

    isNumber(val: any) {
        return !isNaN(parseInt(val, 10)) || !val;
        // return !isNaN(+val) || !val;
    }

    duplicate() {
        console.log('motestack id is ', this.motestack.id);
        this.router.navigate(['./deployment-manager/motestacks/' + this.motestack.id + '/duplicate']);
    }

    delete() {
        swal({
            title: 'Confirm Motestack Deletion',
            text: 'This action cannot be reverted. Are you sure you want to delete this motestack?',
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#023f7d',
            cancelButtonColor: '#6c757d',
            confirmButtonText: 'Yes'
        }).then((res) => {
            console.log('SWEET ALERT result is ', res);
            if (res.value) {
                this.irService.deleteMotestack(this.motestackId, this.currentUser.token).subscribe(response => {
                    if (response.errorMessage === 'success'){
                        swal('Success!', `Description: the motestack was successfully deleted.`, 'success');
                        this.router.navigate(['./deployment-manager/motestacks']);
                    }
                    else {
                        swal('Error!', `Description: something went wrong. Please try again`, 'error');
                    }
                }, error => {
                    console.log('ERROR: motestack form: delete error: ', error);
                    swal('Error!', `Description: something went wrong. Please try again`, 'error');
                });
            }
        });
    }

    sumbit() {
        console.log('DEBUG: motestack-form: on submit the current motestack is ', this.motestack);
        if (!this.isTrySubmit) {
            this.isTrySubmit = true;
            const timer = observableTimer(500);
            timer.subscribe(() => this.sumbit());
            return;
        }
        this.submitError = false;
        const invalidFields = (<any>$('.is-invalid')).length;
        if ((<any>$('.alert-danger')).length > 0 || invalidFields > 0) { // some error
            if (invalidFields > 0) {
                const top = (<any>$('.is-invalid'))[0].getBoundingClientRect().x;
                this.submitError = true;
            }
            return;
        }
        this.isSubmiting = true;
        this.motestack.sensors.forEach(sens => {
            sens.parameters.forEach((param, indx) => {
                // if (param.convertFnEnd) {
                //     param.convertFn = `f(x)=${param.convertFnEnd}`;
                // }
            });
        });
        if (this.motestackId !== 'new') {
            this.irService.updateMotestack(this.motestack, this.currentUser.token).subscribe(res => {
                this.isSubmiting = false;
                this.router.navigate(['./deployment-manager/motestacks']);
            }, err => {
                this.isSubmiting = false;
                this.submitError = true;
            });
        } else {
            this.irService.postMotestack(this.motestack, this.currentUser.token).subscribe(res => {
                this.isSubmiting = false;
                this.router.navigate(['./deployment-manager/motestacks']);
            }, err => {
                this.isSubmiting = false;
                this.submitError = true;
            });
        }
    }
}
