import {Component, OnInit, Output, EventEmitter, ViewChild, ElementRef} from '@angular/core';
import {Router, ActivatedRoute, NavigationEnd} from '@angular/router';

import {IntelligentRiverService} from '../../../services/intelligent-river.service';
import {AuthService} from '../../../services/auth.service';
import {BugReportForm} from '../../../models/bugReportForm.model';

import {Project} from '../../../models/projects.model';
import {Statistics} from '../../../models/statistics.model';
import {User} from '../../../models/user.model';
import swal from 'sweetalert2';
import {filter, map} from 'rxjs/operators';
import {Location} from '@angular/common';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
  @ViewChild('closeApplyBtn', {static: true}) closeApplyBtn: ElementRef;
  @ViewChild('closeReportBugBtn', {static: true}) closeReportBugBtn: ElementRef;

  visible: boolean = true;
  currentProject: Project;
  currentUser: User;
  loggedIn: boolean = false;
  loggedOutSuccess: boolean;
  loggedOutMessage: string;
  attemptingToSignOut: boolean;

  // projects: Project[];
  statistics: Statistics;
  routeData: any;

  applySendText = 'Send';
  applyErrorMessage = '';
  applySuccessMessage = '';
  applyLoading: boolean;
  applyName: string;
  applyEmail: string;
  applyDetails: string;
  trySendApply: boolean;

  // bugReportFields
  bugReportForm: BugReportForm;
  trySendBugReport = false;
  bugReportLoading = false;

  constructor(private irService: IntelligentRiverService,
              private authService: AuthService,
              private activatedRoute: ActivatedRoute,
              private location: Location,
              private router: Router) {
    this.currentProject = null, this.statistics = null;
    this.loggedOutSuccess = false;
    this.loggedOutMessage = null;
    this.attemptingToSignOut = false;
    this.bugReportForm = {};
    this.loggedIn = false;
  }

  ngOnInit() {
    this.router
      .events.pipe(
      filter(event => event instanceof NavigationEnd),
      map(() => {
        const child = this.activatedRoute.firstChild;
        if ('showNavbar' in child.snapshot.data) {
          return child.snapshot.data['showNavbar'];
        }
        return true;
      })
    ).subscribe((showNavbar: boolean) => {
      this.showToolbar(showNavbar);
    });

    this.authService.observeLoggedIn.subscribe((next) => {
      this.loggedIn = next;
      (<any>$('#login-modal')).modal('hide');
    });

    this.authService.observeCurrentUser.subscribe((next) => {
      this.currentUser = next;
    });

    this.irService.observeCurrentProject.subscribe((next) => {
      this.currentProject = next;
    });

    $('.navbar-nav>li.nav-item, .navbar-nav>div>li.nav-item').on('click', function () {
      (<any>$('.navbar-collapse')).collapse('hide');
    });

    $('body').not('.navbar').on('click', function () {
      (<any>$('.navbar-collapse')).collapse('hide');
    });

  }

  showToolbar(event) {
    this.visible = event !== false;
  }


  onClickStatistics(event: Event) {
    // guaranteed fresh on click.
    // console.log('Navbar -> onClickStatistics(event)', event);
    this.irService.getStatistics().subscribe((statistics) => {
      this.statistics = statistics;
    });
  }

  clearCurrentProject(): void {
    this.irService.setCurrentProject(null);
    this.irService.setCurrentProjectId(null);
    this.router.navigate([this.irService.getCurrentResource()]);
  }

  //
  logout(): void {
    this.attemptingToSignOut = true;
    this.authService.postSignOut(this.currentUser.token).subscribe((results) => {
      if ('errorMessage' in results) {
        this.attemptingToSignOut = false;
        if (results['errorMessage'] === 'success') {
          this.loggedOutMessage = 'successfully logged out';
        } else {
          this.loggedOutMessage = 'not logged out, something went wrong';
        }
      } else {
        this.attemptingToSignOut = false;
        this.loggedOutMessage = 'something DREADFULLY wrong has occured, please consult weeping dev in corner';
      }
      setTimeout(() => {
        this.loggedOutMessage = null;
      }, 5000);
    });
  }

  etPhoneHome(): void {
    // console.log('NAVBAR -> etPhoneHome()');
    this.irService.setCurrentProject(null);
    this.irService.setCurrentProjectId(null);
    this.onProjectSelectClick();
  }

  onProjectSelectClick(): void {
    let currentResource = this.location.path().split('/')[1];
    // @ts-ignore
    if (!isNaN(currentResource)) {
      this.router.navigate(['/' + currentResource]);
    } else {
      this.router.navigate(['/measurements']);
    }
  }

  applyModalCalled() {
    this.applyName = '';
    this.applyEmail = '';
    this.applyDetails = '';
    this.applyErrorMessage = '';
    this.applySuccessMessage = '';
    this.applySendText = 'Send';
  }

  reportBugModalCalled() {
    this.bugReportForm = {};
  }

  validateEmail(email) {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  sendApply(name, email, description) {
    if (this.applySendText === 'Done') {
      this.closeApplyBtn.nativeElement.click();
      return;
    }
    this.trySendApply = true;
    if (!name || !this.validateEmail(email) || !description) {
      return;
    }
    this.applyLoading = true;
    this.applyErrorMessage = '';
    this.applySuccessMessage = '';
    this.applySendText = 'Loading...';
    this.irService.postApplyForm(name, email, description).subscribe(res => {
      this.applySuccessMessage = 'The form was sent successfully';
      this.applyLoading = false;
      this.trySendApply = false;
      this.applySendText = 'Done';
    }, (err) => {
      this.applyErrorMessage = 'Something went wrong please try again';
      this.applyLoading = false;
      this.trySendApply = false;
      this.applySendText = 'Send';
    });
  }

  sendReportBug() {
    this.bugReportLoading = true;
    this.trySendBugReport = true;
    if (!this.bugReportForm.name || !this.bugReportForm.email || !this.bugReportForm.bugDescription ||
      !this.validateEmail(this.bugReportForm.email)) {
      return;
    }
    this.bugReportForm.browserDetails = navigator.appVersion;
    this.irService.bugReportPost(this.bugReportForm).subscribe(res => {
      this.trySendBugReport = false;
      this.bugReportLoading = false;
      this.closeReportBugBtn.nativeElement.click();
      swal('Success',
        'Thank you. Our engineers will get back to you as soon as possible. We truly' +
        ' appreciate your input to the development of the SenseStream Portal.', 'success');
    });
  }

  onApplyFullScreenClick() {
    const currentWidth = $('#apply-modal-dialog').width();
    if (currentWidth >= $('#apply-modal-dialog').width('100%').width()) {
      $('#apply-modal-dialog').width('60%').height('55%').css('margin', '20%').css('margin-top', '10%');
    } else {
      $('#apply-modal-dialog').width('100%').height('100%').css('margin', '0').css('padding', '0');
    }
    $('#apply-modal-content').css('height', '100%').css('width', '100%');
  }

  getOSName() {
    let OSName = 'Unknown OS';
    if (navigator.appVersion.indexOf('Win') !== -1) {
      OSName = 'Windows';
    }
    if (navigator.appVersion.indexOf('Mac') !== -1) {
      OSName = 'MacOS';
    }
    if (navigator.appVersion.indexOf('X11') !== -1) {
      OSName = 'UNIX';
    }
    if (navigator.appVersion.indexOf('Linux') !== -1) {
      OSName = 'Linux';
    }

    return OSName;
  }


}
