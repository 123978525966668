import { Component, OnInit } from '@angular/core';
import { IntelligentRiverService } from '../../../../../services/intelligent-river.service';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { AuthService } from '../../../../../services/auth.service';
import { Title } from '@angular/platform-browser';
import { ManagerProject } from '../../../../../models/managerProject.model';
import { Project } from '../../../../../models/projects.model';
import swal from "sweetalert2";

@Component({
  selector: 'app-projects',
  templateUrl: './projects.component.html',
  styleUrls: ['./projects.component.scss']
})
export class ProjectsComponent implements OnInit {
  projects: ManagerProject[];
  filteredProjects: ManagerProject[];

  constructor(private irService: IntelligentRiverService,
     private router: Router,
     private authService: AuthService,
     private titleService: Title,
     private route: ActivatedRoute) { }

  ngOnInit() {
    this.titleService.setTitle(this.route.snapshot.data['title']);
    this.irService.getManagerProjects().subscribe(res => {
      this.projects = res;
      this.filteredProjects = Object.assign([], this.projects);
    });
  }

  filterItem(filter: string): void {
    this.filteredProjects =  this.projects.filter(i => i.name.toLowerCase().includes(filter.toLowerCase()));
  }

  updateProject(project: Project) {
    if (project == null) {
      this.router.navigate(['./deployment-manager/projects', 'new' ]);
    } else {
    this.router.navigate(['./deployment-manager/projects', project.projectId ]);
    }
  }

}
