import {Component, Input, OnInit} from '@angular/core';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {MatChipInputEvent} from '@angular/material/chips';
import {DeploymentNotification} from '../../../models/notification.model';

@Component({
    selector: 'app-deployment-notification-settings',
    templateUrl: './deployment-notification-settings.component.html',
    styleUrls: ['./deployment-notification-settings.component.scss']
})
export class DeploymentNotificationSettingsComponent implements OnInit {
    @Input() notification: DeploymentNotification;
    @Input() isTrySubmit: boolean;

    showInvalidEmailMessage = false;
    selectable = false;
    removable = true;
    addOnBlur = true;
    readonly separatorKeysCodes: number[] = [ENTER, COMMA];

    email_validator = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    constructor() { }

    ngOnInit() {
    }

    updateTimeout(newValue) {
        console.log('DEBUG: timeout was updated, new value is ', newValue);
        this.notification.deathTimeout = newValue;
    }

    add(event: MatChipInputEvent): void {
        const input = event.input;
        const value = event.value;

        // Add the new email if it is valid
        if ((value || '').trim()) {
            const email = String(value).trim().toLowerCase();
            if (this.email_validator.test(email)) {
                this.notification.subscribers.push(email);
                this.showInvalidEmailMessage = false;
            } else {
                this.showInvalidEmailMessage = true;
            }
        }

        // Reset the input value
        if (input) {
            input.value = '';
        }
    }

    isInteger(val) {
        return Number.isInteger(Number(val));
    }

    remove(email: string): void {
        const index = this.notification.subscribers.indexOf(email);

        if (index >= 0) {
            this.notification.subscribers.splice(index, 1);
        }
    }

}
