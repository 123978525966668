import {timer as observableTimer, Observable, Subscription} from 'rxjs';
import {Component, OnInit, ViewChild, ElementRef, HostListener, AfterViewInit, OnDestroy} from '@angular/core';
import {IntelligentRiverService} from '../../../services/intelligent-river.service';
import {DeviceDiagnostic, Observation} from '../../../models/diagnostic.model';
import * as moment from 'moment';
import swal from 'sweetalert2';
import {Title} from '@angular/platform-browser';
import {DaterangePickerComponent} from 'ng2-daterangepicker';
import {error} from 'util';
import {Router} from '@angular/router';
import {interval} from 'rxjs';

declare var google: any;
declare var googleLoaded: any;
declare var Plotly: any;

export interface DeploymentDiagnosticMessagesJSON {
    errorCode: number;
    errorMessage: string;
    result: Array<DeploymentDiagnosticMessage>;
}

export interface DeploymentDiagnosticMessage {
    baseDateTime: string;
    sequenceNumber?: number;
    observationId: string;
    offsetTime: number;
    appDiagnostics: any;
    logDiagnostics: any;
}

@Component({
    selector: 'app-diagnostic',
    templateUrl: './diagnostic.component.html',
    styleUrls: ['./diagnostic.component.scss']
})
export class DiagnosticComponent implements OnInit, AfterViewInit, OnDestroy {
    @ViewChild('exportModalClose', {static: true}) exportModalClose: ElementRef;
    chart: any;
    dataTable: any;
    options: any;
    @HostListener('window:resize', ['$event'])
    public timelineChartData: any = null;
    diagnosticData: any[] = null;
    diagnosticDataKeys: string[] = [];
    selectedBlock: any = null;
    noDataMsg = false;
    exportLoading: boolean;
    exportDataTry = false;
    receptionTime: string;
    exportStartDate: Date;
    exportEndDate: Date;
    exportStartDateSelected = false;
    startDate: Date;
    endDate: Date;
    windowSize: number;
    deploymentHasEmptyMessages = false;
    calendarOptions: any = {
        autoUpdateInput: true,
        locale: {format: 'YYYY-MM-DD'},
        alwaysShowCalendars: false,
        ranges: {
            'Today': [moment(), moment()],
            'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
            'Last 7 Days': [moment().subtract(6, 'days'), moment()],
            // 'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
        },
        opens: 'center'
    };
    showLoadingScreen = false;
    // plot vars
    diagnosticMessagesLoading = false;
    rowsDeploymentUris = [];
    startDateSelect = false;
    selectedBar: string;
    selectedStationDiagnostics: any;
    diagnosticKeys: string[];
    exportBtnText = 'Export';
    exportURL: string;
    eventFromCode: boolean;
    exportId: string;

    constructor(private irService: IntelligentRiverService,
                private router: Router,
                private titleService: Title) {
    }

    onResize(event) {
        this.chart.draw(this.dataTable, this.options);
    }

    ngOnInit() {
        this.titleService.setTitle('Diagnostics | Sense Stream');
        this.startDate = moment().toDate();
        this.endDate = moment().toDate();
        this.exportStartDate = moment().toDate();
        this.exportEndDate = moment().toDate();
        this.getDiagnosticArchive();
    }

    ngOnDestroy() {
    }

    ngAfterViewInit() {
        $('#allDiagnosticModal').on('hidden.bs.modal', (event) => {
            this.collapseAllPlot();
        });
    }

    getDiagnosticArchive() {
        this.timelineChartData = null;
        this.deploymentHasEmptyMessages = false;
        let startNum;
        let endNum;
        if (this.startDateSelect) {
            startNum = moment(this.startDate.valueOf()).local().unix() * 1000;
            endNum = moment(this.endDate.valueOf()).local().unix() * 1000;
        } else {
            startNum = moment(new Date().setHours(0, 0, 0, 0).valueOf()).local().unix() * 1000;
            endNum = moment(new Date().valueOf()).local().unix() * 1000;
        }
        if (startNum > endNum) {
            swal('Error!', `Start Date more than End Date `, 'error');
            return;
        }
        this.windowSize = this.getBlockSizeSeconds(startNum, endNum);
        this.irService.getDiagnosticsArchiveBlock(startNum, endNum, this.windowSize).subscribe(diagnostic => {
            this.processDiagnostic(diagnostic);
        }, error => {
            swal('Error!', 'Something went wrong. Please try again', 'error');
            this.timelineChartData = [];
            this.noDataMsg = true;
        });
    }

    private getBlockSizeSeconds(startNum: number, endNum: number): number {
        const diffInDays = (endNum - startNum) / (1000 * 60 * 60 * 24);
        if (diffInDays < 3) {
            return 1200; // 20 minutes
        }
        if (diffInDays < 6) {
            return 3600; // 1 hours
        }
        if (diffInDays < 30) {
            return 21600; // 6 hours
        } else {
            return 86400; // 24 hours
        }
    }

    /**
     * INF: NEW API change, no need for processing
     * @param diagnostic
     */
    private processDiagnostic(diagnostic: any) {
        this.diagnosticData = diagnostic;
        this.diagnosticDataKeys = Object.keys(diagnostic);
        this.processCharset();
    }

    selectDate(value: any) {
        this.startDate = value.start.toDate();
        this.endDate = value.end.toDate();
        const timeDiff = Math.abs(this.startDate.getTime() - this.endDate.getTime());
        const diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));
        if (diffDays > 15) {
            swal('Warning!', 'Maximum 14 days difference', 'warning');
            return;
        }
        this.startDateSelect = true;
        this.getDiagnosticArchive();
    }

    selectExportDate(value: any) {
        this.exportStartDate = value.start.toDate();
        this.exportEndDate = value.end.toDate();
        const timeDiff = Math.abs(this.startDate.getTime() - this.endDate.getTime());
        const diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));
        if (diffDays > 90) {
            swal('Warning!', 'Maximum 90 days difference', 'warning');
            return;
        }
        this.exportStartDateSelected = true;
    }

    getBlockByIndx(searchIndx: number): any {
        let indx = 0;
        let result: any = null;
        this.diagnosticData.forEach((device, devIndx) => {
            device.forEach((block, obsIndx) => {
                if (indx === searchIndx) {
                    result = block;
                }
                indx++;
            });
        });
        return JSON.parse(JSON.stringify(result));
    }

    getFormattedDateTime(milisec: number) {
        return moment(milisec).format('MMMM Do YYYY, h:mm:ss a');
    }

    getUnixTimestampsForApi(date) {
        return moment(date.valueOf()).local().unix() * 1000;
    }

    getCurrentTimeWindow() {
        let startNum;
        let endNum;
        if (this.startDateSelect) {
            startNum = moment(this.startDate.valueOf()).local().unix() * 1000;
            endNum = moment(this.endDate.valueOf()).local().unix() * 1000;
        } else {
            startNum = moment(new Date().setHours(0, 0, 0, 0).valueOf()).local().unix() * 1000;
            endNum = moment(new Date().valueOf()).local().unix() * 1000;
        }
        return [startNum, endNum];
    }

    processCharset() {
        this.selectedBlock = null;
        this.dataTable = new google.visualization.DataTable();
        this.dataTable.addColumn({type: 'string', id: 'bar'});
        this.dataTable.addColumn({type: 'string', id: 'dummy  label'});
        this.dataTable.addColumn({type: 'string', role: 'tooltip'});
        this.dataTable.addColumn({type: 'date', id: 'Start'});
        this.dataTable.addColumn({type: 'date', id: 'End'});
        const data: any[] = [];
        const emptyDataKeys: any [] = [];
        const emptyData: any [] = [];
        let item: any[] = [];
        let windowStart = 0;
        let windowEnd = 0;
        let rowsCount = 0;
        this.rowsDeploymentUris = [];
        const devicesKeys = Object.keys(this.diagnosticData);
        console.log('DEBUG: diagnostic data is ', this.diagnosticData);
        devicesKeys.forEach(deviceKey => {
            /**
             * this should be here if empty deployments are to be included.
             */
            // rowsCount++;
            if (this.diagnosticData[deviceKey].length) {
                rowsCount++;
                this.rowsDeploymentUris.push(deviceKey);
                this.diagnosticData[deviceKey].forEach(block => {
                    if (block.windowStart) {
                        windowStart = block.windowStart;
                        windowEnd = windowStart + this.windowSize * 1000;
                        item.push(deviceKey);
                        item.push('');
                        item.push(moment(windowStart).format('MMMM Do YYYY, h:mm') +
                            ' - ' + moment(windowEnd).format('MMMM Do YYYY, h:mm') + ', messages: ' + block.messages);
                        item.push(moment(windowStart).toDate());
                        item.push(moment(windowEnd).toDate());
                        data.push(item);
                        item = [];
                    }
                });
            } else {
                /**
                 * INF: This is done to include deployments with no messages.
                 */
                // INF: first collect all empty keys so that the time range now() doesnt shift the graph
                // emptyDataKeys.push(deviceKey);
            }
        });
        /**
         * INF: if empty deployments are to be included, then this needs to be uncommented
         * this is used so that the rectangles with empty messages are removed from the graph
         */
        /*if (emptyDataKeys.length > 0) {
            this.deploymentHasEmptyMessages = true;
            emptyDataKeys.forEach(deviceKey => {
                item.push(deviceKey);
                item.push('');
                item.push('');
                item.push(moment(windowStart).toDate());
                item.push(moment(windowStart).toDate());
                emptyData.push(item);
                item = [];
                this.rowsDeploymentUris.push(deviceKey);
            });
            data.push(...emptyData);
        }*/
        if (data.length < 1) {
            this.noDataMsg = true;
            this.timelineChartData = {};
            this.hideDateInput();
            return;
        } else {
            this.noDataMsg = false;
        }
        this.timelineChartData = data;
        this.dataTable.addRows(this.timelineChartData);
        const timer = observableTimer(200);
        timer.subscribe(() => {
            const container = document.getElementById('timeline-tooltip');
            // this adds a chart to the div container
            this.chart = new google.visualization.Timeline(container);
            google.visualization.events.addListener(this.chart, 'select', () => {
                this.showLoadingScreen = true;
                let selection: any = this.chart.getSelection()[0];
                this.selectedBlock = null;
                if (!selection.row) {
                    this.showLoadingScreen = false;
                    return;
                }
                selection = selection.row;
                const dUri = this.timelineChartData[selection][0];
                const startMillis = this.getUnixTimestampsForApi(this.timelineChartData[selection][3]);
                const endMillis = this.getUnixTimestampsForApi(this.timelineChartData[selection][4]);
                this.irService.getDiagnosticMessagesForDeployment(dUri, startMillis, endMillis).subscribe((next) => {
                    const timer = observableTimer(100);
                    timer.subscribe(() => {
                        this.selectedBlock = {};
                        this.selectedBlock.title = moment(startMillis).format('MMMM Do YYYY, h:mm') +
                            ' - ' + moment(this.selectedBlock.endMillis).format('MMMM Do YYYY, h:mm') + ', messages: ' + next.length;
                        this.selectedBlock.deploymentUri = dUri;
                        this.selectedBlock.messages = next.map((i, indx) => {
                            // moment(i.baseDateTime).format('MMMM Do YYYY h:mm')
                            return {
                                title: `Message #${indx + 1} [${i.baseDateTime}]`,
                                message: i
                            };
                        });
                        const timer2 = observableTimer(100);
                        // this.showLoadingScreen = false;
                        timer2.subscribe(() => {
                            this.showLoadingScreen = false;
                            (<any>$('#messageModal')).modal('show');
                            (<any>$('#documentContent')).resizable({});
                            (<any>$('#documentContent')).draggable({
                                handle: '.modal-header'
                            });
                            this.chart.setSelection([]);
                            (<any>$('.google-visualization-tooltip')).hide();
                        });
                    });
                }, error => {
                    swal('Error!', 'Something went wrong. Please try again later', 'error');
                });
            });
            // INF: it adds a link to deployment titles
            google.visualization.events.addListener(this.chart, 'ready', () => {
                const barColumns = document.getElementsByTagName('g')[0].getElementsByTagName('text');
                Array.from(barColumns).forEach((element, indx) => {
                    element.setAttribute('id', this.rowsDeploymentUris[indx]);
                    element.style.textDecoration = 'underline';
                    element.style.cursor = 'pointer';
                    element.addEventListener('click', ($event) => {
                        this.selectedBar = (<any>$event.currentTarget).id;
                        this.diagnosticKeys = [];
                        this.selectedStationDiagnostics = {};
                        (<any>$('#allDiagnosticModal')).modal('show');
                        this.diagnosticMessagesLoading = true;
                        const [startMillis, endMillis] = this.getCurrentTimeWindow();
                        this.irService.getDiagnosticMessagesForDeployment(this.selectedBar, startMillis, endMillis)
                            .subscribe(res => {
                                if (res.length < 1) {
                                    (<any>$('#allDiagnosticModal')).modal('hide');
                                    observableTimer(100).subscribe(() => {
                                        swal('Warning!', `No data`, 'warning');
                                    });
                                    return;
                                }
                                const originalMessages = res;
                                // this.selectedStationDiagnostics = {};
                                const keys = Object.keys(originalMessages[0]);
                                // this.diagnosticKeys = [];
                                keys.forEach(key => {
                                    if (typeof originalMessages[0][key] === 'object') {
                                        this.diagnosticKeys.push(key);
                                        this.selectedStationDiagnostics[key] = Object.keys(originalMessages[0][key]);
                                    }
                                });
                                (<any>$('#allDiagnosticContent')).draggable({
                                    handle: '.modal-header'
                                });
                                const layout = {
                                    autosize: true,
                                    margin: {
                                        l: 50,
                                        r: 50,
                                        b: 40,
                                        t: 40,
                                        pad: 4
                                    }
                                };
                                const config = {
                                    displayModeBar: true,
                                    modeBarButtonsToRemove: ['sendDataToCloud', 'hoverCompareCartesian', 'pan2d',
                                        'hoverClosestCartesian', 'hoverCompareCartesian', 'toggleSpikelines'],
                                    displaylogo: false
                                };
                                // stop the loading progress bar
                                this.diagnosticMessagesLoading = false;
                                observableTimer(10).subscribe(() => {
                                    this.diagnosticKeys.forEach((key) => {
                                        this.selectedStationDiagnostics[key].forEach((subKey) => {
                                            const y = originalMessages.map(i => i[key]).map(i => i[subKey]);
                                            const x = originalMessages.map(i => this.dateParcerForSafari(i['baseDateTime']));
                                            const trace = {
                                                mode: 'lines+markers',
                                                name: key + '-' + subKey,
                                                hoverinfo: 'x + y + name',
                                                hoverlabel: {
                                                    namelength: -1 // shows the whole name regardless of length.
                                                },
                                                x: x,
                                                y: y
                                            };
                                            const plotly = Plotly.newPlot('plot' + key + subKey, [trace], layout, config);
                                            Plotly.relayout('plot' + key + subKey, {
                                                'xaxis.autorange': true,
                                                'yaxis.autorange': true
                                            });
                                        });
                                    });
                                });
                            }, error => {
                                (<any>$('#allDiagnosticModal')).modal('hide');
                                swal('Error!', 'Something went wrong. Please try again later', 'error');
                            });
                    });
                });
            });
            const height = rowsCount * 41 + 50;
            this.options = {
                hAxis: {
                    minValue: this.startDate,
                    maxValue: this.endDate
                },
                tooltip: {isHtml: true},
                height: height
            };
            this.chart.draw(this.dataTable, this.options);
            if (this.deploymentHasEmptyMessages) {
                this.removeEmptyRectangles(document.getElementById('timeline-tooltip'));
            }
        });
        this.hideDateInput();
    }

    removeEmptyRectangles(container) {
        let el = container.getElementsByTagName('rect');      // get all the descendant rect element inside the container
        let width = 100000000;                                // set a large initial value to width
        let elToRem = [];                                     // element would be added to this array for removal
        for (let i = 0; i < el.length; i++) {                           // looping over all the rect element of container
            let cwidth = parseInt(el[i].getAttribute('width'), 10); // getting the width of ith element
            if (cwidth < width) {                               // if current element width is less than previous width then this is min. width and ith element should be removed
                elToRem = [el[i]];
                width = cwidth;                               // setting the width with min width
            } else if (cwidth == width) {                         // if current element width is equal to previous width then more that one element would be removed
                elToRem.push(el[i]);
            }
        }
        for (let i = 0; i < elToRem.length; i++) // now iterate JUST the elements to remove
        {
            elToRem[i].setAttribute('fill', 'none');
        } // make invisible all the rect element which has minimum width
    }

    private dateParcerForSafari(date: string) {
        // date format is 11-15-2018 17:08:36
        const datePart = date.split(' ')[0];
        const timePart = date.split(' ')[1];
        const monthPart = datePart.split('-')[0];
        const dayPart = datePart.split('-')[1];
        const yearPart = datePart.split('-')[2];
        const hoursPart = timePart.split(':')[0];
        const minutesPart = timePart.split(':')[1];
        const secondsPart = timePart.split(':')[2];
        return new Date(+yearPart, +monthPart, +dayPart, +hoursPart, +minutesPart, +secondsPart);
    }

    private hideDateInput() {
        const timer = observableTimer(10);
        timer.subscribe(() => {
            $('.datarangepicker').css('opacity', 0);
        });
    }

    private formatDate(date) {
        const monthNames = [
            'Jan', 'Feb', 'Mar',
            'Apr', 'May', 'Jun', 'Jul',
            'Aug', 'Sep', 'Oct',
            'Nov', 'Dec'
        ];
        const day = date.getDate();
        const monthIndex = date.getMonth();
        return day + ' ' + monthNames[monthIndex] + ' ' + date.getHours() + ':' + date.getMinutes();
    }

    onFullScreanClick() {
        const currentWidth = $('#documentDialog').width();
        if (currentWidth >= $('#documentDialog').css('max-width', '100%').width('100%').width()) {
            $('#documentDialog').width('60%').height('55%').css('margin', '20%').css('margin-top', '10%');
        } else {
            $('#documentDialog').width('100%').height('100%').css('margin', '0').css('padding', '0').css('max-width', '100%');
        }
        $('#documentContent').css('height', '100%').css('width', '100%');
    }

    onAllFullScreanClick() {
        if (document.querySelector('.fullscrean-dialog') !== null) {
            document.getElementById('allDiagnosticDialog').classList.remove('fullscrean-dialog');
            document.getElementById('allDiagnosticContent').classList.remove('fulscreen-content');
        } else {
            document.getElementById('allDiagnosticDialog').classList.add('fullscrean-dialog');
            document.getElementById('allDiagnosticContent').classList.add('fulscreen-content');
        }
        this.diagnosticKeys.forEach(key => {
            this.selectedStationDiagnostics[key].forEach(subKey => {
                $('#plot' + key + subKey).width($('#plot-card-body').width());
                Plotly.relayout('plot' + key + subKey, {'xaxis.autorange': true, 'yaxis.autorange': true});
            });
        });
    }

    changeDate(next: boolean) {
        if (next && !this.nextDateAvaiable()) {
            return;
        }
        let diffDays = this.endDate.getDate() - this.startDate.getDate();
        if (diffDays === 0) {
            const timeDiff = Math.abs(this.startDate.getTime() - this.endDate.getTime());
            if (timeDiff === 0) {
                const today = new Date();
                today.setHours(0, 0, 0, 0);
                const todtEnd = new Date();
                todtEnd.setHours(23, 59, 59);
                this.startDate = today;
                this.endDate = todtEnd;
            }
            diffDays = 1;
        }
        if (next) {
            this.startDate.setDate(this.startDate.getDate() + diffDays);
            this.endDate.setDate(this.endDate.getDate() + diffDays);
        } else {
            this.startDate.setDate(this.startDate.getDate() - diffDays);
            this.endDate.setDate(this.endDate.getDate() - diffDays);
        }
        this.startDateSelect = true;
        this.getDiagnosticArchive();
    }

    nextDateAvaiable() {
        return this.endDate.getDate() !== new Date().getDate();
    }

    exportBtnClick() {
        this.exportURL = '';
        this.exportBtnText = 'Export';
    }

    exportData(deviceUri: string) {
        if (!this.receptionTime || !this.receptionTimeValid()) {
            this.exportDataTry = true;
            return;
        }
        if (this.exportBtnText === 'Download') {
            window.open(this.exportURL, '_blank');
            this.exportModalClose.nativeElement.click();
            return;
        }
        this.exportLoading = true;
        this.exportDataTry = false;
        this.exportBtnText = 'Loading...';
        let startNum, endNum;
        if (this.exportStartDateSelected) {
            startNum = moment(this.exportStartDate.valueOf()).local().unix() * 1000;
            endNum = moment(this.exportEndDate.valueOf()).local().unix() * 1000;
        } else {
            startNum = moment(new Date().setHours(0, 0, 0, 0).valueOf()).local().unix() * 1000;
            endNum = moment(new Date().valueOf()).local().unix() * 1000;
        }
        // const device: DeviceDiagnostic = this.diagnosticData.find(i => i.deviceId === deviceId);
        this.irService.prepareDiagnosticReport(deviceUri, startNum, endNum, this.receptionTime).subscribe(res => {
            if (res.errorMessage === 'Success') {
                // this.exportURL = this.irService.getDiagnosticReportURL(device.deviceLabel, device.deviceId, startNum, endNum, this.receptionTime);
                this.exportId = res.result.reportId;
                // this.exportLoading = false;
                // this.exportBtnText = 'Download';
                const tenSecInterval = interval(5000);
                let tenSecondsSubscription = tenSecInterval.subscribe(x => {
                    console.log('x is ', x);
                    this.irService.checkDiagnosticsExportStatus(this.exportId).subscribe(res => {
                        console.log('DATA: got response of status: ', res);
                        if (res.reportStatus === 'success') {
                            this.exportURL = res.downloadUrl;
                            this.exportBtnText = 'Download';
                            this.completeExport(tenSecondsSubscription);
                        } else if (res.reportStatus === 'error') {
                            this.completeExport(tenSecondsSubscription);
                            swal('Error', 'Export failed, please trt again', 'error');
                        } else if (x === 240) {
                            this.completeExport(tenSecondsSubscription);
                            swal('Time out', 'The request timed out, please try again', 'error');
                            console.log('x is ', x, ' the timer got unsubscribed');
                        }
                    });
                });
            } else {
                this.exportLoading = false;
                this.exportBtnText = 'Retry';
            }
        });
    }

    completeExport(sub) {
        sub.unsubscribe();
        this.exportId = null;
        this.exportLoading = false;
    }

    receptionTimeValid() {
        return (this.receptionTime && !isNaN(+this.receptionTime) && +this.receptionTime >= 0 && +this.receptionTime <= 120) ||
            (!this.receptionTime && !this.exportDataTry);
    }

    cardKeysClicked(selectedKey: string) {
        this.diagnosticKeys.forEach(key => {
            if (key !== selectedKey) {
                (<any>$('#' + key)).collapse('hide');
            }
        });
    }

    cardSubKeyClicked(selectedSubKey: string, key: string) {
        this.selectedStationDiagnostics[key].forEach(subKey => {
            if (subKey !== selectedSubKey) {
                (<any>$('#collapse' + key + subKey)).collapse('hide');
            }
        });
        observableTimer(10).subscribe(() => {
            Plotly.relayout('plot' + key + selectedSubKey, {
                'xaxis.autorange': true,
                'yaxis.autorange': true
            });
        });
    }

    collapseAllPlot() {
        this.diagnosticKeys.forEach(key => {
            this.selectedStationDiagnostics[key].forEach(subKey => {
                (<any>$('#collapse' + key + subKey)).collapse('hide');
            });
            (<any>$('#' + key)).collapse('hide');
        });
    }
}
