import {timer as observableTimer, Observable} from 'rxjs';
import {Component, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {Deployment, DeploymentsForm} from '../../.././../../models/deployments.model';
import {IntelligentRiverService} from '../../../../../services/intelligent-river.service';
import {AuthService} from '../../../../../services/auth.service';
import {ActivatedRoute, Router} from '@angular/router';
import {User} from '../../../../../models/user.model';
import {NgClass} from '@angular/common';
import {Title} from '@angular/platform-browser';
import swal from 'sweetalert2';
import {Motestack, MotestackManagerList} from '../../../../../models/motestack.model';
import {DeploymentNotification} from '../../../../../models/notification.model';
import isEqual from 'lodash/isEqual';
import cloneDeep from 'lodash/cloneDeep';
import {Sensor} from "../../../../../models/device.model";

@Component({
    selector: 'app-deployment-form',
    templateUrl: './deployment-form.component.html',
    styleUrls: ['./deployment-form.component.scss']
})
export class DeploymentFormComponent implements OnInit {
    deploymentUri: string;
    // motestackOptions: MotestackManagerList[];
    deployment: Deployment;
    allDeployments: Deployment[];
    private currentUser: User;
    isSubmiting: boolean;
    showFormMap: boolean;
    uriIsUnique = true;
    uriIsValid: boolean = true;
    isTrySubmit: boolean;
    submitError: boolean;
    center: google.maps.LatLng;
    // Sensors stuff
    allSensors: Sensor[];
    oneWireOptions: Sensor[];
    analogOptions: Sensor[];
    sdi12Options: Sensor[];
    selectedNewSensorId: any;

    // notifications preferences
    notificationPreferences: DeploymentNotification;
    originalNotificationPreferences: DeploymentNotification;
    deploymentSubmitSuccess: boolean;
    notificationSubmitSuccess: boolean;
    showLoading = false;

    uriVerifier = /^[A-Za-z0-9_-]*$/;

    // end test
    constructor(private irService: IntelligentRiverService, private route: ActivatedRoute,
                private authService: AuthService, private router: Router,
                private titleService: Title) {
    }

    isNumber(val: any) {
        return !isNaN(+val);
    }

    ngOnInit() {
        this.titleService.setTitle(this.route.snapshot.data['title']);
        this.deploymentUri = this.route.snapshot.params['deploymentUri'];
        // Get all sensor devices for the options
        this.irService.getAllSensors().subscribe(sensors => {
            this.allSensors = sensors;
            this.oneWireOptions = sensors.filter(i => i.type === 'ONEWIRE');
            this.analogOptions = sensors.filter(i => i.type === 'ANALOG');
            this.sdi12Options = sensors.filter(i => i.type === 'SDI12');

            if (this.deploymentUri !== 'new') {
                this.irService.getDeploymentDetails(this.deploymentUri).subscribe(deployment => {
                    this.deployment = deployment;

                    // this.deployment = deployments.find(i=> i.deploymentId == this.deploymentId);
                    this.deployment.sensors.forEach(sensor => {
                       sensor.type = this.allSensors.find(s => s.sensorId == sensor.sensorId).type;
                    });

                    console.log('the deployment is', this.deployment);
                });
            }
        });
        this.irService.getDeployments().subscribe(deployments => {
            this.allDeployments = deployments;
        });
        if (this.deploymentUri === 'new') {
            this.deployment = {
                nvsramLog: false,
                active: true,
                sdi12v3: false,
                sensors: []
            };
            this.deployment.location = {lat: 0, lng: 0};
            this.deployment.name = '';
        }
        this.authService.observeCurrentUser.subscribe((next) => {
            this.currentUser = next;
            if (this.deploymentUri !== 'new') {
                this.irService.getDeploymentNotificationPreferences(this.deploymentUri, this.currentUser.token).subscribe(notification => {
                    if (notification.length > 0) {
                        this.originalNotificationPreferences = cloneDeep(notification[0]);
                        this.notificationPreferences = notification[0];
                    } else {
                        this.originalNotificationPreferences = null;
                        this.notificationPreferences = null;
                    }
                });
            }
        });
    }

    toggleViewNotificationSettings(): void {
        if (!this.notificationPreferences) {
            this.notificationPreferences = {
                subscribers: [],
                deathTimeout: 20
            };
        } else {
            this.notificationPreferences = null;
            // no notification existed so just remove the local changes
        }
    }

    onSubmit() {
        if (!this.isTrySubmit) {
            this.isTrySubmit = true;
            const timer = observableTimer(500);
            timer.subscribe(() => this.onSubmit());
            return;
        }
        this.submitError = false;
        const invalidFields = (<any>$('.is-invalid')).length;
        if (invalidFields > 0) {
            const top = (<any>$('.is-invalid'))[0].getBoundingClientRect().x;
            // window.scrollTo(top, 0);
            this.submitError = true;
            return;
        }
        this.isSubmiting = true;
        if (this.deploymentUri === 'new') {
            this.irService.postDeployment(this.deployment, this.currentUser.token).subscribe(res => {
                this.isSubmiting = false;
                this.router.navigate(['./deployment-manager/deployments']);
            }, err => {
                this.submitError = true;
            });
        } else {
            this.irService.updateDeployment(this.deployment, this.currentUser.token).subscribe(res => {
                if (res.errorMessage === 'success') {
                    this.deploymentSubmitSuccess = true;
                    this.originalNotificationPreferences = this.notificationPreferences;
                    if (this.notificationSubmitSuccess) {
                        this.handleSubmitSuccess();
                    }
                }
            }, err => {
                this.submitError = true;
            });
            // notification existed but was deleted
            if (this.originalNotificationPreferences && !this.notificationPreferences){
                this.irService.deleteDeploymentNotificationPreferences(this.originalNotificationPreferences.notificationId,
                    this.currentUser.token).subscribe(res => {
                    if (res.errorMessage === 'success') {
                        this.notificationPreferences = null;
                        this.originalNotificationPreferences = null;
                        this.notificationSubmitSuccess = true;
                        if (this.deploymentSubmitSuccess) {
                            this.handleSubmitSuccess();
                        }
                    }
                });
            }
            // the notification existed and was modified
            else if (this.originalNotificationPreferences && !isEqual(this.originalNotificationPreferences, this.notificationPreferences)) {
                console.log('updated notification settings are', this.notificationPreferences);
                this.irService.updateDeploymentNotificationPreferences(this.originalNotificationPreferences.notificationId, this.deploymentUri,
                    this.notificationPreferences.subscribers, this.notificationPreferences.deathTimeout, this.currentUser.token).subscribe(res => {
                    if (res.errorMessage === 'success') {
                        this.notificationSubmitSuccess = true;
                        // {"errorCode":0,"errorMessage":"success","result":{"notificationId":"7"}}
                        this.saveNotificationSettings(res.result.notificationId);
                        if (this.deploymentSubmitSuccess) {
                            this.handleSubmitSuccess();
                        }
                    }
                });
            }
            // there was no notification setup so a new is posted
            else if (!this.originalNotificationPreferences && this.notificationPreferences) {
                this.irService.postDeploymentNotificationPreferences(this.deploymentUri, this.notificationPreferences.subscribers,
                    this.notificationPreferences.deathTimeout, this.currentUser.token).subscribe(res => {
                    console.log('Post Notification response is ', res);
                    if (res.errorMessage === 'success') {
                        this.notificationSubmitSuccess = true;
                        this.saveNotificationSettings(res.result.notificationId);
                        if (this.deploymentSubmitSuccess) {
                            this.handleSubmitSuccess();
                        }
                    }
                });
            }
            // no changes to notifications
            else {
                this.notificationSubmitSuccess = true;
                if (this.deploymentSubmitSuccess) {
                    this.handleSubmitSuccess();
                }
            }
        }
    }

    saveNotificationSettings(notificationId) {
        this.notificationPreferences['notificationId'] = notificationId;
        this.originalNotificationPreferences = cloneDeep(this.notificationPreferences);
    }

    handleSubmitSuccess() {
        this.isSubmiting = false;
        // this.router.navigate(['./deployment-manager/deployments']);
        swal('Success!', `Description: the deployment was successfully updated.`, 'success');
    }

    onCoordinateChange(latLng) {
        swal({
            title: 'Are you sure?',
            text: 'Would you like to change coordinates to the place you just selected?',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes'
        }).then((res) => {
            if (res.value) {
                this.deployment.location.lat = latLng.lat;
                this.deployment.location.lng = latLng.lng;
            }
        });
    }

    showMap() {
        this.showFormMap = !this.showFormMap;
    }

    onResize(event) {
        if (event.target.innerWidth > 900) {
            this.showFormMap = false;
        }
    }

    validateUri(uri) {
        // check if URI is empty
        if (uri.length === 0) {
            this.uriIsUnique = true;
            this.uriIsValid = true;
            return;
        }
        // check if URI has invalid characters
        this.uriIsValid = this.uriVerifier.test(uri);
        // check if URI is unique
        let unique = true;
        this.allDeployments.forEach(deployment => {
            if (deployment.deploymentUri === uri) {
                unique = false;
            }
            return;
        });
        this.uriIsUnique = unique;
    }

    isUniquesIds() {
        const sensIds = this.deployment.sensors.map(i => i.sensorIndex);
        return sensIds.some((i, indx) => {
            return sensIds.indexOf(i) !== indx;
        });
    }

    delete() {
        swal({
            title: 'Confirm Deployment Deletion',
            text: 'This action cannot be reverted. Are you sure you want to delete this deployment?',
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#023f7d',
            cancelButtonColor: '#6c757d',
            confirmButtonText: 'Yes'
        }).then((res) => {
            if (res.value) {
                this.showLoading = true;
                this.irService.deleteDeployment(this.deployment.deploymentId, this.currentUser.token).subscribe(response => {
                    this.showLoading = false;
                    if (response.errorMessage === 'success') {
                        swal('Success!', `Description: the deployment was successfully deleted.`, 'success');
                        this.router.navigate(['./deployment-manager/deployments']);
                    }
                    else {
                        swal('Error!', `Description: something went wrong. Please try again`, 'error');
                    }
                }, error => {
                    console.log('ERROR: deployment form: delete error: ', error);
                    swal('Error!', `Description: something went wrong. Please try again`, 'error');
                });
            }
        });
    }


    /*// Sensors management
    getAvailableToAddSensors(): any[] {
        const res = [];
        if (this.deployment.sensors.filter(i => i.type === 'ONEWIRE').length < this.deployment.max1Wire) {
            res.push(...this.oneWireOptions);
        }
        if (this.deployment.sensors.filter(i => i.type === 'SDI12').length < this.deployment.maxSdi12) {
            res.push(...this.sdi12Options);
        }
        if (this.deployment.sensors.filter(i => i.type === 'ANALOG').length < this.deployment.numAds) {
            res.push(...this.analogOptions);
        }
        return res;
    }*/

    oneWireAvailable(): boolean {
        if ('max1Wire' in this.deployment) {
            return this.deployment.sensors.filter(i => i.type === 'ONEWIRE').length < this.deployment.max1Wire;
        } else {
            return true;
        }
    }

    sdi12Available(): boolean {
        if ('maxSdi12' in this.deployment) {
            console.log('number of sdi12 devices', this.deployment.sensors);
            console.log('number of max sdi12 sensors', this.deployment.maxSdi12);
            return this.deployment.sensors.filter(i => i.type === 'SDI12').length < this.deployment.maxSdi12;
        } else {
            return true;
        }
    }

    analogAvailable(): boolean {
        if ('numAds' in this.deployment && this.deployment.numAds >= 0) {
            console.log('analog devices number', this.deployment.sensors.filter(i => i.type === 'ANALOG').length);
            console.log('number of analog devices is', this.deployment);
            return this.deployment.sensors.filter(i => i.type === 'ANALOG').length < this.deployment.numAds;
        } else {
            return true;
        }

    }

    addSensor() {
        // console.log('DEBUG: selected sensor id is ', this.selectedNewSensorId);
        // this.deployment.sensors.push(foundSensor);

        this.irService.getSensorEntityInfo(this.selectedNewSensorId).subscribe(sensor => {
            /*if (sensor.type === 'ANALOG'){
                sensor.parameters.forEach(i => {
                    i.units = 'Raw ADC Value';
                    i.convertUnits = 'Raw ADC Value';
                });
            }*/
            sensor.sensorId  = this.selectedNewSensorId;
            this.deployment.sensors.push(sensor);
            console.log('added sensor is ', sensor);
            this.selectedNewSensorId = undefined;
        });
    }

    changeSensorSelection(value: string) {
        console.log('new sensor selected', value);
        this.selectedNewSensorId = value;
    }

    removeSensor(sensingId) {
        this.deployment.sensors = this.deployment.sensors.filter(i => i.sensorId !== sensingId);
    }

    openSensorInNewWindow(sensorId) {
        // Converts the route into a string that can be used
        // with the window.open() function '/deployment-manager/sensors/'+ sensor.sensorId
        const url = this.router.serializeUrl(
            this.router.createUrlTree(['/deployment-manager/sensors/'+ sensorId])
        );

        window.open(url, '_blank');
    }

    // warning generating functions


    // warning alerts:
    isSDI12LimitReached() {
        if (this.deployment.maxSdi12 === undefined) {
            return false;
        }
        return this.deployment.sensors.filter(i => i.type === 'SDI12').length >= +this.deployment.maxSdi12;
    }

    isOneWireLimitReached() {
        if (this.deployment.max1Wire === undefined) {
            return false;
        }
        return this.deployment.sensors.filter(i => i.type === 'ONEWIRE').length >= +this.deployment.max1Wire;
    }

    isAnalogCorrect() {
        if (this.deployment.numAds === undefined) {
            return false;
        }
        return this.deployment.sensors.filter(i => i.type === 'ANALOG').length !== +this.deployment.numAds;
    }

    isSdiNotEnough() {
        if (this.deployment.minSdi12 === undefined) {
            return false;
        }
        return this.deployment.sensors.filter(i => i.type === 'SDI12').length < +this.deployment.minSdi12;
    }

    isOneWireNotEnought() {
        if (this.deployment.min1Wire === undefined) {
            return false;
        }
        return this.deployment.sensors.filter(i => i.type === 'ONEWIRE').length < +this.deployment.min1Wire;
    }
}
