import {throwError as observableThrowError, Observable, Subject, BehaviorSubject} from 'rxjs';

import {first, catchError, map} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams, HttpResponse, HttpErrorResponse} from '@angular/common/http';


import * as moment from 'moment';
import * as $ from 'jquery';

import {Project, ProjectsJSON} from '../models/projects.model';
import {Statistics, StatisticsJSON} from '../models/statistics.model';
import {BugReportForm} from '../models/bugReportForm.model';
import {Error, SystemError, SystemErrorJSON} from '../models/error.model';
import {DeploymentNotification, Notification, NotificationForm} from '../models/notification.model';
import {BoundaryNotification} from '../models/boundary.model';
import {Template, TemplateManagerList, TemplateManagerListJSON, TemplateJSON} from '../models/template.model';
import {Device, DeviceForm, SensingDeviceManagerList, SensingDeviceManagerListJSON, Sensor, SensorEntityJSON} from '../models/device.model';
import {
    Motestack,
    MotestackForm,
    MotestackManagerList,
    MotestackManagerListJSON, MotestackJSON
} from '../models/motestack.model';
import {Maintenance, MaintenanceAllJSON} from '../models/maintenance.model';
import {
    Deployment, DeploymentDetails, DeploymentsByProjectId, DeploymentsByProjectIdJSON, DeploymentsAllJSON,
    DeploymentDetailsByDeploymentIdJSON, DeploymentDetailsByDeploymentId, DeploymentsForm,
    Sensing, SensingDevice, Parameter, CommConfig, DeploymentsManagerListJSON
} from '../models/deployments.model';
import {User, UserAccount, UserJSON, UserEntity} from '../models/user.model';
import {DataTemporary, DataTemporaryJSON} from '../models/data.model';
import {
    DE910Diagnostics, Diagnostics, DiagnosticsJSON, ADSDiagnostics,
    Observation, ObservationMessage
} from '../models/diagnostics.model';
import {SensorStatus, StatusJSON, MoteStackMessage, NewSensorStatus} from '../models/status.model';
import swal from 'sweetalert2';
import {AuthService} from './auth.service';
import {ManagerProject, ManagerProjectDetails} from '../models/managerProject.model';
import {
    DeploymentData,
    SensorMeasurements,
    DeploymentDataApiResponse,
} from '../models/data.model';
import {DeploymentDiagnosticMessage, DeploymentDiagnosticMessagesJSON} from '../components/routes/diagnostic/diagnostic.component';
import {environment} from '../../environments/environment';

@Injectable()
export class IntelligentRiverService {
    // utils
    private headers: HttpHeaders = new HttpHeaders({'Content-Type': 'application/json'});
    private lastUpdate: any;

    // current vars
    private currentProjectId: number;
    private currentProject: Project;
    observeCurrentProject: Subject<Project>;

    private currentDeploymentId: string;
    private currentDeployment: Deployment;
    observeCurrentDeployment: Subject<Deployment>;

    private currentMaintenance: Maintenance;
    observeCurrentMaintenance: Subject<Maintenance>;

    // resources
    private currentResource: string;
    private statistics: StatisticsJSON;
    observeStatistics: Subject<Statistics>;
    private projects: ProjectsJSON;
    observeProjects: Subject<Project[]>;
    private deployments: Deployment[];
    observeDeployments: Subject<Deployment[]>;
    private motestacks: Motestack[];
    observeMotestacks: Subject<Motestack[]>;
    private deploymentsByProjectId: {};
    observeDeploymentsByProjectId: Subject<any>;
    private deploymentDetailsByProjectId: {};
    observeDeploymentDetailsByProjectId: BehaviorSubject<{}>;
    private deploymentDataMetadataIndex: {};
    observeDeploymentDataMetadataIndex: BehaviorSubject<{}>;
    private deploymentDetailsByDeploymentId: DeploymentDetailsByDeploymentIdJSON;
    observeDeploymentDetailsByDeploymentId: Subject<DeploymentDetailsByDeploymentIdJSON>;
    private maintenance: MaintenanceAllJSON;
    observeMaintenance: Subject<any>;
    private maintenanceByProjectId: {};
    observeMaintenanceByProjectId: Subject<any>;
    private diagnostics: Diagnostics;
    private deploymentsFormByProjectId: {};
    observeDeploymentsFormByProjectId: Subject<any>;

    // localstorage keys
    public localStorageKeys: {};
    public serverAPI = 'http://sstest.us-west-2.elasticbeanstalk.com';

    constructor(private http: HttpClient, private authService: AuthService) {

        this.localStorageKeys = {
            'intelligent-river-lastUpdate': this.lastUpdate,
            'intelligent-river-projects': this.projects,
            'intelligent-river-statistics': this.statistics,
            'intelligent-river-deployments': this.deployments,
            'intelligen-river-motestacks': this.motestacks,
            'intelligent-river-deploymentsByProjectId': this.deploymentsByProjectId,
            'intelligent-river-deploymentDetailsByProjectId': this.deploymentDetailsByProjectId,
            'intelligent-river-deploymentDetailsByDeploymentId': this.deploymentDetailsByDeploymentId,
            'intelligent-river-deploymentDataMetadataIndex': this.deploymentDataMetadataIndex,
            'intelligent-river-maintenance': this.maintenance,
            'intelligent-river-maintenanceByProjectId': this.maintenanceByProjectId,
            'intelligent-river-deploymentsFormByProjectId': this.deploymentsFormByProjectId,
        };

        this.currentProjectId = null;
        this.currentDeploymentId = null;
        this.currentResource = null;
        this.currentMaintenance = null;
        this.projects = null, this.statistics = null, this.deployments = null, this.deploymentsByProjectId = null;
        this.maintenanceByProjectId = {};
        this.deploymentsByProjectId = {};
        this.deploymentDataMetadataIndex = {};
        this.deploymentDetailsByProjectId = {};
        this.deploymentDetailsByDeploymentId = {};
        this.deploymentsFormByProjectId = {};

        this.observeStatistics = new BehaviorSubject<Statistics>(null);
        this.observeCurrentProject = new BehaviorSubject<Project>(null);
        this.observeProjects = new BehaviorSubject<Project[]>(null);
        this.observeCurrentDeployment = new BehaviorSubject<Deployment>(null);
        this.observeDeployments = new BehaviorSubject<Deployment[]>(null);
        this.observeMotestacks = new BehaviorSubject<Motestack[]>(null);
        this.observeDeploymentDetailsByProjectId = new BehaviorSubject<{}>(null);
        this.observeDeploymentDataMetadataIndex = new BehaviorSubject<{}>(null);
        this.observeDeploymentsByProjectId = new BehaviorSubject<any>(null);
        this.observeDeploymentDetailsByDeploymentId = new BehaviorSubject<DeploymentDetailsByDeploymentIdJSON>({});
        this.observeMaintenance = new BehaviorSubject<Maintenance[]>(null);
        this.observeMaintenanceByProjectId = new BehaviorSubject<any>({});
        this.observeDeploymentsFormByProjectId = new BehaviorSubject<DeploymentsForm[]>(null);

        this.loadLocalStorage();
    }

    getCurrentProjectId(): number {
        return this.currentProjectId;
    }

    setCurrentProjectId(projectId: number): void {
        this.currentProjectId = projectId;
    }

    getCurrentResource(): string {
        return this.currentResource;
    }

    setCurrentResource(resource: string): void {
        this.currentResource = resource;
    }

    getCurrentDeploymentId(): string {
        return this.currentDeploymentId;
    }

    setCurrentDeploymentId(deploymentId: string): void {
        this.currentDeploymentId = deploymentId;
    }

    setStatistics(statistics: StatisticsJSON): void {
        this.observeStatistics.next(null);
        this.statistics = statistics;
        this.observeStatistics.next(this.statistics.statistics);

    }

    setCurrentProject(currentProjectId: number): void {
        console.log('DEBUG1: setting current project: ', currentProjectId);
        if (this.currentProject && this.currentProject.projectId === currentProjectId) {
            console.log('DEBUG1: the project id was already set');
            return;
        }
        if (currentProjectId === null) {
            console.log('DEBUG1: the project id was null, setting current project to null');
            this.currentProject = null;
        } else {
            if (this.projects && 'result' in this.projects) {
                this.currentProject = this.projects.result.find(i => i.projectId === currentProjectId);
                console.log('DEBUG1: the projects already loaded, finding the current one: ', this.currentProject, 'all projects are', this.projects);
            } else {
                console.log('DEBUG1: the projects were not loaded, requesting all projects...');
                this.getAllProjects().subscribe((next) => {

                    this.currentProject = next.find(i => i.projectId === currentProjectId);
                    this.observeCurrentProject.next(this.currentProject);
                    this.currentProjectId = currentProjectId;
                });
                return;
            }
        }
        this.observeCurrentProject.next(this.currentProject);
        this.currentProjectId = currentProjectId;

    }

    setProjects(projects: ProjectsJSON): void {
        // this.observeProjects.next(null);
        this.projects = projects;
        this.observeProjects.next(this.projects.result);
        this.saveLocalStorage('intelligentriver.org-projects', this.projects);

    }

    setCurrentDeployment(currentDeployment): void {
        // this.observeCurrentDeployment.next(null);
        this.currentDeployment = currentDeployment;
        this.observeCurrentDeployment.next(this.currentDeployment);
        if (currentDeployment) {
            this.currentDeploymentId = currentDeployment.id;
        }
    }

    setAllDeployments(deployments: Deployment[]): void {
        // this.observeDeployments.next(null);
        this.deployments = deployments;
        this.observeDeployments.next(this.deployments);
        this.saveLocalStorage('intelligentriver.org-deployments', this.deployments);

    }

    addDeploymentDataMetadataIndexByProjectId(projectId: number, deploymentDetailsByProjectId: DeploymentDetails[]): void {
        if (projectId in this.deploymentDataMetadataIndex) {
            return;
        } else {
            const objByProjectId: {} = {};
            let deployment: DeploymentDetails;
            let deploymentId: string;
            let sensing: Sensing;
            let parameter: Parameter;
            let property: string;
            let unit: string;

            let min: number;
            let max: number;

            objByProjectId['filters'] = {};
            for (let i = 0; i < deploymentDetailsByProjectId.length; i++) {
                deployment = deploymentDetailsByProjectId[i];
                deploymentId = deployment.id;
                objByProjectId[deployment.id] = {
                    meta: {
                        lng: deployment.location.lng,
                        lat: deployment.location.lat
                        // 'hue' missing
                    }
                };

                let k = 0;
                for (let j = 0; j < deployment.motestackId.sensing.length; j++) {
                    sensing = deployment.motestackId.sensing[j];

                    for (k; k < sensing.parameters.length; k++) {
                        parameter = sensing.parameters[k];
                        property = parameter.parameter.property;
                        unit = parameter.parameter.unit;
                        min = parameter.parameter.min;
                        max = parameter.parameter.max;
                        if (!(property in objByProjectId['filters'])) {
                            objByProjectId['filters'][property] = {
                                property: property,
                                unit: unit,
                                min: min,
                                max: max
                            };
                        } else {
                            if (objByProjectId['filters'][property].min > min) {
                                objByProjectId['filters'][property].min = min;
                            }
                            if (objByProjectId['filters'][property].max < max) {
                                objByProjectId['filters'][property].max = max;
                            }
                        }

                        if (!(property in objByProjectId[deployment.id])) {
                            objByProjectId[deployment.id][property] = {
                                parameterPosition: [],
                                parameterIds: []
                            };
                        }
                        objByProjectId[deployment.id][property].parameterPosition.push(k);
                        objByProjectId[deployment.id][property].parameterIds.push(parameter.parameter.id);
                    }
                }
            }
            this.deploymentDataMetadataIndex[projectId] = objByProjectId;
        }

        this.observeDeploymentDataMetadataIndex.next(this.deploymentDataMetadataIndex);
        this.saveLocalStorage('intelligentriver.org-deploymentDataMetadataIndex', this.deploymentDataMetadataIndex);

        // console.log('Sense Stream Service: deploymentDataMetadataIndex updated and locally saved', this.deploymentDataMetadataIndex);
    }

    addDeploymentsByProjectId(projectId, newDeploymentsByProjectId): void {
        if (projectId in this.deploymentsByProjectId) {
            return;
        }
        // this.observeDeploymentsByProjectId.next(null);
        this.deploymentsByProjectId[projectId] = newDeploymentsByProjectId;
        this.observeDeploymentsByProjectId.next(this.deploymentsByProjectId);
        this.saveLocalStorage('intelligentriver.org-deploymentsByProjectId', this.deploymentsByProjectId);

        // console.log('Sense Stream Service: deploymentsByProjectId updated and locally saved', this.deploymentsByProjectId);
    }

    addDeploymentDetailsByProjectId(projectId, expandedDeployments) {
        if (projectId in this.deploymentDetailsByProjectId) {
            return;
        }
        // this.observeDeploymentsByProjectId.next(null);
        this.deploymentDetailsByProjectId[projectId] = expandedDeployments;
        this.observeDeploymentDetailsByProjectId.next(this.deploymentDetailsByProjectId);
        this.saveLocalStorage('intelligentriver.org-deploymentDetailsByProjectId', this.deploymentDetailsByProjectId);

        // console.log('Sense Stream Service: deploymentDetailsByProjectId updated and locally saved',
        //   this.deploymentDetailsByProjectId);
    }

    addDeploymentDetailsByDeploymentId(deploymentId, newDeploymentDetails: DeploymentDetails): void {
        if (deploymentId in this.deploymentDetailsByDeploymentId && 'parameters' in newDeploymentDetails) {
            return;
        } else {
            const parameters: Parameter[] = [];
            for (let i = 0; i < newDeploymentDetails.motestackId.sensing.length; i++) {
                for (let j = 0; j < newDeploymentDetails.motestackId.sensing[i].parameters.length; j++) {
                    parameters.push(newDeploymentDetails.motestackId.sensing[i].parameters[j]);
                }
            }
            newDeploymentDetails['parameters'] = parameters;
        }
        // this.observeDeploymentsByProjectId.next(null);
        this.deploymentDetailsByDeploymentId[deploymentId] = newDeploymentDetails;
        this.observeDeploymentDetailsByDeploymentId.next(this.deploymentDetailsByDeploymentId);
        this.saveLocalStorage('intelligentriver.org-deploymentDetailsByDeploymentId', this.deploymentDetailsByDeploymentId);

        // console.log('Sense Stream Service: deploymentDetailsByDeploymentId updated and locally saved',
        //   this.deploymentDetailsByDeploymentId);
    }

    getStatistics(): Observable<Statistics> {
        // https://stackoverflow.com/questions/44682115/angular-4-http-service-cache
        // https://stackoverflow.com/questions/41554156/angular-2-cache-observable-http-result-data
        // http://reactivex.io/rxjs/class/es6/Observable.js~Observable.html#static-method-create

        // getting rid of caching so as to force new data each time
        // if (this.statistics) {
        //   console.log('Sense Stream Service: calling getStatistics() );
        //   return Observable.create((observable) => {
        //     observable.next(this.statistics.statistics);
        //     observable.complete();
        //   });
        // }
        // console.log('Sense Stream Service: getStatistics() new HTTP Request');
        return this.http.get(this.serverAPI + '/api/statistics?timeZoneID=' + this.getTimezoneId(), {
            headers: this.headers
        }).pipe(map(
            (response: StatisticsJSON) => {
                this.setStatistics(response);
                this.saveLocalStorage('intelligentriver.org-statistics', this.statistics);
                // console.log('Sense Stream Service: getStatistics() resopnse', response);
                return response.statistics;
            }
        ), catchError(
            (error: HttpErrorResponse) => {
                return observableThrowError(error);
            }
        ),);
    }

    getAllProjects(): Observable<Project[]> {
        const url = environment.apiUrl + '/manager/projects';
        console.log('DEBUG1: getAllProjects: getting all projects, URL IS ', url);
        return this.http.get(url, {
            headers: this.headers
        }).pipe(map(
            (response: ProjectsJSON) => {
                this.setProjects(response);
                this.saveLocalStorage('intelligentriver.org-projects', this.projects);
                return response.result;
            }
        ), catchError(
            (error: HttpErrorResponse) => {
                return observableThrowError(error);
            }
        ),);
    }

    /**
     * INF: gets all the data about the given deployment except for the sensors measurements
     * @param deploymentUri
     */
    getDeploymentData(deploymentUri): Observable<Deployment> {
        const url = environment.apiUrl + '/manager/deployments/by-uri/' + deploymentUri;
        return this.http.get(url, {
            headers: this.headers
        }).pipe(map(
            (response: DeploymentDataApiResponse) => {
                return response.result;
            }
        ), catchError(
            (error: HttpErrorResponse) => {
                return observableThrowError(error);
            }
        ),);
    }

    /**
     * INF: gets measurements for the given sensor and given timeperiod in milliseconds
     * https://newapi.sensestream.org/v1/measurements/DEPLOYMENT_URI/SENSOR_INDEX?start=START_MILLIS&end=END_MILLIS
     * @param deploymentUri
     * @param sensorIndex
     * @param startMillis
     * @param endMillis
     */
    getSensorMeasurements(deploymentUri, sensorIndex, startMillis, endMillis): Observable<SensorMeasurements[]> {
        const url = environment.apiUrl + '/observations/measurements/' + deploymentUri + '/' + sensorIndex;
        const params = new HttpParams().set('start', startMillis.toString()).set('end', endMillis.toString());
        return this.http.get(url, {
            headers: this.headers,
            params: params
        }).pipe(map(
            /**
             * TODO: had DeploymentDataApiResponse instead of any,
             */
            (response: any) => {
                return response.result;
            }
        ), catchError(
            (error: HttpErrorResponse) => {
                return observableThrowError(error);
            }
        ),);
    }

    getProjectById(projectId: number): Project {
        if (this.projects) {
            this.projects.result.find(project => {
                if (project.projectId === projectId) {
                    return project;
                }
            });
        } else {
            this.getAllProjects().subscribe(projects => {
                projects.find(project => {
                    if (project.projectId === projectId) {
                        return project;
                    }
                });
            });
        }
        return null;
    }

    /**
     * INF: gets measurements for the given sensor for the last 24 hours
     * https://newapi.sensestream.org/v1/measurements/DEPLOYMENT_URI/SENSOR_INDEX?start=START_MILLIS&end=END_MILLIS
     * @param deploymentUri
     * @param sensorIndex
     *
     * was returning SensorMeasurements[], had to change to any so that new angular version works
     */
    getSensorMeasurementsForToday(deploymentUri, sensorIndex): Observable<any> {
        // let now = new Date();
        // let endMillis = now.getTime();
        // now.setHours(0, 0, 0, 0);
        // let startMillis = now.getTime();
        const todayTimeStamp = this.getTodayRangeTimestamps();
        const url = environment.apiUrl + '/observations/measurements/' + deploymentUri + '/' + sensorIndex;
        const params = new HttpParams().set('start', todayTimeStamp.start.toString()).set('end', todayTimeStamp.end.toString());
        return this.http.get(url, {
            headers: this.headers,
            params: params
        }).pipe(map(
            (response: DeploymentDataApiResponse) => {
                return response.result;
            }
        ), catchError(
            (error: HttpErrorResponse) => {
                return observableThrowError(error);
            }
        ),);
    }

    getTodayRangeTimestamps() {
        let now = new Date();
        let endMillis = now.getTime();
        now.setHours(0, 0, 0, 0);
        let startMillis = now.getTime();
        return {
            start: startMillis,
            end: endMillis
        };
    }

    getManagerProjects(): Observable<ManagerProject[]> {
        const url = environment.apiUrl + '/manager/projects';
        return this.http.get(url, {
            headers: this.headers
        }).pipe(map(
            (response) => {
                return (<any>response).result;
            }
        ), catchError(
            (error: HttpErrorResponse) => {
                return observableThrowError(error);
            }
        ),);
    }

    getManagerProjectEntity(projectId): Observable<ManagerProjectDetails> {
        const url = environment.apiUrl + '/manager/projects/' + projectId;
        return this.http.get(url, {
            headers: this.headers
        }).pipe(map(
            (response) => {
                return (<any>response).result[0];
            }
        ), catchError(
            (error: HttpErrorResponse) => {
                return observableThrowError(error);
            }
        ),);
    }

    postManagerProjects(project: ManagerProjectDetails, token): Observable<ManagerProject[]> {
        const url = environment.apiUrl + '/manager/projects/';
        const headers = this.headers.append('x-auth-token', token);
        return this.http.post(url, JSON.stringify(project), {
            headers: headers
        }).pipe(map(
            (response: any) => {
                return response;
            }
        ), catchError(
            (error: HttpErrorResponse) => {
                return observableThrowError(error);
            }
        ),);
    }

    /**
     * INF: new update
     * @param deployment
     * @param token
     */
    postDeployment(deployment: Deployment, token: string): Observable<any> {
        const url = environment.apiUrl + '/manager/deployments/';
        return this.postDeploymentHelper(deployment, token, url, true);
    }

    updateDeployment(deployment: Deployment, token: string): Observable<any> {
        const url = environment.apiUrl + '/manager/deployments/' + deployment.deploymentId;
        return this.postDeploymentHelper(deployment, token, url, false);
    }

    postDeploymentHelper(deployment: Deployment, token: string, url: string, isNew: boolean): Observable<any> {
        const headers = this.headers.append('x-auth-token', token);
        const body = {
            name: deployment.name,
            sensors: deployment.sensors,
            min1Wire: deployment.min1Wire,
            max1Wire: deployment.maxSdi12,
            minSdi12: deployment.minSdi12,
            maxSdi12: deployment.maxSdi12,
            numAds: deployment.numAds,
            sPeriod: deployment.sPeriod,
            nvsramLog: deployment.nvsramLog,
            sdi12v3: deployment.sdi12v3,
            location: deployment.location,
            active: deployment.active,
            deploymentUri: deployment.deploymentUri
        };
        return this.http.post(url, JSON.stringify(body), {
            headers: headers
        }).pipe(map(
            (response: any) => {
                if (response.errorMessage === 'success') {
                    this.deployments = this.loadSpecificStorage('intelligentriver.org-deployments');
                    if (this.deployments) {
                        if (isNew) {
                            this.deployments.push(deployment);
                            this.setAllDeployments(this.deployments);   // trigger Subject
                        } else {
                            const updateDeployment = this.deployments.find(i => i.deploymentUri === deployment.deploymentUri);
                            this.deployments[this.deployments.indexOf(updateDeployment)] = deployment;
                            this.setAllDeployments(this.deployments);   // trigger Subject
                        }
                    }
                    return response;
                }
            }
        ), catchError(
            (error: HttpErrorResponse) => {
                return observableThrowError(error);
            }
        ),);
    }

    /**
     * Delete radio (DELETE): /manager/radios/{radioId}, requires token
     * @param templateId
     * @param token
     */
    deleteRadio(radioId: string, token: string): Observable<any> {
        const url = environment.apiUrl + '/manager/radios/' + radioId;
        return this.http.delete(url, {
            headers: this.headers.append('x-auth-token', token)
        }).pipe(map(
            (response: any) => {
                console.log('API: delete template response is', response);
                return response;
            }
        ), catchError(
            (requestError: HttpErrorResponse) => {
                return observableThrowError(requestError);
            }
        ));
    }

    /**
     * NEW API
     * @param start
     * @param end
     * @param token
     */
    getMiddlewareErrors(start: number, end: number, token): Observable<SystemError[]> {
        const headers = this.headers.append('x-auth-token', token);
        const params = new HttpParams().set('start', start.toString()).set('end', end.toString());
        const url = environment.apiUrl + '/infrastructure/errors';
        return this.http.get(url, {
            headers: headers,
            params: params
        }).pipe(map(
            (response: SystemErrorJSON) => {
                return response.result;
            }
        ), catchError(
            (error: HttpErrorResponse) => {
                if (error.status === 401) {
                    swal('Error!', error.error.description, 'error');
                    this.authService.observeCurrentUser.next(null);
                    this.authService.observeLoggedIn.next(false);

                    this.authService.clearUserInStorage();
                    this.authService.retrieveUserFromStorage();
                }
                return observableThrowError(error);
            }
        ),);
    }

    getUsers(token: string): Observable<UserEntity[]> {
        const headers = this.headers.append('x-auth-token', token);
        return this.http.get(environment.apiUrl + '/accounts', {
            headers: headers
        }).pipe(map(
            (response: { result: Array<UserEntity> }) => {
                return response.result;
            }
        ), catchError(
            (error: HttpErrorResponse) => {
                if (error.status === 401) {
                    swal('Error!', error.error.description, 'error');
                    this.authService.observeCurrentUser.next(null);
                    this.authService.observeLoggedIn.next(false);

                    this.authService.clearUserInStorage();
                    this.authService.retrieveUserFromStorage();
                }
                return observableThrowError(error);
            }
        ),);
    }

    getAccountById(accountId, token: string): Observable<UserAccount> {
        const url = environment.apiUrl + '/accounts/' + accountId;
        const headers = this.headers.append('x-auth-token', token);
        return this.http.get(url, {
            headers: headers
        }).pipe(map(
            (response: any) => {
                return response.result[0];
            }
        ), catchError(
            (error: HttpErrorResponse) => {
                if (error.status === 401) {
                    swal('Error!', error.error.description, 'error');
                    this.authService.observeCurrentUser.next(null);
                    this.authService.observeLoggedIn.next(false);

                    this.authService.clearUserInStorage();
                    this.authService.retrieveUserFromStorage();
                }
                return observableThrowError(error);
            }
        ),);
    }

    /**
     * INF: new API
     * @param account
     * @param password
     * @param token
     */
    postAccount(account: UserAccount, password: string, token: string): Observable<any> {
        const url = environment.apiUrl + '/accounts/signUp';
        return this.postAccountHelper(account, password, token, url);
    }

    updateAccount(account: UserAccount, password: string, token: string): Observable<any> {
        const url = environment.apiUrl + '/accounts/' + account.accountId;
        return this.postAccountHelper(account, password, token, url);
    }

    postAccountHelper(account: UserAccount, password: string, token: string, url: string): Observable<any> {
        return this.http.post(url, JSON.stringify({
            email: account.email,
            firstName: account.firstName,
            lastName: account.lastName,
            password: password
        }), {
            headers: this.headers.append('x-auth-token', token)
        }).pipe(map(
            (response: any) => {
                return response;
            }
        ), catchError(
            (error: HttpErrorResponse) => {
                return observableThrowError(error);
            }
        ),);
    }

    deleteAccount(accountId: number, token: string): Observable<any> {
        const url = environment.apiUrl + '/accounts/' + accountId;
        return this.http.delete(url, {
            headers: this.headers.append('x-auth-token', token)
        }).pipe(map(
            (response: any) => {
                console.log('API: delete account response is', response);
                return response;
            }
        ), catchError(
            (requestError: HttpErrorResponse) => {
                return observableThrowError(requestError);
            }
        ));
    }

    updateUser(id: string, email: string, username: string, token: string): Observable<any> {
        const body = JSON.stringify({
            id: id,
            email: email,
            username: username,
            token: token
        });
        return this.http.post(this.serverAPI + '/api/updateUser', body, {
            headers: this.headers
        }).pipe(map(
            (response: any) => {
                return response;
            }
        ), catchError(
            (error: HttpErrorResponse) => {
                return observableThrowError(error);
            }
        ),);
    }

    getNotifications(token: string): Observable<Notification[]> {
        return this.http.get(this.serverAPI + '/api/notificationSubscribers/?token=' + token, {
            headers: this.headers
        }).pipe(map(
            (response: any) => {
                return response.subscribers;
            }
        ), catchError(
            (error: HttpErrorResponse) => {
                if (error.status === 401) {
                    swal('Error!', error.error.description, 'error');
                    this.authService.observeCurrentUser.next(null);
                    this.authService.observeLoggedIn.next(false);

                    this.authService.clearUserInStorage();
                    this.authService.retrieveUserFromStorage();
                }
                return observableThrowError(error);
            }
        ),);
    }

    getBoundaryNotifications(token: string): Observable<any[]> {
        return this.http.get(this.serverAPI + '/api/boundaryNotifications/?token=' + token, {
            headers: this.headers
        }).pipe(map(
            (response: any) => {
                return response.boundaries;
            }
        ), catchError(
            (error: HttpErrorResponse) => {
                if (error.status === 401) {
                    swal('Error!', error.error.description, 'error');
                    this.authService.observeCurrentUser.next(null);
                    this.authService.observeLoggedIn.next(false);

                    this.authService.clearUserInStorage();
                    this.authService.retrieveUserFromStorage();
                }
                return observableThrowError(error);
            }
        ),);
    }

    getNotificationsForm(token: string): Observable<NotificationForm> {
        return this.http.get(this.serverAPI + '/api/notificationSubscribersForm/?token=' + token, {
            headers: this.headers
        }).pipe(map(
            (response: any) => {
                return response;
            }
        ), catchError(
            (error: HttpErrorResponse) => {
                if (error.status === 401) {
                    swal('Error!', error.error.description, 'error');
                    this.authService.observeCurrentUser.next(null);
                    this.authService.observeLoggedIn.next(false);

                    this.authService.clearUserInStorage();
                    this.authService.retrieveUserFromStorage();
                }
                return observableThrowError(error);
            }
        ),);
    }

    postNotification(notification: Notification, token: string): Observable<any> {
        const body = JSON.stringify({
            title: notification.title,
            maxNotifications: notification.maxNotifications,
            notifyTime: notification.notifyTime,
            deathTime: notification.deathTime,
            deployments: notification.deployments,
            subscriberEmails: notification.subscriberEmails,
            blocked: notification.blocked,
            token: token
        });
        return this.http.post(this.serverAPI + '/api/postNotificationSubscriber', body, {
            headers: this.headers
        }).pipe(map(
            (response: any) => {
                return response;
            }
        ), catchError(
            (error: HttpErrorResponse) => {
                return observableThrowError(error);
            }
        ),);
    }

    updateNotification(notification: Notification, token: string): Observable<any> {
        const body = JSON.stringify({
            title: notification.title,
            id: notification.id,
            maxNotifications: notification.maxNotifications,
            notifyTime: notification.notifyTime,
            deathTime: notification.deathTime,
            deployments: notification.deployments,
            subscriberEmails: notification.subscriberEmails,
            blocked: notification.blocked,
            token: token
        });
        return this.http.post(this.serverAPI + '/api/updateNotificationSubscriber', body, {
            headers: this.headers
        }).pipe(map(
            (response: any) => {
                return response;
            }
        ), catchError(
            (error: HttpErrorResponse) => {
                return observableThrowError(error);
            }
        ),);
    }

    deleteNotification(id: string, token: string): Observable<any> {
        const body = JSON.stringify({
            id: id,
            token: token
        });
        return this.http.post(this.serverAPI + '/api/deleteNotificationSubscriber', body, {
            headers: this.headers
        }).pipe(map(
            (response: any) => {
                return response;
            }
        ), catchError(
            (error: HttpErrorResponse) => {
                return observableThrowError(error);
            }
        ),);
    }


    changeUserPassword(id: string, oldPasswordSHA1: string, newPasswordSHA1: string, token: string): Observable<any> {
        const body = JSON.stringify({
            id: id,
            oldPasswordSHA1: oldPasswordSHA1,
            newPasswordSHA1: newPasswordSHA1,
            token: token
        });
        return this.http.post(this.serverAPI + '/api/updateUserPassword', body, {
            headers: this.headers
        }).pipe(map(
            (response: any) => {
                return response;
            }
        ), catchError(
            (error: HttpErrorResponse) => {
                return observableThrowError(error);
            }
        ),);
    }

    getTemplates(): Observable<TemplateManagerList[]> {
        return this.http.get(environment.apiUrl + '/manager/templates', {
            headers: this.headers
        }).pipe(map(
            (response: TemplateManagerListJSON) => {
                return response.result;
            }
        ), catchError(
            (error: HttpErrorResponse) => {
                return observableThrowError(error);
            }
        ),);
    }

    getTemplateEntityById(templateId): Observable<Template> {
        const url = environment.apiUrl + '/manager/templates/' + templateId;
        return this.http.get(url, {
            headers: this.headers
        }).pipe(map(
            (response: TemplateJSON) => {
                return response.result[0];
            }
        ), catchError(
            (error: HttpErrorResponse) => {
                return observableThrowError(error);
            }
        ),);
    }

    /**
     * INF: new api
     */
    getAllSensors(): Observable<SensingDeviceManagerList[]> {
        return this.http.get(environment.apiUrl + '/manager/sensors', {
            headers: this.headers
        }).pipe(map(
            (response: SensingDeviceManagerListJSON) => {
                return response.result;
            }
        ), catchError(
            (error: HttpErrorResponse) => {
                return observableThrowError(error);
            }
        ),);
    }

    /**
     * INF: NEW API
     */
    getSensorEntityInfo(sensorId: string): Observable<Sensor> {
        const url = environment.apiUrl + '/manager/sensors/' + sensorId;
        return this.http.get(url, {
            headers: this.headers
        }).pipe(map(
            (response: SensorEntityJSON) => {
                return response.result[0];
            }
        ), catchError(
            (error: HttpErrorResponse) => {
                return observableThrowError(error);
            }
        ));
    }

    /**
     * Deletes a sensor.
     *
     * Header:
     * - x-auth-token
     * Path:
     * - sensorId
     *
     * @param sensorId
     * @param token
     */
    deleteSensor(sensorId: string, token: string): Observable<any> {
        const url = environment.apiUrl + '/manager/sensors/' + sensorId;
        return this.http.delete(url, {
            headers: this.headers.append('x-auth-token', token)
        }).pipe(map(
            (response: any) => {
                console.log('API: delete sensor response is', response);
                return response;
            }
        ), catchError(
            (requestError: HttpErrorResponse) => {
                return observableThrowError(requestError);
            }
        ));
    }

    postSensor(name: string, token: string): Observable<any> {
        const url = environment.apiUrl + '/infrastructure/parameters/sensor-types';
        return this.postParameterHelper(name, token, url);
    }

    postProperty(name: string, token: string): Observable<any> {
        const url = environment.apiUrl + '/infrastructure/parameters/properties';
        return this.postParameterHelper(name, token, url);
    }

    postUnit(name: string, token: string): Observable<any> {
        const url = environment.apiUrl + '/infrastructure/parameters/units';
        return this.postParameterHelper(name, token, url);
    }

    postSubject(name: string, token: string): Observable<any> {
        const url = environment.apiUrl + '/infrastructure/parameters/subjects';
        return this.postParameterHelper(name, token, url);
    }

    postParameterHelper(name: string, token: string, url: string): Observable<any> {
        const body = JSON.stringify({
            name: name
        });
        return this.http.post(url, body, {
            headers: this.headers.append('x-auth-token', token)
        }).pipe(map(
            (response: any) => {
                return response;
            }
        ), catchError(
            (error: HttpErrorResponse) => {
                return observableThrowError(error);
            }
        ),);
    }

    updateSensingDevice(device: Sensor, token: string): Observable<any> {
        const url = environment.apiUrl + '/manager/sensors/' + device.sensorId;
        const body = JSON.stringify({
            name: device.name,
            type: device.type,
            parameters: device.parameters,
        });
        const headers = this.headers.append('x-auth-token', token);
        return this.http.post(url, body, {
            headers: headers
        }).pipe(map(
            (response: any) => {
                return response;
            }
        ), catchError(
            (error: HttpErrorResponse) => {
                return observableThrowError(error);
            }
        ),);
    }

    /**
     * INF: New api
     * @param device
     * @param token
     */
    postSensingDevice(device: Sensor, token: string): Observable<any> {
        const body = JSON.stringify({
            name: device.name,
            type: device.type,
            parameters: device.parameters,
        });
        const headers = this.headers.append('x-auth-token', token);
        return this.http.post(environment.apiUrl + '/manager/sensors', body, {
            headers: headers
        }).pipe(map(
            (response: any) => {
                return response;
            }
        ), catchError(
            (error: HttpErrorResponse) => {
                return observableThrowError(error);
            }
        ),);
    }

    /**
     * INF: New API
     * @param template
     * @param token
     */
    postTemplate(template: Template, token: string): Observable<any> {
        console.log('DEBUG: template is ', template);
        const body = JSON.stringify({
            name: template.name,
            sensorId: template.sensingDevice.sensorId,
            parameters: template.parameters
        });
        console.log('DEBUG: postTemplate body is ', body);
        const headers = this.headers.append('x-auth-token', token);
        return this.http.post(environment.apiUrl + '/manager/templates', body, {
            headers: headers
        }).pipe(map(
            (response: any) => {
                return response;
            }
        ), catchError(
            (error: HttpErrorResponse) => {
                return observableThrowError(error);
            }
        ),);
    }

    updateTemplate(template: Template, token: string): Observable<any> {
        const url = environment.apiUrl + '/manager/templates/' + template.id;
        const body = JSON.stringify({
            name: template.name,
            sensorId: template.sensorId,
            parameters: template.parameters,
        });
        const headers = this.headers.append('x-auth-token', token);
        return this.http.post(url, body, {
            headers: headers
        }).pipe(map(
            (response: any) => {
                return response;
            }
        ), catchError(
            (error: HttpErrorResponse) => {
                return observableThrowError(error);
            }
        ),);
    }

    /**
     * DELETE: /manager/templates/{templateId} (DELETE)
     * @param templateId
     * @param token
     */
    deleteTemplate(templateId: string, token: string): Observable<any> {
        const url = environment.apiUrl + '/manager/templates/' + templateId;
        return this.http.delete(url, {
            headers: this.headers.append('x-auth-token', token)
        }).pipe(map(
            (response: any) => {
                console.log('API: delete template response is', response);
                return response;
            }
        ), catchError(
            (requestError: HttpErrorResponse) => {
                return observableThrowError(requestError);
            }
        ));
    }

    getSensorsProperties(): Observable<any> {
        const url = environment.apiUrl + '/infrastructure/parameters/properties';
        return this.http.get(url, {
            headers: this.headers
        }).pipe(map(
            (response: any) => {
                return response.result;
            }
        ), catchError(
            (error: HttpErrorResponse) => {
                return observableThrowError(error);
            }),);
    }

    getSensorsUnits(): Observable<any> {
        const url = environment.apiUrl + '/infrastructure/parameters/units';
        return this.http.get(url, {
            headers: this.headers
        }).pipe(map(
            (response: any) => {
                return response.result;
            }
        ), catchError(
            (error: HttpErrorResponse) => {
                return observableThrowError(error);
            }),);
    }

    getSensorsSubjects(): Observable<any> {
        const url = environment.apiUrl + '/infrastructure/parameters/subjects';
        return this.http.get(url, {
            headers: this.headers
        }).pipe(map(
            (response: any) => {
                return response.result;
            }
        ), catchError(
            (error: HttpErrorResponse) => {
                return observableThrowError(error);
            }),);
    }

    getSensorsTypes(): Observable<any> {
        const url = environment.apiUrl + '/infrastructure/parameters/sensor-types';
        return this.http.get(url, {
            headers: this.headers
        }).pipe(map(
            (response: any) => {
                return response.result;
            }
        ), catchError(
            (error: HttpErrorResponse) => {
                return observableThrowError(error);
            }),);
    }

    /**
     * INF: new API
     */
    getMotestacks(): Observable<MotestackManagerList[]> {
        return this.http.get(environment.apiUrl + '/manager/motestacks', {
            headers: this.headers
        }).pipe(map(
            (response: MotestackManagerListJSON) => {
                // this.setAllMotestacks(response.motestacks);
                // this.saveLocalStorage('intelligen-river-motestacks', response.motestacks);
                return response.result;
            }
        ), catchError(
            (error: HttpErrorResponse) => {
                return observableThrowError(error);
            }
        ),);
    }

    /**
     * INF: new api
     * @param motestackId
     */
    getMotestackById(motestackId): Observable<Motestack> {
        return this.http.get(environment.apiUrl + '/manager/motestacks/' + motestackId, {
            headers: this.headers
        }).pipe(map(
            (response: MotestackJSON) => {
                return response.result[0];
            }
        ), catchError(
            (error: HttpErrorResponse) => {
                return observableThrowError(error);
            }
        ));
    }

    updateMotestack(motestack: Motestack, token: string) {
        const url = environment.apiUrl + '/manager/motestacks/' + motestack.id;
        return this.postMotestackHelper(motestack, token, url);
    }

    postMotestack(motestack: Motestack, token: string) {
        const url = environment.apiUrl + '/manager/motestacks/';
        return this.postMotestackHelper(motestack, token, url);
    }

    postMotestackHelper(motestack: Motestack, token: string, url: string): Observable<any> {
        const allSensors = [];
        motestack.sensors.forEach((sens, indx) => {
            allSensors.push({
                sensorId: sens.sensorId,
                name: sens.name,
                sensorIndex: sens.sensorIndex,
                parameters: sens.parameters
            });
        });
        const body = JSON.stringify({
            id: motestack.id,
            name: motestack.name,
            max1Wire: motestack.max1Wire,
            min1Wire: motestack.min1Wire,
            maxSdi12: motestack.maxSdi12,
            minSdi12: motestack.minSdi12,
            numAds: motestack.numAds,
            nvsramLog: motestack.nvsramLog ? motestack.nvsramLog : false,
            sdi12Read: motestack.sdi12Read,
            sdi12St: motestack.sdi12St,
            sdi12V3: motestack.sdi12v3 ? motestack.sdi12v3 : false,
            sLogSize: !motestack.sLogSize ? 1 : motestack.sLogSize,
            sPeriod: motestack.sPeriod,
            sTrans: motestack.sTrans,
            // communications: motestack.communications,
            sensors: allSensors,
        });

        console.log('API: motestack post the request is', body);

        return this.http.post(url, body, {
            headers: this.headers.append('x-auth-token', token)
        }).pipe(map(
            (response: any) => {
                console.log('API: post motestack ', response);
                return response;
            }
        ), catchError(
            (requestError: HttpErrorResponse) => {
                return observableThrowError(requestError);
            }
        ),);
    }

    /**
     *  * Deletes a motestack.
     *
     * Header:
     * - x-auth-token
     * Path:
     * - motestackId
     *
     */
    deleteMotestack(motestackId: string, token: string): Observable<any> {
        const url = environment.apiUrl + '/manager/motestacks/' + motestackId;
        return this.http.delete(url, {
            headers: this.headers.append('x-auth-token', token)
        }).pipe(map(
            (response: any) => {
                console.log('API: delete Motestack response is', response);
                return response;
            }
        ), catchError(
            (requestError: HttpErrorResponse) => {
                return observableThrowError(requestError);
            }
        ));
    }

    setAllMotestacks(motestacks: Motestack[]): void {
        // this.observeDeployments.next(null);
        this.motestacks = motestacks;
        this.observeMotestacks.next(this.motestacks);
        this.saveLocalStorage('intelligen-river-motestacks', this.motestacks);

        // console.log('Sense Stream Service: motestacks updated and locally saved', this.motestacks);
    }

    /**
     * INF: only active deployments
     */
    getDeployments(): Observable<Deployment[]> {
        const url = environment.apiUrl + '/manager/deployments/';
        return this.http.get(url, {
            headers: this.headers
        }).pipe(map(
            (response: DeploymentsManagerListJSON) => {
                this.setAllDeployments(response.result);
                this.saveLocalStorage('intelligentriver.org-deployments', this.deployments);
                return response.result;
            }
        ), catchError(
            (error: HttpErrorResponse) => {
                return observableThrowError(error);
            }
        ),);
    }

    /**
     * Get all deployments
     */
    getActiveInactiveDeployments(): Observable<Deployment[]> {
        const params = new HttpParams().set('onlyActive', String(false));
        const url = environment.apiUrl + '/manager/deployments';
        return this.http.get(url, {
            headers: this.headers,
            params: params
        }).pipe(map(
            (response: DeploymentsManagerListJSON) => {
                return response.result;
            }
        ), catchError(
            (error: HttpErrorResponse) => {
                return observableThrowError(error);
            }
        ),);
    }

    /***
     * New get deployments by projectId
     * @param projectId
     */
    getDeploymentsByProjId(projectId: number): Observable<Deployment[]> {
        const url = environment.apiUrl + '/manager/deployments/by-project/' + projectId;
        return this.http.get(url, {
            headers: this.headers
        }).pipe(map(
            (response: any) => {
                this.observeDeploymentsByProjectId.next(response.result);
                return response.result;
            }), catchError((error: HttpErrorResponse) => {
            return observableThrowError(error);
        }),);
    }

    getAllDeployments(): Observable<Deployment[]> {
        // const params = new HttpParams().set('expanded', String(true));
        const url = environment.apiUrl + '/manager/deployments';
        return this.http.get(url, {
            headers: this.headers,
        }).pipe(map(
            (response: any) => {
                this.observeDeploymentsByProjectId.next(response.result);
                return response.result;
            }), catchError((error: HttpErrorResponse) => {
            return observableThrowError(error);
        }),);
    }

    /**
     * Delete deployment (DELETE): /manager/deployments/{deploymentId}
     * @param deploymentId
     * @param token
     */
    deleteDeployment(deploymentId: string, token: string): Observable<any> {
        const url = environment.apiUrl + '/manager/deployments/' + deploymentId;
        return this.http.delete(url, {
            headers: this.headers.append('x-auth-token', token)
        }).pipe(map(
            (response: any) => {
                console.log('API: delete template response is', response);
                return response;
            }
        ), catchError(
            (requestError: HttpErrorResponse) => {
                return observableThrowError(requestError);
            }
        ));
    }

    async getDataRecentAsync(deploymentId: string) {
        const params = new HttpParams().set('deploymentId', deploymentId).set('ascending', 'true');
        const response: any = await this.http.get(this.serverAPI + '/api/dataRecent', {
            headers: this.headers,
            params: params
        }).pipe(first()).toPromise();
        return response.projects[Object.keys(response.projects)[0]][deploymentId].data;
    }

    getDataArchive(deploymentId: string, startDate: number, endDate: number) {
        const params = new HttpParams().set('deploymentId', deploymentId).set('startDate', '' + startDate).set('endDate', '' + endDate).set('ascending', 'true');
        return this.http.get(this.serverAPI + '/api/dataArchive', {
            headers: this.headers,
            params: params
        }).pipe(map(
            (response: any) => {
                return response.data.data;
            }), catchError((error: HttpErrorResponse) => {
            return observableThrowError(error);
        }),);
    }

    getDeploymentDetailsByProjectId(projectId: number): Observable<{}> {
        if (!this.deploymentDetailsByDeploymentId) {
            this.deploymentDetailsByDeploymentId = this.loadSpecificStorageAsDict('intelligentriver.org-deploymentDetailsByProjectId');
        }

        if (this.deploymentDetailsByDeploymentId) {
            if (projectId in this.deploymentDetailsByProjectId) {
                // console.log('Sense Stream Service: getDeploymentDetailsByProjectId(' + projectId + ') cache',
                //   this.deploymentDetailsByDeploymentId);
                // this.addDeploymentDetailsByDeploymentId(projectId, this.deploymentDetailsByProjectId);   // trigger Subject
                this.observeDeploymentDetailsByProjectId.next(this.deploymentDetailsByProjectId);
                return Observable.create((observable) => {  // if they're subscribing to the method
                    observable.next(this.deploymentDetailsByProjectId);
                    observable.complete();
                });
            }
        }

        // console.log('Sense Stream Service: getDeploymentDetailsByProjectId(' + projectId + ')');
        const params = new HttpParams().set('projectId', '' + projectId);
        return this.http.get(this.serverAPI + '/api/deploymentsExpanded', {
            headers: this.headers,
            params: params
        }).pipe(map(
            (response: any) => {
                this.addDeploymentDetailsByProjectId(projectId, response);
                // console.log('here 0');
                this.addDeploymentDataMetadataIndexByProjectId(projectId, response['deployments']);
                for (let i = 0; i < response['deployments'].length; i++) {
                    this.addDeploymentDetailsByDeploymentId(response['deployments'][i]['id'], response['deployments'][i]);
                }
                this.saveLocalStorage('intelligentriver.org-deploymentDetailsByProjectId', this.deploymentDetailsByProjectId);
                return this.deploymentDetailsByProjectId;
            }
        ), catchError(
            (error: HttpErrorResponse) => {
                return observableThrowError(error);
            }
        ),);
    }

    /**
     * INF: DeploymentDetailsByDeploymentId, changed to any for a new version of angular
     * @param deploymentId
     */
    getDeploymentDetailsByDeploymentId(deploymentId: string): Observable<any> {
        if (!this.deploymentDetailsByDeploymentId) {
            this.deploymentDetailsByDeploymentId = this.loadSpecificStorageAsDict('intelligentriver.org-deploymentDetailsByDeploymentId');
        }

        if (this.deploymentDetailsByDeploymentId) {
            if (deploymentId in this.deploymentDetailsByDeploymentId) {
                // console.log('Sense Stream Service: getDeploymentDetailsByDeploymentId(' + deploymentId + ') cache',
                //   this.deploymentDetailsByDeploymentId);
                // this.addDeploymentDetailsByDeploymentId(deploymentId, this.deploymentDetailsByDeploymentId);   // trigger Subject
                return Observable.create((observable) => {  // if they're subscribing to the method
                    observable.next(this.deploymentDetailsByDeploymentId);
                    observable.complete();
                });
            }
        }

        // console.log('Sense Stream Service: getDeploymentDetailsByDeploymentId(' + deploymentId + ')');
        const params = new HttpParams().set('deploymentId', '' + deploymentId);
        return this.http.get(this.serverAPI + '/api/deploymentDetails', {
            headers: this.headers,
            params: params
        }).pipe(map(
            (response: any) => {
                // console.log('inside getDeploymentDetailsByDeploymentId()', deploymentId, response);
                this.addDeploymentDetailsByDeploymentId(deploymentId, response['deployment']);
                this.saveLocalStorage('intelligentriver.org-deploymentDetailsByDeploymentId', this.deploymentDetailsByDeploymentId);
                return this.deploymentDetailsByDeploymentId;
            }
        ), catchError(
            (error: HttpErrorResponse) => {
                return observableThrowError(error);
            }
        ),);
    }

    getDeploymentDetailsByDeployment(deploymentId: string): Observable<DeploymentDetailsByDeploymentId> {
        const params = new HttpParams().set('deploymentId', '' + deploymentId);
        return this.http.get(this.serverAPI + '/api/deploymentDetails', {
            headers: this.headers,
            params: params
        }).pipe(map(
            (response: any) => {
                return response.deployment;
            }
        ), catchError(
            (error: HttpErrorResponse) => {
                return observableThrowError(error);
            }
        ),);
    }

    getDiagnosticsArchiveBlock(start: number, end: number, blockSizeSeconds: number): Observable<any> {
        const url = environment.apiUrl + '/observations/diagnostics';
        const params = new HttpParams().set('start', start.toString())
            .set('end', end.toString())
            .set('windowSeconds', blockSizeSeconds.toString());
        return this.http.get(url, {
            headers: this.headers,
            params: params
        }).pipe(map(
            (response: any) => {
                return response.result;
            }
        ), catchError(
            (error: HttpErrorResponse) => {
                return observableThrowError(error);
            }
        ),);
    }

    getDiagnosticMessagesForDeployment(dUri: string, start: number, end: number): Observable<DeploymentDiagnosticMessage[]> {
        const url = environment.apiUrl + '/observations/diagnostics/messages/' + dUri;
        const params = new HttpParams().set('start', start.toString())
            .set('end', end.toString());
        return this.http.get(url, {
            headers: this.headers,
            params: params
        }).pipe(map((response: any) => {
            return response.result;
        }), catchError((error: HttpErrorResponse) => {
            return observableThrowError(error);
        }),);
    }

    getMaintenanceByProjectId(projectId: number): Observable<Maintenance[]> {

        // console.log('Sense Stream Service: calling getMaintenanceByProjectId(' + projectId + ') new HTTP Request');
        const params = new HttpParams().set('projectId', '' + projectId);
        return this.http.get(this.serverAPI + '/api/maintenance', {
            headers: this.headers,
            params: params
        }).pipe(map(
            (response: MaintenanceAllJSON) => {
                // // console.log('inside getMaintenance by project Id', projectId, response);
                // this.addMaintenanceByProjectId(projectId, response.maintenance);
                // this.saveLocalStorage('intelligentriver.org-maintenanceByProjectId', this.maintenanceByProjectId);
                return response.maintenance;
            }
        ), catchError(
            (error: HttpErrorResponse) => {
                return observableThrowError(error);
            }
        ),);
    }

    addMaintenanceByProjectId(projectId, newMaintenanceByProjectId): void {
        if (projectId in this.addMaintenanceByProjectId) {
            return;
        }
        // this.observeMaintenanceByProjectId.next(null);
        this.maintenanceByProjectId[projectId] = newMaintenanceByProjectId;
        this.observeMaintenanceByProjectId.next(this.maintenanceByProjectId);
        // console.log('trying to save MaintenanceByProjectId local storage', this.maintenanceByProjectId);
        // this.saveLocalStorage('intelligentriver.org-maintenanceByProjectId', this.maintenanceByProjectId);
        // console.log('Sense Stream Service: maintenanceByProjectId updated', this.maintenanceByProjectId);
    }

    getMaintenanceFormData(projectId: number): Observable<any> {
        // console.log('Sense Stream Service: getMaintenanceFormData(' + projectId + ') new HTTP Request');
        const params = new HttpParams().set('projectId', '' + projectId);
        return this.http.get(this.serverAPI + '/api/maintenanceForm', {
            headers: this.headers,
            params: params
        }).pipe(map(
            (response: any) => {
                // console.log('Sense Stream Service: getMaintenanceFormData(' + projectId + ') response', response);
                return response;
            }
        ), catchError(
            (error: HttpErrorResponse) => {
                // console.log('Sense Stream Service: getMaintenanceFormData(' + projectId + ') Error!', error);
                return observableThrowError(error);
            }
        ),);
    }

    postMaintenanceData(maintenance: Maintenance, token: string): Observable<any> {
        let maintenancePost: any;
        maintenancePost = maintenance;
        maintenancePost.token = token;
        const body = JSON.stringify({
            datetime: maintenancePost.datetime,
            comment: maintenancePost.comment,
            deployment: maintenancePost.deployment,
            eventType: maintenancePost.eventType,
            projectId: maintenancePost.projectId,
            token: maintenancePost.token,
            user: maintenancePost.user
        });
        return this.http.post(this.serverAPI + '/api/postMaintenance', body, {
            headers: this.headers
        }).pipe(map(
            (response: any) => {
                return response;
            }
        ), catchError(
            (error: HttpErrorResponse) => {
                return observableThrowError(error);
            }
        ),);
    }

    deleteMaintenance(token: string, maintenanceId: string) {
        // console.log('Sense Stream Service: deleteMaintenance called, maintenanceId, token: ', maintenanceId, token);
        const body = JSON.stringify({
            token: token,
            maintenanceId: maintenanceId
        });
        return this.http.post(this.serverAPI + '/api/deleteMaintenance', body, {
            headers: this.headers
        }).pipe(map(
            (response: any) => {
                return response;
            }
        ), catchError(
            (error: HttpErrorResponse) => {
                return observableThrowError(error);
            }
        ),);
    }

    /**
     * INF: new api request
     * @param deploymentId
     */
    getDeploymentDetails(deploymentUri: string): Observable<any> {
        const url = environment.apiUrl + '/manager/deployments/by-uri/' + deploymentUri;
        return this.http.get(url, {
            headers: this.headers
        }).pipe(map(
            (response: any) => {
                return response.result;
            }
        ), catchError(
            (error: HttpErrorResponse) => {
                return observableThrowError(error);
            }
        ),);
    }

    getDeploymentsMeasurementsExportFile(startTime: number, endTime: number, deploymentUris: Array<string>): Observable<any> {
        const body = {
            start: startTime,
            end: endTime,
            deploymentUris: deploymentUris
        };
        const url = environment.apiUrl + '/reports/export/measurements/request';
        return this.http.post(url, JSON.stringify(body), {
            headers: this.headers
        }).pipe(map(
            (response: any) => {
                console.log('IRservice: got response for file export: ', response);
                return response.result;
            }
        ), catchError(
            (error: HttpErrorResponse) => {
                return observableThrowError(error);
            }
        ),);
    }

    checkMeasurementsExportStatus(exportId: string): Observable<any> {
        const url = environment.apiUrl + '/reports/export/measurements/progress/' + exportId;
        return this.http.get(url, {
            headers: this.headers,
        }).pipe(map(
            (response: any) => {
                console.log('status response is ', response);
                return response.result;
            }), catchError((error: HttpErrorResponse) => {
            return observableThrowError(error);
        }),);
    }

    getDataCurrent(projectId: number): Observable<any> {
        // console.log('Sense Stream Service: getDataCurrent(' + projectId + ') new HTTP Request');
        const params = new HttpParams().set('projectId', '' + projectId).set('date', '01-20-2017');
        // return this.http.get(this.serverAPI + '/api/dataRecent', {
        return this.http.get(this.serverAPI + '/api/dataArchive', {
            headers: this.headers,
            params: params
        }).pipe(map(
            (response: DataTemporaryJSON) => {
                // console.log('Sense Stream Service: getDataCurrent(' + projectId + ') response', response);
                return this.temporaryDataCurrentSorting(response);
                // return response;
            }
        ), catchError(
            (error: HttpErrorResponse) => {
                // console.log('Sense Stream Service: getDataCurrent(' + projectId + ') ERROR!!!!!', error);
                return observableThrowError(error);
            }
        ),);
    }

    temporaryDataCurrentSorting(response: DataTemporaryJSON): {} {
        const tempDataSort = {};
        const data = response.data;
        let datum: DataTemporary;
        const ret: {} = {};
        for (let i = 0; i < data.length; i++) {
            datum = data[i];
            if (datum.deploymentId in tempDataSort) {
                if (datum.observationDateTime > tempDataSort[datum.deploymentId]['observationDateTime']) {
                    tempDataSort[datum.deploymentId] = datum;
                }
            } else {
                tempDataSort[datum.deploymentId] = datum;
            }
        }

        return tempDataSort;
    }

    loadLocalStorage(): void {
        const now: moment.Moment = moment();
        // console.log('Sense Stream Service: now', now.toISOString());
        if (localStorage.getItem('intelligentriver.org-last-update') === null) {
            localStorage.setItem('intelligentriver.org-last-update', JSON.stringify(now.toISOString()));
        } else {
            let then: any = JSON.parse(localStorage.getItem('intelligentriver.org-last-update'));
            // console.log('Sense Stream Service: last-update', then);
            then = moment(then);

            if (now.diff(then, 'hours') < 6) {
                // console.log('Sense Stream Service: last update less than 6 hours, retrieving local storage');
                this.retrieveOtherStorage();
            } else {
                // console.log('Sense Stream Service: outdated, resetting local storage');
                for (const key of Object.keys(this.localStorageKeys)) {
                    this.clearLocalStorage(key);
                }
                localStorage.setItem('intelligentriver.org-last-update', JSON.stringify(now.toISOString()));
            }
        }
    }

    loadSpecificStorage(storageKey: string): any {
        const now = moment();
        if (localStorage.getItem('intelligentriver.org-last-update') === null) {
            localStorage.setItem('intelligentriver.org-last-update', JSON.stringify(now.toISOString()));
        } else {
            let then: any = JSON.parse(localStorage.getItem('intelligentriver.org-last-update'));
            then = moment(then);

            if (now.diff(then, 'hours') < 6) {
                const data: any = JSON.parse(localStorage.getItem(storageKey));
                return data;
            } else {
                for (const key of Object.keys(this.localStorageKeys)) {
                    this.clearLocalStorage(key);
                }
                localStorage.setItem('intelligentriver.org-last-update', JSON.stringify(now.toISOString()));
            }
        }
        return null;
    }

    loadSpecificStorageAsDict(storageKey: string): any {
        const now = moment();
        if (localStorage.getItem('intelligentriver.org-last-update') === null) {
            localStorage.setItem('intelligentriver.org-last-update', JSON.stringify(now.toISOString()));
        } else {
            let then: any = JSON.parse(localStorage.getItem('intelligentriver.org-last-update'));
            then = moment(then);

            if (now.diff(then, 'hours') < 6) {
                const data: any = JSON.parse(localStorage.getItem(storageKey));
                return !data ? {} : data;
            } else {
                for (const key of Object.keys(this.localStorageKeys)) {
                    this.clearLocalStorage(key);
                }
                localStorage.setItem('intelligentriver.org-last-update', JSON.stringify(now.toISOString()));
            }
        }
        return {};
    }

    retrieveOtherStorage(): void {
        let data: any;
        if (localStorage.getItem('intelligentriver.org-projects') === null) {
            this.projects = null;
        } else {
            data = JSON.parse(localStorage.getItem('intelligentriver.org-projects'));
            this.projects = data;
        }

        if (localStorage.getItem('intelligentriver.org-statistics') === null) {
            this.statistics = null;
        } else {
            data = JSON.parse(localStorage.getItem('intelligentriver.org-statistics'));
            this.statistics = data;
        }

        if (localStorage.getItem('intelligentriver.org-deployments') === null) {
            this.deployments = null;
        } else {
            data = JSON.parse(localStorage.getItem('intelligentriver.org-deployments'));
            this.deployments = data;
        }

        if (localStorage.getItem('intelligentriver.org-deploymentDataMetadataIndex') === null) {
            this.deploymentDataMetadataIndex = {};
        } else {
            data = JSON.parse(localStorage.getItem('intelligentriver.org-deploymentDataMetadataIndex'));
            this.deploymentDataMetadataIndex = data;
        }

        if (localStorage.getItem('intelligentriver.org-deploymentsByProjectId') === null) {
            this.deploymentsByProjectId = {};
        } else {
            data = JSON.parse(localStorage.getItem('intelligentriver.org-deploymentsByProjectId'));
            this.deploymentsByProjectId = !data ? {} : data;
        }

        if (localStorage.getItem('intelligentriver.org-deploymentDetailsByProjectId') === null) {
            this.deploymentDetailsByProjectId = {};
        } else {
            data = JSON.parse(localStorage.getItem('intelligentriver.org-deploymentDetailsByProjectId'));
            this.deploymentDetailsByProjectId = !data ? {} : data;
        }

        if (localStorage.getItem('intelligentriver.org-deploymentDetailsByDeploymentId') === null) {
            this.deploymentDetailsByDeploymentId = {};
        } else {
            data = JSON.parse(localStorage.getItem('intelligentriver.org-deploymentDetailsByDeploymentId'));
            this.deploymentDetailsByDeploymentId = !data ? {} : data;
        }

        this.observeCurrentProject.next(this.currentProject);
        if (this.projects) {
            this.observeProjects.next(this.projects.result);
        }
        this.observeCurrentDeployment.next(this.currentDeployment);
        if (this.deployments) {
            this.observeDeployments.next(this.deployments);
        }
        this.observeDeploymentDataMetadataIndex.next(this.deploymentDataMetadataIndex);
        this.observeDeploymentsByProjectId.next(this.deploymentsByProjectId);
        this.observeDeploymentDetailsByDeploymentId.next(this.deploymentDetailsByDeploymentId);
    }

    saveLocalStorage(key: any, value: any): void {
        localStorage.setItem(key, JSON.stringify(value));
    }

    clearLocalStorage(key: string) {
        localStorage.removeItem(key);
    }

    postApplyForm(name: string, email: string, description: string): Observable<any> {
        const body = {name: name, email: email, description: description};
        return this.http.post(this.serverAPI + '/api/submissionForm', JSON.stringify(body), {
            headers: this.headers
        }).pipe(map(
            (response: any) => {
                return response;
            }
        ), catchError(
            (error: HttpErrorResponse) => {
                return observableThrowError(error);
            }
        ),);
    }

    /**
     * INF: new api
     * @param deploymentUri URIs of deployment for which report is generated
     * @param startDate start of the epoch (milliseconds)
     * @param endDate end of the epoch (milliseconds)
     * @param receptionTimeMinutes
     */
    prepareDiagnosticReport(deploymentUri: string, startDate: number, endDate: number, receptionTimeMinutes: string) {
        const body = {
            deploymentUri: deploymentUri,
            start: startDate,
            end: endDate,
            receptionTimeMinutes: receptionTimeMinutes
        };
        return this.http.post(environment.apiUrl + '/reports/uptime/request', JSON.stringify(body), {
            headers: this.headers
        }).pipe(map(
            (response: any) => {
                console.log('API: uptime request response is', response);
                return response;
            }
        ), catchError(
            (error: HttpErrorResponse) => {
                return observableThrowError(error);
            }
        ),);
    }

    checkDiagnosticsExportStatus(exportId: string): Observable<any> {
        const url = environment.apiUrl + '/reports/uptime/progress/' + exportId;
        return this.http.get(url, {
            headers: this.headers,
        }).pipe(map(
            (response: any) => {
                console.log('status response is ', response);
                return response.result;
            }), catchError((error: HttpErrorResponse) => {
            return observableThrowError(error);
        }),);
    }

    getDiagnosticReportURL(deviceName: string, deploymentId: string, startDate: number, endDate: number, receptionTimeMinutes: string) {
        return this.serverAPI + `/api/diagnosticReport?startDate=${startDate}&endDate=${endDate}&deviceName=${deviceName}&deploymentId=${deploymentId}&timeZoneID=${this.getTimezoneId()}&receptionTimeMinutes=${receptionTimeMinutes}`;
    }

    getTimezoneId() {
        return Intl.DateTimeFormat().resolvedOptions().timeZone;
    }

    postBoundaryNotification(boundary: BoundaryNotification, token: string): Observable<any> {
        const body = {boundaries: boundary, token: token};
        return this.http.post(this.serverAPI + '/api/postBoundaryNotification', JSON.stringify(body), {
            headers: this.headers
        }).pipe(map(
            (response: any) => {
                return response;
            }
        ), catchError(
            (error: HttpErrorResponse) => {
                return observableThrowError(error);
            }
        ),);
    }

    updateBoundaryNotification(boundary: BoundaryNotification, token: string): Observable<any> {
        const body = {boundaries: boundary, token: token};
        return this.http.post(this.serverAPI + '/api/updateBoundaryNotification', JSON.stringify(body), {
            headers: this.headers
        }).pipe(map(
            (response: any) => {
                return response;
            }
        ), catchError(
            (error: HttpErrorResponse) => {
                return observableThrowError(error);
            }
        ),);
    }

    deleteBoundaryNotification(id: string, token: string): Observable<any> {
        const body = {id: id, token: token};
        return this.http.post(this.serverAPI + '/api/deleteBoundaryNotification', JSON.stringify(body), {
            headers: this.headers
        }).pipe(map(
            (response: any) => {
                return response;
            }
        ), catchError(
            (error: HttpErrorResponse) => {
                return observableThrowError(error);
            }
        ),);
    }

    getDeploymentExpandedAll() {
        return this.http.get(this.serverAPI + '/api/deploymentsExpandedAll', {
            headers: this.headers
        }).pipe(map(
            (response: any) => {
                return response.deployments;
            }
        ), catchError(
            (error: HttpErrorResponse) => {
                return observableThrowError(error);
            }
        ),);
    }

    bugReportPost(bugReportForm: BugReportForm) {
        return this.http.post(this.serverAPI + '/api/bugReportForm', JSON.stringify(bugReportForm), {
            headers: this.headers
        }).pipe(map(
            (response: any) => {
                return response;
            }
        ), catchError(
            (error: HttpErrorResponse) => {
                return observableThrowError(error);
            }
        ),);
    }

    /**
     * deployments notifications api
     */
    getDeploymentNotificationPreferences(deploymentUri: string, token: string): Observable<DeploymentNotification[]> {
        const url = environment.apiUrl + '/infrastructure/notifications/for-deployment/' + deploymentUri;
        return this.http.get(url, {
            headers: this.headers.append('x-auth-token', token)
        }).pipe(map(
            (response: any) => {
                console.log('API: notification settings message: ', response);
                return response.result;
            }
        ), catchError(
            (error: HttpErrorResponse) => {
                return observableThrowError(error);
            }
        ),);
    }

    /**
     * /infrastructure/notifications/ (POST):
     * Header:
     * - x-auth-token
     * Body:
     * - subscribers
     * - deathTimeout
     * - motestackId
     *
     * @param deploymentUri
     * @param subscribers
     * @param deathTimeout
     * @param token
     */
    postDeploymentNotificationPreferences(deploymentUri: string, subscribers: string[], deathTimeout: number, token: string): Observable<any> {
        const url = environment.apiUrl + '/infrastructure/notifications/';
        return this.http.post(url, JSON.stringify({
            deploymentUri: deploymentUri,
            subscribers: subscribers,
            deathTimeout: deathTimeout
        }), {
            headers: this.headers.append('x-auth-token', token)
        }).pipe(map(
            (response: any) => {
                console.log('API: post notification settings message: ', response);
                return response;
            }
        ), catchError(
            (error: HttpErrorResponse) => {
                return observableThrowError(error);
            }
        ),);
    }

    /**
     * /infrastructure/notifications/{notificationId} (POST):
     * Header:
     * - x-auth-token
     * Path:
     * - notificationId
     * Body:
     * - subscribers
     * - deathTimeout
     * - deploymentUri
     * @param notificationId
     * @param deploymentUri
     * @param subscribers
     * @param deathTimeout
     * @param token
     */
    updateDeploymentNotificationPreferences(notificationId: number, deploymentUri: string, subscribers: string[], deathTimeout: number, token: string): Observable<any> {
        const url = environment.apiUrl + '/infrastructure/notifications/' + notificationId;
        return this.http.post(url, JSON.stringify({
            deploymentUri: deploymentUri,
            subscribers: subscribers,
            deathTimeout: deathTimeout
        }), {
            headers: this.headers.append('x-auth-token', token)
        }).pipe(map(
            (response: any) => {
                console.log('API: update notification settings message: ', response);
                return response;
            }
        ), catchError(
            (error: HttpErrorResponse) => {
                return observableThrowError(error);
            }
        ),);
    }

    /**
     * /infrastructure/notifications/{notificationId} (DELETE):
     * Header:
     * - x-auth-token
     * Path:
     * - deploymentUri
     * @param notificationId
     * @param token
     */
    deleteDeploymentNotificationPreferences(notificationId: number, token: string): Observable<any> {
        const url = environment.apiUrl + '/infrastructure/notifications/' + notificationId;
        return this.http.delete(url, {
            headers: this.headers.append('x-auth-token', token)
        }).pipe(map(
            (response: any) => {
                console.log('API: delete notification settings message: ', response);
                return response;
            }
        ), catchError(
            (error: HttpErrorResponse) => {
                return observableThrowError(error);
            }
        ),);
    }

    /**
     * Filtering deployments functions
     */

    getDeploymentsBySensorIds(sensorIds): Observable<any> {
        const url = environment.apiUrl + '/manager/deployments/by-sensors';
        return this.http.post(url, JSON.stringify({
            sensorIds: sensorIds
        }), {
            headers: this.headers
        }).pipe(map(
            (response: any) => {
                console.log('API: get deployments by sensors ids ', response);
                return response;
            }
        ), catchError(
            (error: HttpErrorResponse) => {
                return observableThrowError(error);
            }
        ),);
    }

    /**
     * Deployment Status
     * https://api.sensestream.org/observations/status/dorchester_1?start=1590086226000&end=1592486226000
     */
    getStatusByDeploymentUri(deploymentUri, startMillis, endMillis): Observable<SensorStatus[]> {
        const url = environment.apiUrl + '/observations/status/' + deploymentUri;
        const params = new HttpParams().set('start', startMillis.toString()).set('end', endMillis.toString());
        return this.http.get(url, {
            headers: this.headers,
            params: params
        }).pipe(map(
            (response: any) => {
                console.log('API: get status by deployment uri response: ', response);
                return response.result;
            }
        ), catchError(
            (error: HttpErrorResponse) => {
                return observableThrowError(error);
            }
        ),);
    }

    /**
     * Deployment Status
     * https://api.sensestream.org/observations/status/dorchester_1?start=1590086226000&end=1592486226000
     */
    newGetStatusByDeploymentUri(deploymentUri, startMillis, endMillis): Observable<NewSensorStatus[]> {
        const url = environment.apiUrl + '/observations/status-new/' + deploymentUri;
        const params = new HttpParams().set('start', startMillis.toString()).set('end', endMillis.toString());
        return this.http.get(url, {
            headers: this.headers,
            params: params
        }).pipe(map(
            (response: any) => {
                console.log('API: get status by deployment uri response: ', response);
                return response.result;
            }
        ), catchError(
            (error: HttpErrorResponse) => {
                return observableThrowError(error);
            }
        ),);
    }

    /**
     * View By Sensor stuff
     */

    getAverageReadingsBySensorParams(deploymentUri, sensorIndex, paramIndex, startMillis, endMillis): Observable<any> {
        const url = environment.apiUrl + '/observations/measurements/' + deploymentUri + '/' + sensorIndex + '/' + paramIndex;
        const params = new HttpParams().set('start', startMillis.toString()).set('end', endMillis.toString()).set('method', 'AVG');
        return this.http.get(url, {
            headers: this.headers,
            params: params
        }).pipe(map(
            (response: any) => {
                // console.log('API:getAverageReadingsBySensorParams response: ', response);
                return response.result;
            }
        ), catchError(
            (error: HttpErrorResponse) => {
                return observableThrowError(error);
            }
        ),);
    }
}
