import {Component, OnInit, ViewChild} from '@angular/core';
import {User} from '../../../../../models/user.model';
import {Error, SystemError, SystemErrorJSON} from '../../../../../models/error.model';
import {IntelligentRiverService} from '../../../../../services/intelligent-river.service';
import {Router, Params, ActivatedRoute} from '@angular/router';
import {AuthService} from '../../../../../services/auth.service';
import {Title} from '@angular/platform-browser';
import {NgDatepickerModule, DatepickerOptions} from 'ng2-datepicker';
import * as frLocale from 'date-fns/locale/fr';
import * as moment from 'moment';
import {timer, Observable} from 'rxjs';
import {MatSort} from "@angular/material/sort";
import {MatTableDataSource} from "@angular/material/table";
import {MatDialog, MatDialogConfig} from "@angular/material/dialog";
import {ErrorModalComponent} from "../error-modal/error-modal.component";

// import { AgmJsonViewerComponent } from 'agm-json-viewer';
@Component({
    selector: 'app-errors-list',
    templateUrl: './errors-list.component.html',
    styleUrls: ['./errors-list.component.scss']
})
export class ErrorsListComponent implements OnInit {
    //  @ViewChild(AgmJsonViewerComponent) jsonComponent: AgmJsonViewerComponent;
    // @ViewChild('errorModal') modal :any;
    errors: SystemError[];
    filteredErrors: SystemError[];
    private currentUser: User;
    selectedError: SystemError;
    messageObj: object;
    date: object;
    loadingNewErrors: boolean;
    // Table state
    displayedColumns = ['deploymentUri', 'description', 'formattedDate'];
    errorsTableData: any;
    @ViewChild(MatSort, {static: true}) sort: MatSort;


    theHtmlString: string;
    options: DatepickerOptions = {
        minYear: 1970,
        maxYear: 2030,
        displayFormat: 'YYYY-MM-DD',
        barTitleFormat: 'YYYY-MM-DD',
        firstCalendarDay: 0, // 0 - Sunday, 1 - Monday
        locale: frLocale
    };

    constructor(private irService: IntelligentRiverService,
                private router: Router, private authService: AuthService,
                private titleService: Title,
                private route: ActivatedRoute,
                public dialog: MatDialog) {
    }

    ngOnInit() {
        this.titleService.setTitle(this.route.snapshot.data['title']);
        this.selectedError = {};
        this.date = moment();
        this.authService.observeCurrentUser.subscribe((next) => {
            this.currentUser = next;
            if (next == null) {
                return;
            }
            console.log('Date is ', this.date);
            this.getErrors(this.date);
        });
    }

    getErrors(momentObject) {
        let start = 0;
        let end = 0;
        let isCurrentDate = momentObject.isSame(new Date(), 'day');
        if (isCurrentDate) {
            start = moment(new Date().setHours(0, 0, 0, 0).valueOf()).local().unix() * 1000;
            end = moment(new Date().valueOf()).local().unix() * 1000;
        } else {
            start = momentObject.hour(0).minute(0).minute(0).second(0).millisecond(0).local().unix() * 1000;
            end = momentObject.hour(23).minute(59).minute(59).second(59).millisecond(59).local().unix() * 1000;
        }
        this.loadingNewErrors = true;
        this.irService.getMiddlewareErrors(start, end, this.currentUser.token).subscribe(errors => {
            this.errors = errors;
            this.errors.forEach(error => {
                error.formattedDate = new Date(error.time).toLocaleTimeString('en-US');
            });
            this.errorsTableData = new MatTableDataSource(this.errors);
            this.errorsTableData.sort = this.sort;
            // this.errors.forEach(error => {
            //   // error.deploymentUriTitile = error.deploymentUri.substr(error.deploymentUri.indexOf('#') + 1);
            // });
            // this.filteredErrors = Object.assign([], this.errors);
            this.loadingNewErrors = false;
            const timer1 = timer(10);
            timer1.subscribe(r => {
                $('.ngx-datepicker-input').css('opacity', 0); // change this to angular
            });
        });
    }

    applyFilter(event: Event) {
        const filterValue = (event.target as HTMLInputElement).value;
        this.errorsTableData.filter = filterValue.trim().toLowerCase();
    }

    filterItem(filter: string): void {
        this.filteredErrors = this.errors.filter(i => i.description.toLowerCase().includes(filter.toLowerCase()));
    }

    showErrorInfo(error: Error) {
        this.selectedError = null;
        // this trick use to delete modal & create new => new json-viewer for new error obj generated
        const timer2 = timer(100);
        timer2.subscribe(r => {
            this.selectedError = error;
            this.selectedError.formattedDate = new Date(this.selectedError.time).toLocaleTimeString('en-US');
            this.messageObj = {message: this.selectedError.message};
            const timer2 = timer(100);
            timer2.subscribe(r2 => {
                (<any>$('#errorModal')).modal('show');
                (<any>$('#errorModalContent')).resizable();
                (<any>$('#errorModalContent')).draggable({
                    handle: '.modal-header'
                });
            });
        });
    }

    openDialog(error: Error) {
        this.selectedError = null;
        this.selectedError = error;

        const dialogConfig = new MatDialogConfig();

        dialogConfig.maxWidth = '90vw';
        dialogConfig.autoFocus = false;
        // dialogConfig.maxHeight = 'calc(100vh - 350px)';
        dialogConfig.data = this.selectedError;
        dialogConfig.position = {
            top: '110px',
        };

        this.dialog.open(ErrorModalComponent, dialogConfig);
    }

    dateChange(newDate) {
        this.date = newDate;
        this.getErrors(moment(newDate));
    }

    onFullScreanClick() {
        const currentWidth = $('#errorModalDialog').width();
        if (currentWidth >= $('#errorModalDialog').css('max-width', '100%').width('100%').width()) {
            $('#errorModalDialog').width('60%').height('55%').css('margin', '20%').css('margin-top', '10%');
        } else {
            $('#errorModalDialog').width('100%').height('100%').css('margin', '0').css('padding', '0').css('max-width', '100%');
        }
        $('#errorModal').css('padding-right', '0px');
        $('#errorModalContent').css('height', '100%').css('width', '100%');
    }
}
