import { Injectable } from '@angular/core';

@Injectable()
export class SortService {

  constructor() { }

  sortByAlphabet(sortField: string, array: any[]): any[] {
    return array.sort(function(a, b){
      if(a[sortField] < b[sortField]) return -1;
      if(a[sortField] > b[sortField]) return 1;
      return 0;
    });
  } 
  
}
