import { Component, OnInit } from '@angular/core';
import { User } from '../../../../../models/user.model';
import { Notification } from '../../../../../models/notification.model';
import { IntelligentRiverService } from '../../../../../services/intelligent-river.service';
import { Router,ActivatedRoute, Params } from '@angular/router';
import { AuthService } from '../../../../../services/auth.service';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-notification-list',
  templateUrl: './notification-list.component.html',
  styleUrls: ['./notification-list.component.scss']
})
export class NotificationListComponent implements OnInit {
  notifications: Notification[];
  filteredNotifications: Notification[];
  private currentUser: User;
  isDefault = true;

  constructor(private irService: IntelligentRiverService,
     private router: Router, private authService: AuthService, private titleService: Title, private route: ActivatedRoute) { }

  ngOnInit() {
        this.titleService.setTitle(this.route.snapshot.data['title']);
        this.authService.observeCurrentUser.subscribe((next) => {
          this.currentUser = next;
          if (next == null) {
             return;
          }
          this.getDefaultNotifications();
      });
  }

  filterItem(filter: string): void {
    this.filteredNotifications =  this.notifications.filter(i => i.title.toLowerCase().includes(filter.toLowerCase()));
  }

  getNotification(isDefault: boolean) {
    if (this.isDefault === isDefault) {
      return;
    }
    this.isDefault = isDefault;
    this.notifications = null;
    if (this.isDefault) {
      this.getDefaultNotifications();
    } else {
      this.getBoundaryNotifications();
    }
  }

  private getDefaultNotifications() {
    this.irService.getNotifications(this.currentUser.token).subscribe(notifications => {
      this.notifications = notifications;
      this.filteredNotifications = Object.assign([], this.notifications);
    });
  }

  private getBoundaryNotifications() {
    this.irService.getBoundaryNotifications(this.currentUser.token).subscribe(notifications => {
      this.notifications = notifications;
      this.filteredNotifications = Object.assign([], this.notifications);
    });
  }

  updateNotification(notification: Notification): void {
    if (this.isDefault) {
      if (notification == null) {
          this.router.navigate(['./deployment-manager/notifications', 'new' ]);
      } else {
        this.router.navigate(['./deployment-manager/notifications', notification.id ]);
      }
    } else {
      if (notification == null) {
        this.router.navigate(['./deployment-manager/boundary-notifications', 'new' ]);
    } else {
      this.router.navigate(['./deployment-manager/boundary-notifications', notification.id ]);
    }
    }
  }

}
