import { Component, OnInit } from '@angular/core';
import {TemplateManagerList} from '../../../../../models/template.model';
import { IntelligentRiverService } from '../../../../../services/intelligent-river.service';
import { Router, Params ,ActivatedRoute} from '@angular/router';
import { Title } from '@angular/platform-browser';
import { SortService } from '../../../../../services/sort.service';

@Component({
  selector: 'app-template-list',
  templateUrl: './template-list.component.html',
  styleUrls: ['./template-list.component.scss']
})
export class TemplateListComponent implements OnInit {
  templates: TemplateManagerList[];
  filteredTemplates: TemplateManagerList[];
  constructor(private irService: IntelligentRiverService, private router: Router, private titleService: Title, private route:ActivatedRoute, private sort: SortService) { }

  ngOnInit() {
    this.titleService.setTitle(this.route.snapshot.data['title']);
     this.irService.getTemplates().subscribe(templates => {
      this.templates = this.sort.sortByAlphabet('name', templates);
      this.filteredTemplates = Object.assign([], this.templates);
    });
  }

  filterItem(filter: string) : void {
    this.filteredTemplates =  this.templates.filter(i=>i.name.toLowerCase().includes(filter.toLowerCase()));
  }

  updateTemplate(template: TemplateManagerList) :void {
     if(template == null)
        this.router.navigate(['./deployment-manager/templates', 'new' ]);
    else this.router.navigate(['./deployment-manager/templates', template.id ]);
  }


}
