import {timer as observableTimer, Observable, interval} from 'rxjs';
import {AfterViewInit, Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Router, ActivatedRoute, Params} from '@angular/router';
import {Project, ProjectsJSON} from '../../../models/projects.model';
import {Deployment, DeploymentsAllJSON} from '../../../models/deployments.model';
import {IntelligentRiverService} from '../../../services/intelligent-river.service';
import {DeploymentStatusExtended, NewSensorStatus, SensorStatus, StatusJSON} from '../../../models/status.model';
import {Title} from '@angular/platform-browser';
import swal from 'sweetalert2';
import * as moment from "moment";

@Component({
    selector: 'app-status',
    templateUrl: './status.component.html',
    styleUrls: ['./status.component.scss']
})
export class StatusComponent implements OnInit, OnDestroy {
    @ViewChild('exportModalClose', {static: true}) exportModalClose: ElementRef;
    chart: any;
    dataTable: any;
    options: any;
    @HostListener('window:resize', ['$event'])
    startDate: Date;
    endDate: Date;
    calendarOptions: any = {
        autoUpdateInput: true,
        locale: {format: 'YYYY-MM-DD'},
        alwaysShowCalendars: false,
        ranges: {
            'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
            'Last 7 Days': [moment().subtract(6, 'days'), moment()],
            // 'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
        },
        opens: 'center'
    };
    showLoadingScreen = false;
    loadedCount: number;
    startDateSelect = false;
    // all deployments
    deployments: DeploymentStatusExtended[];
    openDeployment: number; // index of the open deployment;
    // all timestamps for current time range
    tableTimestamps: string[];
    monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
        "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
    ];

    statuses: any = {};

    constructor(private irService: IntelligentRiverService,
                private router: Router,
                private titleService: Title) {
    }

    ngOnInit() {
        this.titleService.setTitle('Status | Sense Stream');
        this.startDate = moment().subtract(1, 'days').toDate();
        this.endDate = moment().subtract(1, 'days').toDate();
        // this.startDate = new Date(1583038800000);
        // this.endDate = new Date(1588391999000);
        // this.tableTimestamps = this.getDates(this.startDate, this.endDate);
        this.tableTimestamps = [];
        this.openDeployment = null;
        // Get a list of deployments first
        this.deployments = [];
        this.showLoadingScreen = true;
        this.irService.getAllDeployments().subscribe(deployments => {
            this.deployments = deployments;
            // this.getStatusForEachDeployment();
            this.showLoadingScreen = false;
        });
    }

    ngOnDestroy() {
    }

    loadStatus(deploymentIndex) {
        console.log('DEBUG5: The deployment index is', deploymentIndex);
        // this means that the deployment tab was collapsed
        if (this.openDeployment === deploymentIndex) {
            console.log('DEBUG5: closing the tab');
            this.openDeployment = null;
            return;
        }
        this.openDeployment = deploymentIndex;
        console.log('DEBUG5: assigned the new deployment index');
        if (this.deployments.length > deploymentIndex) {
            // this.getDeploymentStatuses(deploymentIndex);
            console.log('DEBUG5: getting new information about the deployment');
            this.statuses = {};
            this.newGetDeploymentStatuses(deploymentIndex);
        } else {
            console.log('out of range index');
            return;
        }
    }

    newGetDeploymentStatuses(deploymentIndex) {
        console.log('DEBUG5: getting new statuses');
        this.tableTimestamps = [];
        this.showLoadingScreen = true;
        const uri = this.deployments[deploymentIndex].deploymentUri;
        console.log('DEBUG5: deployment index is', deploymentIndex, 'uri is', uri);
        let start = new Date(this.startDate.getTime());
        let end = new Date(this.endDate.getTime());
        let timeConstant = this.getTimezoneConstant();
        console.log('start', start, 'end is', end);
        this.irService.newGetStatusByDeploymentUri(uri, start.setHours(timeConstant, 0, 0, 0),
            end.setHours(23-timeConstant, 59,59,999)).subscribe(statuses => {
            console.log('DEBUG5: new statuses is: ', statuses);
            this.statuses[deploymentIndex] = this.newFormatStatuses(statuses, deploymentIndex);
            this.showLoadingScreen = false;
        }, error => {
        });
    }

    getTimezoneConstant() {
        let d = new Date();
        let n = d.getTimezoneOffset();
        return n*-1/60;
    }

    newFormatStatuses(rawStatuses: NewSensorStatus[], deploymentIndex) {
        let data = {}; // Initial data with an entry for each sensor in this deployment
        this.tableTimestamps = Object.keys(rawStatuses);

        let diagnosticData = {
            sensor: 'Diagnostic'
        };

        this.deployments[deploymentIndex].sensors.forEach(sensor => {
           data[sensor.sensorIndex] = {
               sensor: sensor.name
           };
        });

        let statuses = Object.values(rawStatuses);
        statuses.forEach((status, statusIndex) => {
            diagnosticData[this.tableTimestamps[statusIndex]] = status.diagnosticCount;
            Object.keys(status.sensorCounts).forEach((sensorCountKey, sensorCountIndex) => {
                data[sensorCountKey][this.tableTimestamps[statusIndex]] = {
                    actualCount: status.sensorCounts[sensorCountKey].toString(),
                    expectedCount: status.expectedSampleCount,
                };
            });
        });

        const finalData = [diagnosticData, ...Object.values(data)];
        console.log('DEBUG5: Final data is ', finalData);
        // Checks if there is any data in the generated data object
        let foundData = finalData.some(el => {
            return Object.keys(el).length > 1;
        });
        console.log('DEBUG: found data: ', foundData);
        return foundData ? finalData : null;
    }

    getTableColumns() {
        let result = ['sensor', ...this.tableTimestamps];
        return result;
    }

    formatDate(timestamp: string) {
        let timeConstant = 3600000 * this.getTimezoneConstant() * -1;
        let d = new Date(parseInt(timestamp, 10)+timeConstant); // add four hours to the date to account for the timezone difference with UTC
        let formattedString = this.monthNames[d.getMonth()] + '. ' + d.getDate();
        return formattedString;
    }

    // Get status for each deployment
    getStatusForEachDeployment() {
        this.loadedCount = 0;
        this.deployments.forEach(deployment => {
            this.irService.getStatusByDeploymentUri(deployment.deploymentUri, 1590086226000,
                1592486226000).subscribe(statuses => {
                // this.checkLoadingStatusCompleteness();
                // this.statuses.push({deployment: deployment, statuses: statuses});
            }, error => {
                this.checkLoadingStatusCompleteness();
            });
        });
    }

    checkLoadingStatusCompleteness() {
        this.loadedCount += 1;
        if (this.loadedCount === this.deployments.length) {
            this.showLoadingScreen = false;
            // console.log('All statuses are ', this.statuses);
        }
    }

    countLoadingPercentage() {
        if (this.deployments.length === 0 || this.loadedCount === 0) {
            return 0;
        }
        return 100 * this.loadedCount / this.deployments.length;
    }

    selectDate(value: any) {
        this.startDate = value.start.toDate();
        this.endDate = value.end.toDate();
        const timeDiff = Math.abs(this.startDate.getTime() - this.endDate.getTime());
        const diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));
        // console.log('start date', this.startDate, 'end date', this.endDate, 'day diff', diffDays);
        if (diffDays > 12 * 7) {
            swal('Warning!', 'Maximum 12 weeks difference', 'warning');
            // TODO: this needs to be handled
            return;
        }
        this.startDateSelect = true;
        // this.tableTimestamps = this.getDates(this.startDate, this.endDate);
        this.tableTimestamps = [];
        console.log('selected new date, open deployment is', this.openDeployment);
        if (this.openDeployment !== undefined) {
            this.newGetDeploymentStatuses(this.openDeployment);
        }
    }

    getDates(d1: Date | any, d2: Date | any) {
        if (new Date(d1.getTime()).setHours(0,0,0,0) === new Date(d2.getTime()).setHours(0,0,0,0)) {
            return [d1.setHours(0,0,0,0).toString()];
        }
        let oneDay = 24 * 3600 * 1000;
        let d = [];
        for (let ms = d1 * 1, last = d2 * 1; ms < last; ms += oneDay) {
            d.push(new Date(ms).setHours(0, 0, 0, 0).toString());
        }
        // console.log('dates found are', d);
        return d;
    }

    getFormattedDateTime(milisec: number) {
        return moment(milisec).format('MMMM Do YYYY, h:mm:ss a');
    }

    getUnixTimestampsForApi(date) {
        return moment(date.valueOf()).local().unix() * 1000;
    }

    getCurrentTimeWindow() {
        let startNum;
        let endNum;
        if (this.startDateSelect) {
            startNum = moment(this.startDate.valueOf()).local().unix() * 1000;
            endNum = moment(this.endDate.valueOf()).local().unix() * 1000;
        } else {
            startNum = moment(new Date().setHours(0, 0, 0, 0).valueOf()).local().unix() * 1000;
            endNum = moment(new Date().valueOf()).local().unix() * 1000;
        }
        return [startNum, endNum];
    }

    changeDate(next: boolean) {
        if (next && !this.nextDateAvaiable()) {
            return;
        }
        let diffDays = this.endDate.getDate() - this.startDate.getDate();
        if (diffDays === 0) {
            const timeDiff = Math.abs(this.startDate.getTime() - this.endDate.getTime());
            if (timeDiff === 0) {
                const today = new Date();
                today.setHours(0, 0, 0, 0);
                const todtEnd = new Date();
                todtEnd.setHours(23, 59, 59);
                this.startDate = today;
                this.endDate = todtEnd;
            }
            diffDays = 1;
        }
        if (next) {
            this.startDate.setDate(this.startDate.getDate() + diffDays);
            this.endDate.setDate(this.endDate.getDate() + diffDays);
        } else {
            this.startDate.setDate(this.startDate.getDate() - diffDays);
            this.endDate.setDate(this.endDate.getDate() - diffDays);
        }
        this.startDateSelect = true;
        if (this.openDeployment) {
            this.newGetDeploymentStatuses(this.openDeployment);
        }
        // this.getDiagnosticArchive();
    }

    nextDateAvaiable() {
        return this.endDate.getDate() !== new Date().getDate();
    }
}
