import {Component, OnInit} from '@angular/core';
import {Router, NavigationEnd, ActivatedRoute, PRIMARY_OUTLET} from '@angular/router';
import {Title} from '@angular/platform-browser';
import {Subscription} from 'rxjs';
import {Project, ProjectsJSON} from './models/projects.model';
import {Statistics, StatisticsJSON} from './models/statistics.model';
import {Deployment, DeploymentsByProjectIdJSON, DeploymentsAllJSON} from './models/deployments.model';
import {User, UserAccount, UserJSON} from './models/user.model';
import {IntelligentRiverService} from './services/intelligent-river.service';
import {AuthService} from './services/auth.service';
import {environment} from "../environments/environment";

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
    sub: Subscription;
    showProjectSelect: boolean;

    constructor(public router: Router,
                public activated: ActivatedRoute,
                private titleService: Title,
                private authService: AuthService,
                private irService: IntelligentRiverService) {
        if (environment.production) {
            this.router.events.subscribe(event => {
                if (event instanceof NavigationEnd) {
                    (<any>window).ga('set', 'page', event.urlAfterRedirects);
                    (<any>window).ga('send', 'pageview');
                }
            });
        }
    }

    ngOnInit() {
        // don't do anything with the returned Observable
        // the other components grab data from irService by observing
        //    subjects
        // this.irService.getProjects().subscribe(projects => {});
        // this.irService.getDeployments().subscribe(deployments => {});
        this.showProjectSelect = true;
    }

    onProjectSelect() {
        this.showProjectSelect = true;
    }

    hideSelectProjects() {
        $('footer').css('box-shadow', '0 0 10px rgba(0,0,0,0.5)');
        this.showProjectSelect = false;
    }
}
