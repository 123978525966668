import {enableProdMode} from '@angular/core';
import {environment} from './environment';

const disableConsoleMessages = () => {
    window.console.log = function () {};
    console.log = function () {};
};

export function setupEnvironment () {
    if (environment.production) {
        enableProdMode();
        disableConsoleMessages();
    } else {
        console.log('Dev. mode enabled');
    }
}
