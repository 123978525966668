import {Component, Inject, Input, OnInit} from '@angular/core';
import {MatDialogRef} from '@angular/material';
import {MAT_DIALOG_DATA} from "@angular/material/dialog";

@Component({
    selector: 'app-error-modal',
    templateUrl: './error-modal.component.html',
    styleUrls: ['./error-modal.component.scss']
})
export class ErrorModalComponent implements OnInit {
    date: Date;
    description: string;
    message: any;
    deploymentUri: any;

    constructor(public dialogRef: MatDialogRef<ErrorModalComponent>, @Inject(MAT_DIALOG_DATA) data) {
        this.date = data.formattedDate;
        this.description = data.description;
        this.message = {message: data.message};
        this.deploymentUri = data.deploymentUri;
    }

    ngOnInit() {
    }

    close() {
        this.dialogRef.close();
    }
}
